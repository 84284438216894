import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const VisitStep4 = (props) => {
  if (props.currentStep !== 5) return null;
  return (
    <>
      <div className="grid grid-cols-9 col-span-9 ">
        <div className="grid grid-cols-9 col-span-9 content-center ">
          <p className={'col-span-9 text-center mt-4 mb-4 font-semibold'}>
            Overview
          </p>
          <p className={'col-span-7 col-start-2 pl-4 text-left font-bold mb-1'}>
            Required
          </p>
          <p
            className={
              'col-span-7 col-start-2 pl-4 text-left mb-2 font-normal cursor-pointer'
            }
            onClick={() => props.changeStep(1)}
          >
            {props.step1Done && <input type="checkbox" checked />}{' '}
            {!props.step1Done && <input type="checkbox" />} Capture Stock{' '}
            <FontAwesomeIcon icon={faAngleRight} />
          </p>
          <p
            className={
              'col-span-7 col-start-2 pl-4 text-left mb-2 font-normal cursor-pointer'
            }
            onClick={() => props.changeStep(2)}
          >
            {props.step2Done && <input type="checkbox" checked />}
            {!props.step2Done && <input type="checkbox" />} Capture POSM{' '}
            <FontAwesomeIcon icon={faAngleRight} />
          </p>
          <p className={'col-span-7 col-start-2 pl-4 text-left font-bold mb-1'}>
            Optional
          </p>
          <p
            className={
              'col-span-7 col-start-2 pl-4 text-left mb-2 font-normal cursor-pointer'
            }
            onClick={() => props.changeStep(3)}
          >
            {props.step3Done && <input type="checkbox" checked />}{' '}
            {!props.step3Done && <input type="checkbox" />} Place Order{' '}
            <FontAwesomeIcon icon={faAngleRight} />
          </p>
        </div>
      </div>
    </>
  );
};

export default VisitStep4;
