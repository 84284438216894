import clsx from 'clsx';
import {
  ErrorOption,
  Path,
  RegisterOptions,
  UseFormReturn,
} from 'react-hook-form';

export interface TextareaProps<T>
  extends Partial<Pick<UseFormReturn<T>, 'register' | 'formState'>> {
  name: Path<T>;
  options?: RegisterOptions;
  rows?: number;
  cols?: number;
  className?: string;
  error?: ErrorOption;
}

const Textarea = <T,>({
  register,
  name,
  options,
  rows = 30,
  cols = 10,
  className,
  error,
  ...rest
}: TextareaProps<T>) => {
  const style = clsx(
    'py-1 px-3 rounded-lg w-full border-2',
    !error && 'bg-gray-200',
    error &&
      'border-red-500 bg-red-200 focus:outline-none focus:ring focus:ring-red-400 focus:ring-offset-1 focus:border-none'
  );

  return (
    <div className={`mb-4 ${className}`}>
      <textarea
        {...register(name, options)}
        className={style}
        cols={cols}
        rows={rows}
        {...rest}
      />
      {error && <p className="pl-2 text-sm text-red-500">{error.message}</p>}
    </div>
  );
};

export default Textarea;
