import { useEffect, useState } from 'react';
import React from 'react';
import { useCheckAccess } from '../../lib/auth/use-checkAccess';
import Card from '../../components/ui/Card';
import Content from '../../components/layout/Content';
import Alert from '../../components/ui/Alert';
import Back from '../../components/ui/Back';
import Loader from '../../components/ui/Loader';
import { useUser } from '../../lib/hooks/use-user';
import Button from '../../components/ui/Button';

declare global {
  interface Window {
    FB: any; // Define FB object as any type
  }
}

const Profile = React.memo(() => {
  const checkAccess = useCheckAccess();
  const { user, loading, error } = useUser();
  const [showAlert, setShowAlert] = useState<boolean>(false);

  useEffect(() => {
    if (error) {
      setShowAlert(true);
    } else if (showAlert) {
      setShowAlert(false);
    }
    return () => {
      setShowAlert(false);
    };
  }, [error, showAlert, user]);

  const launchWhatsAppSignup = () => {
    // Launch Facebook login
    window.FB.login(
      function (response: any) {
        if (response.authResponse) {
          const accessToken = response.authResponse.accessToken;
          console.log('Response', response);
          //Use this token to call the debug_token API and get the shared WABA's ID
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      },
      {
        config_id: '821804970005603', // configuration ID obtained in the previous step goes here
        response_type: 'code', // must be set to 'code' for System User access token
        override_default_response_type: true,
      }
    );
  };

  return (
    <Content>
      <Loader show={loading} />
      <Alert
        message={error && error.message}
        open={showAlert}
        setOpen={(open) => setShowAlert(open)}
        title="Error"
      />
      <Card>
        <div className="grid grid-cols-12">
          <Back to="/home" className=" col-span-3" />
          <div className="col-span-6 flex justify-center items-center text-center text-xl font-bold text-lh-head-black">
            {user && user.name}
          </div>
          <h2 className="col-span-6 text-lh-head-black text-lg font-bold">
            Phone number
          </h2>
          <p className="col-span-full text-lh-text-black text-sm font-normal">
            {user && user.phone}
          </p>
          <h2 className="col-span-6 text-lh-head-black text-lg font-bold">
            Cluster
          </h2>
          <p className="col-span-full text-lh-text-black text-sm font-normal">
            {user && user.cluster
              ? user.cluster.name
              : user && user.clusters
              ? user.clusters
                  .map((cluster, index) => {
                    return `${cluster.name}`;
                  })
                  .join(' ')
              : 'No territory'}
          </p>
          <h2 className="col-span-6 text-lh-head-black text-lg font-bold">
            Territory
          </h2>
          <p className="col-span-full text-lh-text-black text-sm font-normal">
            {user && user.cluster
              ? user.cluster.territory.name
              : user && user.clusters
              ? user.clusters
                  .map((cluster, index) => {
                    return `${cluster.territory.name}`;
                  })
                  .join(' ')
              : 'No territory'}
          </p>
          <h2 className="col-span-6 text-lh-head-black text-lg font-bold">
            Region
          </h2>
          <p className="col-span-full text-lh-text-black text-sm font-normal">
            {user && user.cluster
              ? user.cluster.region.name
              : user && user.clusters
              ? user.clusters
                  .map((cluster, index) => {
                    return `${cluster.region.name}`;
                  })
                  .join(' ')
              : 'No territory'}
          </p>
          {checkAccess(['fsa']) && (
            <>
              <h2 className="col-span-6 text-lh-head-black text-lg font-bold">
                Assigned SOP
              </h2>
              <p className="col-span-full text-lh-text-black text-sm font-normal">
                Name: {user && user.sop && user.sop.name}
              </p>
              <p className="col-span-full text-lh-text-black text-sm font-normal">
                Email: {user && user.sop && user.sop.email}
              </p>
              <p className="col-span-full text-lh-text-black text-sm font-normal">
                Phone: {user && user.sop && user.sop.phone}
              </p>
            </>
          )}
          {checkAccess(['admin']) && (
            <Button
              className="bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded-md text-white cursor-pointer font-bold text-base h-10 px-6 w-80 mt-8"
              type="button"
              onClick={launchWhatsAppSignup}
            >
              Login with Facebook
            </Button>
          )}
        </div>
      </Card>
    </Content>
  );
});

export default Profile;
