import { initStore } from '../hooks/use-store';

type TargetStatus = 'fsa' | 'tpcp';

const configureTargetStatusStore = () => {
  const actions = {
    SET_TARGET_STATUS: (curTargetState, targetStatus: TargetStatus[]) => {
      return { ...curTargetState, targetStatus };
    },
  };
  initStore(actions, { targetStatus: ['fsa'] });
};

export default configureTargetStatusStore;
