import {
  Auth,
  AuthError,
  ConfirmationResult,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { useCallback, useMemo, useState } from 'react';
import { firestore } from '../utils/firebase';
import { PhoneActionHook } from './types';

interface PhoneAuthError extends AuthError {
  code: string;
}

export const useSignInWithPhone = (auth: Auth): PhoneActionHook => {
  const [error, setError] = useState<AuthError>();
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmationResult, setConfirmationResult] =
    useState<ConfirmationResult>();

  const signInWithPhone = useCallback(
    async (phone: string, appVerifier: RecaptchaVerifier) => {
      try {
        setLoading(true);
        const phoneRef = doc(firestore, 'phones', phone);
        const phoneSnap = await getDoc(phoneRef);
        let res;
        if (
          phoneSnap.exists() &&
          phoneSnap
            .data()
            .roles?.some((role) =>
              ['fsa', 'distributor', 'tae', 'fsm'].includes(role)
            )
        ) {
          res = await signInWithPhoneNumber(auth, phone, appVerifier);
        } else {
          await new Promise((resolve) => setTimeout(resolve, 400));
        }
        setConfirmationResult(res);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        let message = '';
        if (err.code === 'auth/too-many-requests') {
          message =
            'You tried to login too many times. Please try again later. It may take up to 1 hour for the block to be lifted.';
        } else {
          message =
            'Something went wrong. Please make sure you entered a valid phone number.';
        }
        const customError: PhoneAuthError = new Error(
          message
        ) as PhoneAuthError;

        customError.code = err.code;

        setError(customError);
      }
    },
    [auth]
  );

  const verifyCode = useCallback(
    async (code: string) => {
      try {
        setLoading(true);
        await confirmationResult.confirm(code);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err.code === 'auth/invalid-verification-code') {
          const customError: AuthError = new Error(
            'Your verification code is invalid.'
          ) as AuthError;
          setError(customError);
        } else if (err.code === 'auth/code-expired') {
          const customError: AuthError = new Error(
            'Your verification code expired. Please request a new one.'
          ) as AuthError;
          setError(customError);
        } else if (err.code === 'auth/too-many-requests') {
          const customError: AuthError = new Error(
            'You tried to login too many times. Please try again later. It may take up to 1 hour for the block to be lifted.'
          ) as AuthError;
          setError(customError);
        } else {
          setError(err.message);
        }
      }
    },
    [confirmationResult]
  );
  return useMemo<PhoneActionHook>(
    () => [signInWithPhone, verifyCode, loading, error],
    [signInWithPhone, verifyCode, loading, error]
  );
};
