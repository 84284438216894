import { initStore } from '../hooks/use-store';

type Status = 'pending' | 'accepted' | 'fulfilled';

const configureLeadStatusStore = () => {
  const actions = {
    SET_STATUS_LEAD: (curState, statusLead: Status[]) => {
      return { ...curState, statusLead };
    },
  };
  initStore(actions, { statusLead: ['pending'] });
};

export default configureLeadStatusStore;
