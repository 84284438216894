import dayjs from 'dayjs';
import { initStore } from '../hooks/use-store';

const configureDateRoutePlanningStore = () => {
  const actions = {
    SET_DATE_ROUTE_PLANNING: (curState, routePlanningDate: dayjs.Dayjs) => {
      return { routePlanningDate };
    },
    ADD_DAY_ROUTE_PLANNING: (curState) => {
      return { routePlanningDate: curState.routePlanningDate.add(1, 'month') };
    },
    SUBTRACT_DAY_ROUTE_PLANNING: (curState) => {
      return {
        routePlanningDate: curState.routePlanningDate.subtract(1, 'month'),
      };
    },
  };
  initStore(actions, { routePlanningDate: dayjs() });
};

export default configureDateRoutePlanningStore;
