import { initStore } from '../hooks/use-store';

type RouteFSA = Object;

const configureRouteFSAStore = () => {
  const actions = {
    SET_ROUTE_FSA: (curRouteFSA, routeFSA: RouteFSA) => {
      return { routeFSA };
    },
  };
  initStore(actions, { routeFSA: null });
};

export default configureRouteFSAStore;
