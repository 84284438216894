import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { RouteOutlet } from '../../../../global';
import { firestore } from '../utils/firebase';
import { RouteCreateData, UpdateRouteData } from './types';

export const createTempRoute = async (
  data: RouteCreateData,
  id?: string
): Promise<void> => {
  if (id) {
    const ref = doc(collection(firestore, 'routeChanges'), id);
    await setDoc(ref, data);
  } else {
    const ref = collection(firestore, 'routeChanges');
    await addDoc(ref, data);
  }
};

export const updateTempRoute = async (
  id: string,
  data: UpdateRouteData
): Promise<void> => {
  const ref = doc(firestore, 'routeChanges', id);
  await updateDoc(ref, data);
};

export const setOutletTempRoute = async (id: string, data: RouteOutlet) => {
  const ref = doc(firestore, `routeChanges/${id}/routeChangesOutlets`, data.id);
  await setDoc(ref, data);
};

export const deleteOutletTempRoute = async (id: string, outletId: string) => {
  const ref = doc(
    firestore,
    `routeChanges/${id}/routeChangesOutlets`,
    outletId
  );
  await deleteDoc(ref);
};

export const updateOutletTempRoute = async (
  id: string,
  outletId: string,
  data: any
) => {
  const ref = doc(
    firestore,
    `routeChanges/${id}/routeChangesOutlets`,
    outletId
  );
  await updateDoc(ref, data);
};
