import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import {
  DefaultValues,
  RegisterOptions,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { Confirm } from '../../components/forms/Confirm';
import Input from '../../components/forms/Input';
import Select from '../../components/forms/Select';
import Content from '../../components/layout/Content';
import Alert from '../../components/ui/Alert';
import Back from '../../components/ui/Back';
import Button from '../../components/ui/Button';
import Card from '../../components/ui/Card';
import Loader from '../../components/ui/Loader';
import { useAssociate } from '../../lib/hooks/use-associates';
import { useRoute, useRoutes } from '../../lib/hooks/use-routes';
import { useUser } from '../../lib/hooks/use-user';

type NewRouteFormValues = {
  route_name: string;
  route_day: string;
};

const defaultValues: DefaultValues<NewRouteFormValues> = {
  route_name: '',
  route_day: '',
};

const days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

type RouteParams = 'id';

const NewRoute = () => {
  const navigate = useNavigate();
  const { id } = useParams<RouteParams>();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { error: fsaError, associate: fsaSelected } = useAssociate(id);
  const { user } = useUser();
  const { create, loading } = useRoute();
  const {
    register,
    handleSubmit,
    formState: { isSubmitSuccessful, errors, isValid },
  } = useForm({ defaultValues, mode: 'all', reValidateMode: 'onChange' });
  const [showFSAAlert, setShowFSAAlert] = useState(false);

  const { routes } = useRoutes({
    fsaId: id ? id : '',
  });

  const routeNameOptions: RegisterOptions = {
    required: 'Please enter a route name...',
    maxLength: {
      value: 100,
      message: 'Route name cannot be more than 100 characters!',
    },
  };

  const routeDayOptions: RegisterOptions = {
    required: 'Please select a route day...',
    maxLength: {
      value: 100,
      message: 'Day cannot be more than 100 characters!',
    },
    validate: (v) => v !== 'placeholder',
  };

  useEffect(() => {
    if (fsaError) {
      setShowFSAAlert(true);
    } else if (showFSAAlert) {
      setShowFSAAlert(false);
    }
    return () => {
      setShowFSAAlert(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fsaError]);

  useEffect(() => {
    if (isSubmitSuccessful && !loading) {
      navigate('/routes', { replace: true });
    }
  }, [isSubmitSuccessful, loading, navigate]);

  const handleOnConfirm = () => {
    setShowConfirmation(false);
    handleSubmit(onSubmit)();
  };

  const handleOnCancel = () => {
    setShowConfirmation(false);
  };

  const onSubmit: SubmitHandler<NewRouteFormValues> = (data) => {
    if (!isValid) return;
    let fsa = null;

    if (user.roles.includes('sop') || user.roles.includes('fsm')) {
      fsa = fsaSelected;

      create({
        active: true,
        name: data.route_name,
        defaultDay: data.route_day,
        defaultDayNumber: days.findIndex((index) => index === data.route_day),
        fsa: {
          id: fsa.id,
          name: fsa.name,
          phone: fsa.phone,
        },
        outlets: 0,
        userIds: [fsa.id, user.id],
      });
    } else if (user.roles.includes('fsa')) {
      fsa = user;

      create({
        active: true,
        name: data.route_name,
        defaultDay: data.route_day,
        defaultDayNumber: days.findIndex((index) => index === data.route_day),
        fsa: {
          id: fsa.id,
          name: fsa.name,
          phone: fsa.phone,
        },
        outlets: 0,
        userIds: [fsa.id],
        newRoute: true,
        edited: false,
      });
    }
  };

  return (
    <>
      <Alert
        message={fsaError && fsaError.message}
        open={showFSAAlert}
        setOpen={(open) => setShowFSAAlert(open)}
        title="Error"
      />
      <Loader show={loading} />
      <Confirm
        title="Are you sure you want to create this new route?"
        open={showConfirmation}
        onConfirm={handleOnConfirm}
        onCancel={handleOnCancel}
        setOpen={(open) => {
          setShowConfirmation(open);
        }}
      />
      <Content>
        <Card className="grid grid-cols-12">
          <Back to="/routes" className="col-span-4" />
          <div className="col-span-4 flex justify-center items-center text-center text-xl font-bold text-lh-head-black">
            <FontAwesomeIcon
              icon={faPlusCircle}
              className="mr-2 text-lh-head-black"
            />
            New Route
          </div>
          <form
            name="newRouteForm"
            onSubmit={handleSubmit(onSubmit)}
            className="grid grid-cols-12 col-span-full mt-2"
          >
            <p className=" font-bold col-span-12 text-lh-text-black">
              Selected FSA:
            </p>
            <p className="col-span-12 text-lh-text-black">
              {fsaSelected ? fsaSelected.name : ''}
            </p>

            <h2 className="font-bold col-span-12 mt-2 mb-2 text-lh-head-black">
              Route name
            </h2>
            <Input
              name="route_name"
              register={register}
              options={routeNameOptions}
              error={errors.route_name}
              type="text"
              className="col-span-full text-lh-text-black"
            />
            <label
              htmlFor="routeDay"
              className="col-span-full font-bold text-lh-head-black"
            >
              Default route day
            </label>
            <Select
              name="route_day"
              register={register}
              options={routeDayOptions}
              error={errors.route_day}
              placeholder="Select default route day..."
              items={
                days
                  ? days
                      .filter(
                        (el1) => !routes.find((el2) => el1 === el2.defaultDay)
                      )
                      .map((days) => ({
                        value: days,
                        key: days,
                        label: days,
                      }))
                  : []
              }
              className="col-span-full text-lh-text-black p-1"
            />
          </form>
          <Button
            buttonDisabled={!isValid}
            text="Create route"
            color="green"
            className="col-span-full"
            onClick={() => {
              setShowConfirmation(true);
            }}
          />
        </Card>
      </Content>
    </>
  );
};

export default NewRoute;
