import { useMemo } from 'react';
import {
  ErrorOption,
  Path,
  RegisterOptions,
  UseFormReturn,
} from 'react-hook-form';
import { InputType } from '../../../../global';
import clsx from 'clsx';

export interface InputProps<T>
  extends Partial<Pick<UseFormReturn<T>, 'register' | 'formState'>> {
  name: Path<T>;
  type?: InputType;
  options?: RegisterOptions;
  className?: string;
  rules?: any;
  placeholder?: string;
  value?: string;
  error?: ErrorOption;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  accept?: string;
  capture?: boolean | "user" | "environment";
}

const Input = <T,>({
  name,
  type,
  options,
  register,
  className,
  value,
  error,
  disabled,
  ...rest
}: InputProps<T>) => {
  const style = useMemo(() => clsx('mb-4', className), [className]);
  const inputStyle = useMemo(
    () =>
      clsx(
        'bg-gray-200 rounded-md py-1 px-3 border-2 disabled:opacity-50 w-full',
        error && 'border-red-500 bg-red-200 focus:ring-red-500'
      ),
    [error]
  );

  return (
    <div className={style}>
      <input
        disabled={disabled}
        className={inputStyle}
        type={type}
        {...register(name, options)}
        {...rest}
        value={value}
      />
      {error && (
        <p className="pl-2 text-sm text-red-500 tracking-normal">
          {error.message}
        </p>
      )}
    </div>
  );
};

export default Input;
