import { useEffect, useState } from 'react';
import Loader from '../../components/ui/Loader';
import List from '../../components/ui/list/List';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faAngleRight,
  faSync,
} from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../lib/hooks/use-auth';
import { useVisits } from '../../lib/hooks/use-visits';
import Visit from '../../components/visits/Visit';
import Content from '../../components/layout/Content';
import TopBar from '../../components/layout/TopBar';
import ButtonBottomRight from '../../components/ui/ButtonBottomRight';
import Alert from '../../components/ui/Alert';
import { useRoute } from '../../lib/hooks/use-routes';
import dayjs from 'dayjs';
import { useRoutePlans } from '../../lib/hooks/use-routeplans';
import useStore from '../../lib/hooks/use-store';

const Visits = () => {
  const { id } = useAuth();
  const [state, dispatch] = useStore();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showAlertRoutePlan, setShowAlertRoutePlan] = useState<boolean>(false);
  const {
    visits,
    loading,
    hasMore,
    load,
    reset: resetVisits,
  } = useVisits({
    day: state.dateVisits.date(),
    month: state.dateVisits.month() + 1,
    year: state.dateVisits.year(),
  });

  const [routeId, setRouteId] = useState(null);
  const {
    error: routePlanError,
    routePlans,
    loading: routePlanLoading,
    reset: resetRoutePlans,
  } = useRoutePlans({
    fsaId: id,
    day: state.dateVisits.date(),
    month: state.dateVisits.month() + 1,
    year: state.dateVisits.year(),
  });

  const {
    route,
    error: routeError,
    loading: routeLoading,
    outlets,
  } = useRoute(routeId);

  useEffect(() => {
    if (
      routePlans &&
      routePlans.length > 0 &&
      routePlans[0].route &&
      routePlans[0].route.id
    ) {
      setRouteId(routePlans[0].route.id);
    } else {
      setRouteId('');
    }
  }, [routePlans]);

  const onDayChange = (direction) => {
    if (direction === 'add') {
      dispatch('ADD_DAY_VISITS', {});
      resetAll();
    } else {
      dispatch('SUBTRACT_DAY_VISITS', {});
      resetAll();
    }
  };

  const resetAll = () => {
    resetVisits();
    resetRoutePlans();
  };

  useEffect(() => {
    console.log('visits', visits);
  }, [visits]);

  return (
    <Content topBar>
      <Alert
        title="Error"
        message={routeError && routeError.message}
        open={showAlert}
        setOpen={(open) => setShowAlert(open)}
      />
      <Alert
        title="Error"
        message={routePlanError && routePlanError.message}
        open={showAlertRoutePlan}
        setOpen={(open) => setShowAlertRoutePlan(open)}
      />
      <ButtonBottomRight
        onClick={() => resetAll()}
        blocked
        arialabel="reload"
        icon={faSync}
      />
      <TopBar>
        <div className="flex justify-between w-full flex-wrap">
          <div className="w-full grid col-span-11 grid-cols-12 justify-center mt-4">
            <button
              className={
                'font-semibold md:col-span-1 md:col-start-5 col-span-1 col-start-4 text-center cursor-pointer'
              }
              onClick={() => onDayChange('substract')}
            >
              <FontAwesomeIcon
                icon={faAngleLeft}
                className={'font-semibold '}
              />
            </button>

            <p
              className={
                'font-semibold align-middle text-center md:col-span-2 md:col-start-6 col-span-4 col-start-5 pt-4'
              }
            >
              {state.dateVisits.format('DD.MM.YYYY') ===
              dayjs().format('DD.MM.YYYY')
                ? 'Today'
                : state.dateVisits.format('DD.MM.YYYY')}
            </p>

            <button
              className={
                'font-semibold md:col-span-1 md:col-start-8 col-span-1 col-start-9 text-center cursor-pointer block'
              }
              onClick={() => onDayChange('add')}
            >
              <FontAwesomeIcon
                icon={faAngleRight}
                className={'font-semibold'}
              />
            </button>
          </div>
        </div>
      </TopBar>
      <Loader show={loading || routePlanLoading || routeLoading} />
      <div className="w-full grid grid-cols-12">
        <p className="col-span-4 text-center font-bold">
          {route && route.name}
        </p>
        <p className="col-span-4 text-center font-bold">
          {route && route.defaultDay}
        </p>
        <p className="col-span-4 text-center font-bold">
          {route &&
            route.outlets + ' ' + (route.outlets > 1 ? 'Outlets' : 'Outlet')}
        </p>
      </div>
      <List load={load} hasMore={hasMore} loading={loading}>
        {outlets &&
          visits &&
          outlets
            .filter(
              (outlet) => !visits.find((visit) => outlet.id === visit.outlet.id)
            )
            .map((outlet) => (
              <Visit
                outletId={outlet.id}
                retailerId={outlet.retailerId}
                retailerName={outlet.name}
                key={outlet.id}
                done={false}
                clickable={
                  state.dateVisits.format('DD.MM.YYYY') ===
                  dayjs().format('DD.MM.YYYY')
                }
              />
            ))}
        {outlets &&
          visits &&
          outlets
            .filter((outlet) =>
              visits.find((visit) => outlet.id === visit.outlet.id)
            )
            .map((outlet) => (
              <Visit
                outletId={outlet.id}
                retailerId={outlet.retailerId}
                retailerName={outlet.name}
                key={outlet.id}
                done={true}
                visit={visits.find((visit) => outlet.id === visit.outlet.id)}
              />
            ))}
        {!outlets ||
          !visits ||
          (outlets && outlets.length === 0 && visits.length === 0 && (
            <p className="justify-between mx-2 mb-2 h-18 list-none shadow-md rounded-lg pb-4 px-2 pt-2 bg-white text-center">
              No visits for this day.
            </p>
          ))}
      </List>
    </Content>
  );
};

export default Visits;
