import { useEffect } from 'react';
import { RouteOutlet } from '../../../../global';
import RouteElement from '../routes/RouteElement';

type SortableListProps = {
  items: RouteOutlet[];
  type: string;
  loading: boolean;
  removeOutletFunction?: Function;
  moveHigherFunction?: Function;
  moveLowerFunction?: Function;
  tempRoute?: boolean;
  routeEdited?: boolean;
};

const List = ({
  items,
  type,
  loading,
  removeOutletFunction,
  moveHigherFunction,
  moveLowerFunction,
  tempRoute,
  routeEdited,
}: SortableListProps) => {
  const listItems = items.map((item) => {
    switch (type) {
      case 'routes':
        return (
          <RouteElement
            key={item.id}
            name={item.name}
            {...item}
            removeOutlet={removeOutletFunction}
            moveHigher={moveHigherFunction}
            moveLower={moveLowerFunction}
            tempRoute={tempRoute}
            routeEdited={routeEdited}
          />
        );
      default:
        return null;
    }
  });

  useEffect(() => {}, [items]);
  return (
    <ul className="grid grid-col-12 col-span-full w-full md:min-w-min md:w-1/2 auto-rows-min mx-2 my-4 px-6 py-4 overflow-auto">
      {listItems}
    </ul>
  );
};

export default List;
