import { FirebaseError } from 'firebase/app';
import {
  collection,
  doc,
  DocumentSnapshot,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  Unsubscribe,
  where,
} from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { CreateSiteOwnerData, User, UserChanges } from '../../../../global';
import { useCheckAccess } from '../auth/use-checkAccess';
import {
  confirmSiteOwnerChanges,
  confirmSiteOwnerChangesRejection,
  confirmSiteOwnerRegistration,
  confirmSiteOwnerRejection,
  createSiteOwner,
  registerSiteOwnerChanges,
  rejectSiteOwnerChanges,
  rejectSiteOwnerRegistration,
} from '../db/siteOwners';
import { useLoadingValue } from '../utils';
import { docToJSON, firestore } from '../utils/firebase';
import { useAuth } from './use-auth';

export const useSiteOwners = (selectedCategory?: string, pageSize = 20) => {
  const { id } = useAuth();
  const [siteOwners, setSiteOwners] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [lastDoc, setLastDoc] = useState(null);

  const loadInitial = async () => {
    if (!id) return;
    setLoading(true);
    if (siteOwners.length !== 0) {
      setSiteOwners([]);
      setLastDoc(null);
      setHasMore(true);
    }

    const siteOwnersWhere = [where('roles', 'array-contains', 'siteOwner')];

    switch (selectedCategory) {
      case 'actionRequired': {
        siteOwnersWhere.push(where('status', 'in', ['changes', 'unconfirmed']));
        break;
      }
      case 'numberWrong': {
        siteOwnersWhere.push(where('phoneCorrect', '==', false));
        break;
      }
      case 'noWhatsApp': {
        siteOwnersWhere.push(where('hasWhatsApp', '==', false));
        break;
      }
      case 'enabledWhatsApp': {
        siteOwnersWhere.push(where('enabledWhatsApp', '==', true));
        break;
      }
      default: {
        break;
      }
    }

    const siteOwnersQuery = query(
      collection(firestore, `users/${id}/associates`),
      ...siteOwnersWhere,
      orderBy('name'),
      startAfter(lastDoc),
      limit(pageSize)
    );

    const siteOwnersSnap = await getDocs(siteOwnersQuery);

    setLoading(false);
    if (siteOwnersSnap.empty) {
      setHasMore(false);
      return;
    }
    const newSiteOwners = siteOwnersSnap.docs.map(
      (ret) => docToJSON(ret) as User
    );
    if (newSiteOwners.length < pageSize) setHasMore(false);
    setSiteOwners(newSiteOwners);
    setLastDoc(siteOwnersSnap.docs[newSiteOwners.length - 1]);
  };

  const loadMore = async () => {
    if (!id) return;
    if (!hasMore) return;
    setLoading(true);
    const siteOwnersWhere = [where('roles', 'array-contains', 'siteOwner')];

    switch (selectedCategory) {
      case 'actionRequired': {
        siteOwnersWhere.push(where('status', 'in', ['changes', 'unconfirmed']));
        break;
      }
      case 'numberWrong': {
        siteOwnersWhere.push(where('phoneCorrect', '==', false));
        break;
      }
      case 'noWhatsApp': {
        siteOwnersWhere.push(where('hasWhatsApp', '==', false));
        break;
      }
      case 'enabledWhatsApp': {
        siteOwnersWhere.push(where('enabledWhatsApp', '==', true));
        break;
      }
      default: {
        break;
      }
    }

    const siteOwnersQuery = query(
      collection(firestore, `users/${id}/associates`),
      ...siteOwnersWhere,
      orderBy('name'),
      limit(pageSize)
    );

    const siteOwnersSnap = await getDocs(siteOwnersQuery);
    setLoading(false);
    if (siteOwnersSnap.empty) {
      setHasMore(false);
      return;
    }
    const newSiteOwners = siteOwnersSnap.docs.map(
      (ret) => docToJSON(ret) as User
    );
    if (newSiteOwners.length < pageSize) setHasMore(false);
    setSiteOwners(siteOwners.concat(newSiteOwners));
    setLastDoc(siteOwnersSnap.docs[newSiteOwners.length - 1]);
  };

  return {
    siteOwners,
    loading,
    hasMore,
    loadInitial,
    loadMore,
  };
};

export const useSiteOwner = (id?: string) => {
  const checkAccess = useCheckAccess();
  const { error, loading, setError, setValue, value, setLoading } =
    useLoadingValue<DocumentSnapshot, FirebaseError>();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (id) {
      if (!loading) setLoading(true);
      const ref = doc(firestore, 'users', id);
      unsubscribe = onSnapshot(ref, setValue, setError);
    }
    setValue(undefined);
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const create = async (data: CreateSiteOwnerData) => {
    try {
      setLoading(true);
      await createSiteOwner(checkAccess(['sop']), data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const update = async (changes: UserChanges) => {
    if (!id) return;
    try {
      setLoading(true);
      await registerSiteOwnerChanges(id, checkAccess(['sop']), changes);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
      setError(err);
    }
  };

  const confirmRegistration = async (confirmed: boolean, comment?: string) => {
    if (!id || !checkAccess(['sop'])) return;
    try {
      setLoading(true);
      confirmed
        ? await confirmSiteOwnerRegistration(id)
        : await rejectSiteOwnerRegistration(id, comment);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  const confirmChanges = async (
    confirmed: boolean,
    changes: UserChanges,
    comment?: string
  ) => {
    if (!id || !checkAccess(['sop'])) return;
    try {
      setLoading(true);
      confirmed
        ? await confirmSiteOwnerChanges(id, changes)
        : await rejectSiteOwnerChanges(id, changes, comment);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  const confirmRejection = async () => {
    if (!id || !checkAccess(['tae'])) return;
    try {
      setLoading(true);
      confirmSiteOwnerRejection(id);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  const confirmChangesRejection = async () => {
    if (!id || !checkAccess(['tae'])) return;
    try {
      setLoading(true);
      confirmSiteOwnerChangesRejection(id);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  useEffect(() => {
    console.log(error);
  }, [error]);

  return useMemo(
    () => ({
      siteOwner: value ? (docToJSON(value) as User) : null,
      error,
      loading,
      create,
      update,
      confirmChanges,
      confirmRegistration,
      confirmRejection,
      confirmChangesRejection,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [error, loading, value]
  );
};
