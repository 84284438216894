import clsx from 'clsx';
import Content from '../../components/layout/Content';
import TopBar from '../../components/layout/TopBar';
import List from '../../components/ui/list/List';
import Loader from '../../components/ui/Loader';
import useStore from '../../lib/hooks/use-store';
import { CoPilotActionCard } from '../../components/copilot/CoPilotActionCard';
import { useCopilot } from '../../lib/hooks/use-copilot';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

const CoPilot = () => {
  const [state, dispatch] = useStore();
  const { error, hasMore, load, loading, documents } = useCopilot({
    loadData: state.copilotTabStatus,
    direction: 'asc',
    actionTaken: state.copilotTabStatus,
  });

  const readStyle = clsx(
    'flex justify-center content-center flex-col text-center flex-grow cursor-pointer text-lh-dark-blue text-xl font-medium border-b-2 border-lh-dark-blue h-full',
    state.copilotTabStatus && 'border-b-4 border-lh-dark-blue font-semibold'
  );
  const unreadStyle = clsx(
    'flex justify-center content-center flex-col text-center flex-grow cursor-pointer text-lh-dark-blue text-xl font-medium border-b-2 border-lh-dark-blue h-full',
    !state.copilotTabStatus && 'border-b-4 border-lh-dark-blue font-semibold'
  );

  const handleTabChange = (showHistory: boolean) => () => {
    dispatch('SET_COPILOT_TAB_STATUS', showHistory);
  };

  useEffect(() => {
    if (error) {
      toast.error(error.message, { toastId: 'copilot', autoClose: false });
    }
  }, [error]);

  return (
    <Content topBar>
      <TopBar>
        <div className={unreadStyle} onClick={handleTabChange(false)}>
          Follow ups (action required)
        </div>
        <div className={readStyle} onClick={handleTabChange(true)}>
          History
        </div>
      </TopBar>
      <Loader show={loading} />
      <List load={load} hasMore={hasMore} loading={loading}>
        {documents && documents.length > 0 ? (
          documents.map((n) => <CoPilotActionCard {...n} key={n.id} />)
        ) : (
          <p className="justify-between mx-2 mb-2 h-18 list-none shadow-md rounded-lg pb-4 px-2 pt-2 bg-white text-center">
            {state.copilotTabStatus ? 'No history' : 'No follow ups'}
          </p>
        )}
      </List>
    </Content>
  );
};

export default CoPilot;
