import { initStore } from '../hooks/use-store';

type SelectedFSAOrders = { value: string, label: string };

const configureSelectedFSAOrdersStore = () => {
  const actions = {
    SET_SELECTED_FSA_ORDERS: (curState, selectedFSAOrders: SelectedFSAOrders) => {
      return { ...curState, selectedFSAOrders };
    },
  };
  initStore(actions, { selectedFSAOrders: null });
};

export default configureSelectedFSAOrdersStore;
