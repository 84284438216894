import { RegisterOptions, useForm } from 'react-hook-form';
import Input from '../forms/Input';
import Submit from '../forms/Submit';
import Back from '../ui/Back';
import { useEffect } from 'react';

type SendCodeProps = {
  handleSendCode: (phone: string) => void;
  phone?: string;
  disableSendButton?: boolean;
};

type SendCodeFormValues = {
  phone: string;
};

export const SendCode: React.FC<SendCodeProps> = ({
  handleSendCode,
  phone,
  disableSendButton,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<SendCodeFormValues>();

  const sendCodeOptions: RegisterOptions = {
    required: 'Please enter a phone number!',
    pattern: {
      value: /^\+[0-9]{11,13}$/,
      message: 'Please enter a valid phone number!',
    },
  };

  useEffect(() => {
    if (phone) {
      reset({ phone });
    }
  }, [phone, reset]);

  const submitHandler = (data: SendCodeFormValues) => {
    handleSendCode(data.phone);
  };

  return (
    <>
      <form
        className="flex justify-center flex-col w-full"
        name="sendCodeForm"
        onSubmit={handleSubmit(submitHandler)}
      >
        <div className="flex justify-between">
          <Back to="/enter " />
          <h1 className="text-2xl font-bold text-center pt-4">
            Welcome to REAL
          </h1>
          <div className="w-8"></div>
        </div>
        <div className="w-full text-center font-thin mt-12">
          <p>Enter phone number to login</p>
        </div>
        <Input
          type="tel"
          placeholder="+234XXXXXXXXXX"
          name="phone"
          register={register}
          error={errors.phone}
          options={sendCodeOptions}
          className="mt-4 mb-0 text-3xl tracking-widest"
        ></Input>
        <Submit
          className="mt-4"
          value="Send code"
          color="gray"
          disabled={disableSendButton}
        />
      </form>
    </>
  );
};
