import { initStore } from '../hooks/use-store';

const configureCopilotTabStatus = () => {
  const actions = {
    SET_COPILOT_TAB_STATUS: (curState, showHistory: boolean) => {
      return { ...curState, copilotTabStatus: showHistory };
    },
  };
  initStore(actions, { copilotTabStatus: false });
};

export default configureCopilotTabStatus;
