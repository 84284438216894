import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Route as RouteType } from '../../../../global';
import { useCheckAccess } from '../../lib/auth/use-checkAccess';

type RouteProps = {
  id: string;
  routeName: string;
  routeFSA: string;
  routeOutlets: number;
  routeDefaultDay: string;
  route: RouteType;
};

const Route = (props: RouteProps) => {
  const navigate = useNavigate();
  const checkAccess = useCheckAccess();

  return (
    <li
      className="flex justify-between mx-2 mb-2 h-18 list-none shadow-md rounded-lg pb-4 px-2 pt-2 cursor-pointer bretailer-lh-red bg-white"
      onClick={() => navigate(`/routes/${props.id}`)}
    >
      <div className="grid grid-cols-12 w-full">
        <div className="col-span-12">
          <p className="col-span-6 text-sm mt-1 font-bold">{` ${props.routeName}`}</p>
          <p className="col-span-6 text-sm mt-1">
            <FontAwesomeIcon icon={faUserCircle} />
            {` ${props.routeFSA}`}
          </p>
          <p className=" text-xs mt-2">{`${
            props.routeDefaultDay ? props.routeDefaultDay : 'No default day'
          } `}</p>
        </div>
      </div>
      <p className="self-center text-right text-sm w-32 mt-2">
        {props.routeOutlets && props.routeOutlets > 1
          ? `${props.routeOutlets} Outlets `
          : `${props.routeOutlets} Outlet `}
        <FontAwesomeIcon icon={faAngleRight} />
        {props.route.newRoute && (
          <p className="self-center text-right text-sm w-36 pr-5 mt-2 text-green-600 font-bold">
            New route
          </p>
        )}
        {props.route.edited && (
          <p className="self-center text-right text-sm w-36 pr-5 mt-2 text-red-600 font-bold">
            Changes pending
          </p>
        )}

        {checkAccess(['fsa']) &&
          !props.route.changeConfirmed &&
          props.route.lastEditStatus &&
          props.route.lastEditStatus === 'accepted' && (
            <p className="self-center text-right text-sm w-36 pr-5 mt-2 text-green-600 font-bold">
              Changes {props.route.lastEditStatus}
            </p>
          )}
        {checkAccess(['fsa']) &&
          !props.route.changeConfirmed &&
          props.route.lastEditStatus &&
          props.route.lastEditStatus === 'rejected' && (
            <p className="self-center text-right text-sm w-36 pr-5 mt-2 text-red-600 font-bold">
              Changes {props.route.lastEditStatus}
            </p>
          )}
      </p>
    </li>
  );
};

export default Route;
