import { useEffect, useState } from 'react';
import Loader from '../../components/ui/Loader';
import Content from '../../components/layout/Content';
import TopBar from '../../components/layout/TopBar';
import Alert from '../../components/ui/Alert';
import Select from 'react-select';
import { useAssociate, useAssociates } from '../../lib/hooks/use-associates';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import useStore from '../../lib/hooks/use-store';
import Button from '../../components/ui/Button';
import { useRoutePlan, useRoutePlans } from '../../lib/hooks/use-routeplans';
import AssingRoute from '../../components/routePlans/AssignRoute';
import { Route } from '../../../../global';
import { useRoutes } from '../../lib/hooks/use-routes';
import { Timestamp } from 'firebase/firestore';
import CalendarDay from '../../components/routePlans/CalendarDay';
import { useCheckAccess } from '../../lib/auth/use-checkAccess';
import { useAuth } from '../../lib/hooks/use-auth';

//Months
const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

//Week days
const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const RoutesPlanningPage = () => {
  const [state, dispatch] = useStore();
  const { id } = useAuth();
  const checkAccess = useCheckAccess();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showAlertRoutePlans, setShowAlertRoutePlans] =
    useState<boolean>(false);
  const [fsaSelected, setFSASelected] = useState(null);
  const [userId, setUserId] = useState('');
  const [days, setDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);
  const { error, routePlans, loading, reset } = useRoutePlans({
    fsaId: fsaSelected ? fsaSelected.value : '',
    month: state.routePlanningDate.month() + 1,
    year: state.routePlanningDate.year(),
  });
  const [showAssignOutlet, setShowAssignOutlet] = useState(false);
  const {
    create,
    update,
    removeRoutePlan,
    loading: routePlanLoading,
  } = useRoutePlan();

  const { associate: userAssociate, loading: userAssociateLoading } =
    useAssociate(userId);

  const { routes } = useRoutes({
    fsaId: fsaSelected ? fsaSelected.value : '',
  });

  const { associates: fsas } = useAssociates({ roles: 'fsa', pageSize: 0 });

  useEffect(() => {
    if (id) {
      setUserId(id);
    }
  }, [id]);

  useEffect(() => {
    if (userAssociate && checkAccess(['fsa'])) {
      setFSASelected({
        value: userAssociate.id,
        label: userAssociate.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAssociate]);

  const onMonthChange = (direction) => {
    if (direction === 'add') {
      if (state.routePlanningDate.diff(dayjs().startOf('month'), 'month') >= 1)
        return;
      dispatch('ADD_DAY_ROUTE_PLANNING', {});
    } else {
      if (
        state.routePlanningDate
          .subtract(1, 'month')
          .isBefore('2021-03-01', 'month')
      )
        return;
      dispatch('SUBTRACT_DAY_ROUTE_PLANNING', {});
    }
  };

  useEffect(() => {
    if (fsaSelected) {
      let newDays = [];

      // if first day is sunday, create 6 days before
      if (state.routePlanningDate.startOf('month').day() - 2 === -2) {
        for (let i = 5; i >= 0; i--) {
          let monthBefore = state.routePlanningDate.subtract(1, 'month');
          newDays.push({
            day: monthBefore.daysInMonth() - i,
            style: 'before',
            month: state.routePlanningDate.subtract(1, 'month').month() + 1,
            year: state.routePlanningDate.subtract(1, 'month').year(),
          });
        }
      }

      //get days until monday before month starts
      for (
        let i = state.routePlanningDate.startOf('month').day() - 2;
        i >= 0;
        i--
      ) {
        let monthBefore = state.routePlanningDate.subtract(1, 'month');
        newDays.push({
          day: monthBefore.daysInMonth() - i,
          style: 'before',
          month: state.routePlanningDate.subtract(1, 'month').month() + 1,
          year: state.routePlanningDate.subtract(1, 'month').year(),
        });
      }

      //add days of month
      for (let i = 1; i <= state.routePlanningDate.daysInMonth(); i++) {
        if (
          (i < dayjs().date() &&
            state.routePlanningDate.month() + 1 === dayjs().month() + 1) ||
          state.routePlanningDate.month() + 1 < dayjs().month() + 1 ||
          state.routePlanningDate.year() < dayjs().year()
        ) {
          newDays.push({
            day: i,
            style: 'month_past',
            month: state.routePlanningDate.month() + 1,
            year: state.routePlanningDate.year(),
          });
        } else {
          newDays.push({
            day: i,
            style: 'month',
            month: state.routePlanningDate.month() + 1,
            year: state.routePlanningDate.year(),
          });
        }
      }

      //add days for next month until 42
      let moreDays = newDays.length;
      for (let i = 1; i + moreDays <= 42; i++) {
        newDays.push({
          day: i,
          style: 'after',
          month: state.routePlanningDate.add(1, 'month').month() + 1,
          year: state.routePlanningDate.add(1, 'month').year(),
        });
      }

      if (routePlans && routePlans.length > 0) {
        newDays = newDays.map((day) => {
          const routePlan = routePlans.find(
            (rp) =>
              rp.day === day.day &&
              rp.month === day.month &&
              rp.year === day.year
          );
          day.routePlan = routePlan;
          return day;
        });
      }

      if (routes.length > 0 && !loading) {
        newDays = newDays.map((day) => {
          const route = routes.find(
            (rp) =>
              rp.defaultDay ===
              weekDays[
                dayjs(
                  day.month + '-' + day.day + '-' + day.year,
                  'MM-DD-YYYY'
                ).day()
              ]
          );

          if (route && day.style === 'month' && !day.routePlan) {
            const newRoutePlan = {
              active: true,
              fsaId: route.fsa.id,
              fsa: route.fsa,
              day: day.day,
              month: day.month,
              route: route,
              year: day.year,
              name: route.name,
              userIds: [
                route.fsa.id,
                route.sop.id,
                ...('fsm' in route ? [route.fsm.id] : []),
              ],
              routePlanDate: Timestamp.fromDate(
                dayjs()
                  .set('year', day.year)
                  .set('month', day.month - 1)
                  .set('date', day.day)
                  .toDate()
              ),
              date: Timestamp.now(),
            };

            create(newRoutePlan);

            day.routePlan = newRoutePlan;
            return day;
          }
          return day;
        });
      }

      setDays(newDays);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routePlans, routes]);

  const handleOnAssign = (route: Route) => {
    let newRoutePlan;
    const newDays = days.map(async (day) => {
      if (
        day.day === selectedDay.day &&
        day.month === selectedDay.month &&
        day.year === selectedDay.year
      ) {
        if (day.routePlan) {
          day.routePlan.route = route;
          await update(day.routePlan.id, { route: route });
        } else {
          newRoutePlan = {
            active: true,
            fsa: route.fsa,
            day: day.day,
            month: day.month,
            route,
            year: day.year,
            name: route.name,
            userIds: [
              route.fsa.id,
              route.sop.id,
              ...('fsm' in route ? [route.fsm.id] : []),
            ],
            routePlanDate: Timestamp.fromDate(
              dayjs()
                .set('year', day.year)
                .set('month', day.month - 1)
                .set('date', day.day)
                .toDate()
            ),
            date: Timestamp.now(),
            ...(route.group && { group: route.group }),
          };

          await create(newRoutePlan);
        }
        reset();
      }
      return day;
    });
    setSelectedDay(null);
    setDays(newDays);
    setShowAssignOutlet(false);
  };

  const changeMonthAndSelect = (action, day) => {
    if (action === 'add') {
      setSelectedDay(day);
      onMonthChange('add');
    } else {
      setSelectedDay(day);
      onMonthChange('substract');
    }
  };

  const deleteRoutePlan = async (deleteSelectedDay) => {
    await removeRoutePlan(deleteSelectedDay.routePlan.id);
    setSelectedDay(null);
    reset();
  };

  return (
    <Content topBar>
      <Alert
        title="Error"
        message={'Error'}
        open={showAlert}
        setOpen={(open) => setShowAlert(open)}
      />
      <Alert
        title="Error"
        message={error && error.message}
        open={showAlertRoutePlans}
        setOpen={(open) => setShowAlertRoutePlans(open)}
      />
      {/* TODO: add correct reload 
      <ButtonBottomRight onClick={() => console.log('test')} blocked arialabel="reload"  icon={faSync} />
      */}
      <TopBar>
        <div className="flex justify-between w-full flex-wrap">
          <div className="w-full grid grid-cols-12 col-span-12">
            {checkAccess(['sop', 'fsm']) && (
              <Select
                options={
                  fsas
                    ? fsas.map((fsa) => ({
                        value: fsa.id,
                        label: fsa.name,
                      }))
                    : []
                }
                placeholder={'Select FSA...'}
                onChange={(fsa) => setFSASelected(fsa)}
                value={fsaSelected}
                defaultValue={fsaSelected}
                className="mt-4 col-span-6 col-start-4 justify-center text-lh-text-black"
              />
            )}
            <div className="w-full grid col-span-12 grid-cols-12 justify-center mt-4">
              <button
                className={
                  'font-semibold md:col-span-1 md:col-start-5 col-span-1 col-start-4 text-center cursor-pointer'
                }
                onClick={() => onMonthChange('substract')}
              >
                <FontAwesomeIcon
                  icon={faAngleLeft}
                  className={'font-semibold '}
                />
              </button>

              <p
                className={
                  'font-semibold align-middle text-center md:col-span-2 md:col-start-6 col-span-4 col-start-5 pt-1'
                }
              >
                {monthNames[state.routePlanningDate.month()] +
                  ' ' +
                  state.routePlanningDate.year()}
              </p>

              <button
                className={
                  'font-semibold md:col-span-1 md:col-start-8 col-span-1 col-start-9 text-center cursor-pointer'
                }
                onClick={() => onMonthChange('add')}
              >
                <FontAwesomeIcon
                  icon={faAngleRight}
                  className={'font-semibold'}
                />
              </button>
            </div>
          </div>
          <div className="w-full grid col-span-11 grid-cols-11 justify-center mt-4"></div>
        </div>
      </TopBar>
      <AssingRoute
        open={showAssignOutlet}
        setOpen={setShowAssignOutlet}
        onAssign={handleOnAssign}
        fsaId={fsaSelected ? fsaSelected.value : ''}
      />
      <Loader show={loading || routePlanLoading || userAssociateLoading} />
      <div className="w-full grid grid-cols-7 mt-8 border-2">
        <div className={'col-span-1 text-center py-3'}>
          <p>Mon</p>
        </div>
        <div className={'col-span-1 text-center py-3'}>
          <p>Tue</p>
        </div>
        <div className={'col-span-1 text-center py-3'}>
          <p>Wed</p>
        </div>
        <div className={'col-span-1 text-center py-3'}>
          <p>Thu</p>
        </div>
        <div className={'col-span-1 text-center py-3'}>
          {' '}
          <p>Fr</p>
        </div>
        <div className={'col-span-1 text-center py-3'}>
          <p>Sa</p>
        </div>
        <div className={'col-span-1 text-center py-3'}>
          <p>Sun</p>
        </div>
        {days &&
          days.length > 0 &&
          days.map((day) => {
            return (
              <CalendarDay
                dayObject={day}
                day={day.day}
                month={day.month}
                year={day.year}
                style={day.style}
                setSelectedDay={setSelectedDay}
                changeMonthAndSelect={changeMonthAndSelect}
                selectedDay={selectedDay}
                routeName={
                  day && day.routePlan && day.routePlan.route
                    ? day.routePlan.route.name
                    : ''
                }
              />
            );
          })}
      </div>
      <div className="w-full grid grid-cols-3 mt-8 ">
        {selectedDay && selectedDay.style !== 'month_past' && (
          <div className="cols-span-1 col-start-2">
            <p className="text-center font-bold">
              Selected Day: {selectedDay.day}.{selectedDay.month}.
              {selectedDay.year}
            </p>
            {!selectedDay ||
            !selectedDay.routePlan ||
            !selectedDay.routePlan.route ? (
              <Button
                onClick={() => setShowAssignOutlet(true)}
                color="green"
                text="Add route"
                className="w-full mt-4"
              />
            ) : (
              ''
            )}
            {selectedDay &&
              selectedDay.routePlan &&
              selectedDay.routePlan.route && (
                <>
                  <p className="text-center mt-4">
                    {selectedDay.routePlan.route.name}
                  </p>
                  <Button
                    onClick={() => deleteRoutePlan(selectedDay)}
                    color="green"
                    text="Remove route"
                    className="w-full mt-4"
                  />
                </>
              )}
          </div>
        )}
        {selectedDay && selectedDay.style === 'month_past' && (
          <div className="cols-span-1 col-start-2">
            <p className="text-center font-bold">
              Selected Day: {selectedDay.day}.{selectedDay.month}.
              {selectedDay.year}
            </p>
            {!selectedDay ||
            !selectedDay.routePlan ||
            !selectedDay.routePlan.route ? (
              <p className="text-center mt-4">No route on this day.</p>
            ) : (
              ''
            )}
            {selectedDay &&
              selectedDay.routePlan &&
              selectedDay.routePlan.route && (
                <>
                  <p className="text-center mt-4">
                    {selectedDay.routePlan.route.name}
                  </p>
                </>
              )}
          </div>
        )}
      </div>
    </Content>
  );
};

export default RoutesPlanningPage;
