import { initStore } from '../hooks/use-store';

type ArchiveStatus = 'distributor' | 'outlet' | 'all';

const configureLeadArchiveStatusStore = () => {
  const actions = {
    SET_ARCHIVE_STATUS_LEAD: (curState, archiveStatusLead: ArchiveStatus) => {
      return { ...curState, archiveStatusLead };
    },
  };
  initStore(actions, { archiveStatusLead: 'all' });
};

export default configureLeadArchiveStatusStore;
