import { useEffect, useState } from 'react';
import Loader from '../../components/ui/Loader';
import SearchAndSort from '../../components/ui/SearchAndSort';
import Alert from '../../components/ui/Alert';
import Content from '../../components/layout/Content';
import ButtonBottomRight from '../../components/ui/ButtonBottomRight';
import TopBar from '../../components/layout/TopBar';
import List from '../../components/ui/list/List';
import useStore from '../../lib/hooks/use-store';
import { useCheckAccess } from '../../lib/auth/use-checkAccess';
import { useAuth } from '../../lib/hooks/use-auth';
import New from '../../components/ui/New';
import Site from '../../components/site/Site';
import { useSites, useSitesTypesense } from '../../lib/hooks/use-sites';
import { faSync } from '@fortawesome/free-solid-svg-icons';

const Sites = () => {
  const checkAccess = useCheckAccess();
  const { typesenseKeyUserIds } = useAuth();

  const [state, dispatch] = useStore();
  const [searchText, setSearchText] = useState(null);
  const [searchEnabled, setSearchEnabled] = useState(false);
  const [activityLevel, setActivityLevel] = useState('all');

  const { count, error, hasMore, load, loading, sites, reset } = useSites(
    state.retailerStatus,
    activityLevel
  );

  const {
    error: errorTypesense,
    hasMore: hasMoreTypesense,
    load: loadTypesense,
    loading: loadingTypesense,
    sites: sitesTypesense,
    reset: resetTypesense,
  } = useSitesTypesense({
    category: state.retailerStatus,
    searchText: searchText,
  });

  const [showAlert, setShowAlert] = useState<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (typesenseKeyUserIds) {
      setSearchEnabled(true);
    }
  });

  useEffect(() => {
    if (error) {
      setShowAlert(true);
    } else if (showAlert) {
      setShowAlert(false);
    }
    return () => {
      setShowAlert(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const onCategoryChange = (event) => {
    dispatch('SET_RETAILER_STATUS', event.target.value);
  };

  return (
    <Content topBar>
      <Alert
        title="Error"
        message={error && error.message}
        open={showAlert}
        setOpen={(open) => setShowAlert(open)}
      />
      <Alert
        title="Error"
        message={errorTypesense}
        open={showAlert}
        setOpen={(open) => setShowAlert(open)}
      />
      <ButtonBottomRight
        onClick={() => {
          reset();
          resetTypesense();
        }}
        blocked={loading}
        arialabel="reload"
        icon={faSync}
      />
      <TopBar>
        <SearchAndSort
          onCategoryChange={onCategoryChange}
          selectedCategory={state.retailerStatus}
          categories={{
            all: 'All',
            actionRequired: 'Action required',
            noWhatsApp: 'No WhatsApp',
            enabledWhatsApp: 'WhatsApp enabled',
          }}
          displayCategory={true}
          displaySearch={true}
          searchPlaceHolder="Site name"
          className={'mt-4'}
          setSearchText={setSearchText}
          searchDisabled={!searchEnabled}
        />
      </TopBar>
      <div className="flex justify-between items-center overflow-x-auto">
      <table className="w-full border border-gray-300 overflow-x-auto">
        <thead>
          <tr>
            <th className="text-center px-2">Total</th>
            <th className="text-center px-2">Active</th>
            <th className="text-center px-2">Inactive</th>
            <th className="text-center px-">Foundation</th>
            <th className="text-center px-2">Casting</th>
            <th className="text-center px-2">Plastering</th>
            <th className="text-center px-2">Block Setting</th>
            <th className="text-center px-2">Tiling stage</th>
          </tr>
          <tr>
            <td className="text-center px-2" onClick={() => setActivityLevel(null)}>{ count.totalSites }</td>
            <td className="text-center px-2" onClick={() => setActivityLevel('isActive')}>{ count.totalActive }</td>
            <td className="text-center px-2" onClick={() => setActivityLevel('isInactive')}>{ count.totalInactive }</td>
            <td className="text-center px-2" onClick={() => setActivityLevel('foundation')}>{ count.foundationLevel }</td>
            <td className="text-center px-2" onClick={() => setActivityLevel('casting')}>{ count.castingLevel }</td>
            <td className="text-center px-2" onClick={() => setActivityLevel('plastering')}>{ count.plasteringLevel }</td>
            <td className="text-center px-2" onClick={() => setActivityLevel('block_setting')}>{ count.blockSettingLevel }</td>
            <td className="text-center px-2" onClick={() => setActivityLevel('tiling_stage')}>{ count.tilingStage }</td>
          </tr>
        </thead>
      </table>
      </div>
      {(!searchText || (searchText && searchText.length < 3)) && (
        <>
          <Loader show={loading} />
          <List load={load} hasMore={hasMore} loading={loading}>
            {sites && sites.map((site) => <Site site={site} key={site.id} />)}
          </List>
        </>
      )}

      {searchText && searchText.length >= 3 && (
        <>
          <Loader show={loadingTypesense} />
          <List
            load={loadTypesense}
            hasMore={hasMoreTypesense}
            loading={loadingTypesense}
          >
            {sitesTypesense &&
              sitesTypesense.map((site) => <Site site={site} key={site.id} />)}
          </List>
        </>
      )}
      {checkAccess(['sop', 'tae', 'fsm']) && <New to="new" />}
    </Content>
  );
};

export default Sites;
