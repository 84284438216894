import { faMapMarked } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../../components/ui/Button';
import { Location, Outlet } from '../../../../../global';
import Map from '../../../components/map/Map';
import Layers from '../../../components/map/layers/Layers';
import VisitLayer from '../../../components/map/layers/VisitLayer';
import IconLayer from '../../../components/map/layers/IconLayer';

import storeImage from '../../../assets/icons/store-solid-white.svg';
import Input from '../../../components/forms/Input';
import { RegisterOptions, useForm } from 'react-hook-form';
import { updateOutlet } from '../../../lib/db/outlets';

type VisitStep0Props = {
  currentStep: number;
  captureOutletLocation: () => void;
  location: Location;
  outlet: Outlet;
  outletLocationCaptured: boolean;
  outletLocationLoading: boolean;
};

type AddAddressForm = {
  addressStreet: string;
  addressNr: string;
  addressLandmark: string;
  addressCity: string;
  addressArea: string;
};

const VisitStep0 = ({
  captureOutletLocation,
  currentStep,
  location,
  outlet,
  outletLocationCaptured,
  outletLocationLoading,
}: VisitStep0Props) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<AddAddressForm>();

  const addressStreetOptions: RegisterOptions = {
    required: 'Please enter a street name!',
    minLength: {
      value: 3,
      message: 'Please enter a street name with at least 3 characters!',
    },
    maxLength: {
      value: 50,
      message: 'The street name cannot exceed 50 characters!',
    },
  };

  const addressNumberOptions: RegisterOptions = {
    required: 'Please enter a street number!',
    minLength: {
      value: 1,
      message: 'Please enter a street name with at least 1 characters!',
    },
    maxLength: {
      value: 50,
      message: 'The street name cannot exceed 10 characters!',
    },
  };

  const addressLandmarkOptions: RegisterOptions = {
    required: 'Please enter a landmark name!',
    minLength: {
      value: 3,
      message: 'Please enter a landmark name with at least 3 characters!',
    },
    maxLength: {
      value: 50,
      message: 'The landmark name cannot exceed 50 characters!',
    },
  };

  const addressAreaOptions: RegisterOptions = {
    required: 'Please enter an area / neighborhood name!',
    minLength: {
      value: 3,
      message:
        'Please enter an area / neighborhood name with at least 3 characters!',
    },
    maxLength: {
      value: 50,
      message: 'The area / neighborhood name cannot exceed 50 characters!',
    },
  };

  const addressCityOptions: RegisterOptions = {
    required: 'Please enter a city name!',
    minLength: {
      value: 3,
      message: 'Please enter a city name with at least 3 characters!',
    },
    maxLength: {
      value: 50,
      message: 'The city name cannot exceed 50 characters!',
    },
  };

  const submitHandler = (data) => {
    const address = {
      street: data.addressStreet,
      nr: data.addressNr,
      landmark: data.addressLandmark,
      city: data.addressCity,
      area: data.addressArea,
    };

    updateOutlet(outlet.id, { address });
  };

  if (currentStep !== 0) return null;

  return (
    outlet && (
      <div className="grid grid-cols-9 col-span-9 w-full ">
        <div className="grid grid-cols-9 col-span-9 content-center ">
          <hr className={'col-span-9'} />
          <p className={'col-span-4 pl-4 text-left mt-4 mb-2 font-semibold'}>
            Order Status
          </p>
          <p className={'col-span-2 pl-4 text-center mt-4 mb-2 font-semibold'}>
            Count
          </p>
          <p className={'col-span-3 pl-4 text-center mt-4 mb-2 font-semibold'}>
            Volume
          </p>
          <div className={'grid grid-cols-9 col-span-9'}>
            <p className={'col-span-4 pl-4 text-left mb-2'}>Orders pending:</p>
            <p className={'col-span-2 pl-4 font-bold text-center mb-2'}>
              {outlet &&
                outlet.ordersAmountAppPending +
                  outlet.ordersAmountChatbotPending}
            </p>
            <p className={'col-span-3 pl-4 font-bold text-center mb-2'}>
              {outlet && outlet.pendingVolume ? outlet.pendingVolume : 0}
            </p>
            <p className={'col-span-4 pl-4 text-left mb-2'}>Orders accepted:</p>
            <p className={'col-span-2 pl-4 font-bold text-center mb-2'}>
              {outlet &&
                outlet.ordersAmountAppAccepted +
                  outlet.ordersAmountChatbotAccepted}
            </p>
            <p className={'col-span-3 pl-4 font-bold text-center mb-2'}>
              {outlet && outlet.acceptedVolume ? outlet.acceptedVolume : 0}
            </p>
            <p className={'col-span-4 pl-4 text-left mb-2'}>
              Orders fulfilled:
            </p>
            <p className={'col-span-2 pl-4 font-bold text-center mb-2'}>
              {outlet &&
                outlet.ordersAmountAppFulfilled +
                  outlet.ordersAmountChatbotFulfilled}
            </p>
            <p className={'col-span-3 pl-4 font-bold text-center mb-2'}>
              {outlet && outlet.fulfilledVolume ? outlet.fulfilledVolume : 0}
            </p>
          </div>
          <hr className={'col-span-9'} />
          <p className={'col-span-9 pl-4 text-left mt-4 mb-2 font-semibold'}>
            Chatbot enabled
          </p>
          <p className="col-span-9 pl-4 text-left mb-2">
            Status:{' '}
            {outlet && !outlet.retailer.phoneCorrect
              ? 'Phone number wrong'
              : outlet && !outlet.retailer.hasWhatsApp
              ? 'Has no WhatsApp'
              : outlet && !outlet.retailer.hasInteracted
              ? 'Not interacted yet'
              : outlet && !outlet.retailer.chatbotTOSAccepted
              ? 'TOS not accepted'
              : 'Chatbot enabled'}
          </p>
          <p className={'col-span-9 pl-4 text-left mt-2 mb-2 font-semibold'}>
            Location
          </p>
          {outlet?.location && (
            <p className="col-span-9 pl-4 text-left mb-2">
              <FontAwesomeIcon icon={faMapMarked} />{' '}
              <a
                href={`geo:${outlet?.location?.lat},${
                  outlet?.location?.lng
                }?q=${outlet?.location?.lat},${outlet?.location?.lng}(${
                  outlet && outlet.name
                })`}
              >
                Open in Google Maps
              </a>
            </p>
          )}
          {outlet?.location && (
            <div className="col-span-full h-56 flex mt-4">
              <Map center={outlet.location} zoom={16} className="w-full h-56">
                <Layers>
                  {location ? (
                    <VisitLayer
                      visitLocation={outlet.location}
                      userLocation={location}
                      icon={storeImage}
                      name={'Outlet'}
                    />
                  ) : (
                    <IconLayer
                      location={outlet.location}
                      icon={storeImage}
                      name={'Outlet'}
                    />
                  )}
                </Layers>
              </Map>
            </div>
          )}
          {!outlet?.address && (
            <>
              <p className={'col-span-9 text-left mt-2 mb-2 font-semibold'}>
                To check in, please add the address of the outlet. This can only
                be done once, so please make sure the address is correct.
              </p>
              <form
                name="add-address-outlet-form"
                onSubmit={(event) => event.preventDefault()}
                className="col-span-full flex flex-col"
              >
                <label htmlFor="addressStreet">Street name</label>
                <Input
                  register={register}
                  name="addressStreet"
                  placeholder="Street..."
                  error={errors.addressStreet}
                  options={addressStreetOptions}
                />
                <label htmlFor="addressNr">Building number</label>
                <Input
                  register={register}
                  name="addressNr"
                  placeholder="Nr..."
                  error={errors.addressNr}
                  options={addressNumberOptions}
                />
                <label htmlFor="addressLandmark">Closest landmark</label>
                <Input
                  register={register}
                  name="addressLandmark"
                  placeholder="Landmark..."
                  error={errors.addressLandmark}
                  options={addressLandmarkOptions}
                />
                <label htmlFor="addressArea">Area / Neighborhood</label>
                <Input
                  register={register}
                  name="addressArea"
                  placeholder="Area..."
                  error={errors.addressArea}
                  options={addressAreaOptions}
                />
                <label htmlFor="addressCity">City/Town</label>
                <Input
                  register={register}
                  name="addressCity"
                  placeholder="City/Town..."
                  error={errors.addressCity}
                  options={addressCityOptions}
                />
                <Button
                  onClick={handleSubmit(submitHandler)}
                  text="Add address"
                  color="green"
                  className="mt-4 "
                  buttonDisabled={outletLocationLoading}
                />
              </form>
            </>
          )}
          {!outlet?.location && location && (
            <div className="col-span-full flex flex-col">
              <Button
                onClick={captureOutletLocation}
                text="Capture Outlet Location"
                color="green"
                className="mt-4 "
                buttonDisabled={outletLocationLoading}
              />
            </div>
          )}
          {outletLocationCaptured && (
            <p className="col-span-full text-center text-green-500 font-thin text-sm">
              The location has ben captured!
            </p>
          )}
        </div>
      </div>
    )
  );
};

export default VisitStep0;
