import { faArrowLeft, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactDOM from 'react-dom';

type LoaderProps = {
  show: boolean;
  transparent?: boolean;
  text?: string;
  onStop?: () => void;
};

const Loader = ({ show, transparent = true, text, onStop }: LoaderProps) => {
  let bg: string;
  if (transparent) {
    bg = 'bg-gray-500 bg-opacity-75 transition-opacity';
  } else {
    bg = 'bg-white';
  }

  return show
    ? ReactDOM.createPortal(
        <div
          className="fixed z-50 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className={`fixed inset-0 ${bg}`} aria-hidden="true"></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <FontAwesomeIcon
              icon={faSpinner}
              className="animate-spin h-5 w-5"
            ></FontAwesomeIcon>
            {text && (
              <div className="fixed z-[60]  inset-0 top-1/2 mt-4 overflow-y-auto font-bold flex flex-col">
                <p>{text}</p>
                {onStop && (
                  <button onClick={onStop} className="mt-5">
                    <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
                  </button>
                )}
              </div>
            )}
          </div>
        </div>,
        document.body
      )
    : null;
};

export default Loader;
