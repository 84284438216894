import { initStore } from '../hooks/use-store';

const configureSelectedClusterStore = () => {
  const actions = {
    SET_SELECTED_CLUSTER: (curSelectedCluster, selectedCluster: string) => {
      return { ...curSelectedCluster, selectedCluster };
    },
  };
  initStore(actions, { selectedCluster: '' });
};

export default configureSelectedClusterStore;
