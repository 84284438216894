import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DevTool } from '@hookform/devtools';
import { Timestamp } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { RegisterOptions, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { Option } from '../../../../global';
import { Confirm } from '../../components/forms/Confirm';
import Input from '../../components/forms/Input';
import SearchableSelect from '../../components/forms/SearchableSelect';
import Content from '../../components/layout/Content';
import Alert from '../../components/ui/Alert';
import Back from '../../components/ui/Back';
import Button from '../../components/ui/Button';
import Card from '../../components/ui/Card';
import Loader from '../../components/ui/Loader';
import { useCheckAccess } from '../../lib/auth/use-checkAccess';
import { useAssociates } from '../../lib/hooks/use-associates';
import { useOrder } from '../../lib/hooks/use-orders';
import { useOutlets } from '../../lib/hooks/use-outlets';
import { useUser } from '../../lib/hooks/use-user';

type NewOrderFormValues = {
  classic: number;
  supaset: number;
  supafixc0: number;
  supafixc1: number;
  watershield: number;
  pop: number;
  //tempProduct2 tempProduct2: number;
  //tempProduct3 tempProduct3: number;
  //tempProduct4 tempProduct4: number;
  address: string;
  distributor: Option;
  outlet: Option;
};
// TODO: Update loader with all values and add order error alert, remove unused variables
const NewOrder = () => {
  const navigate = useNavigate();
  const checkAccess = useCheckAccess();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { user } = useUser();
  const { error: distributorError, associates: distributors } = useAssociates({
    roles: 'distributor',
    pageSize: 0,
  });
  const { error: outletsError, outlets } = useOutlets({
    pageSize: 0,
    active: true,
  });
  const { create, loading } = useOrder();
  const {
    register,
    handleSubmit,
    formState: { isSubmitSuccessful, errors, isValid },
    getValues,
    control,
  } = useForm({ mode: 'all', reValidateMode: 'onChange' });
  const [showOutletsAlert, setShowOutletsAlert] = useState(false);
  const [showDistributorAlert, setShowDistributorAlert] = useState(false);

  const moreThanZero = (): boolean => {
    const classic = getValues('classic');
    const supafixc0 = getValues('supafixc0');
    const supafixc1 = getValues('supafixc1');
    const supaset = getValues('supaset');
    const watershield = getValues('watershield');
    const pop = getValues('pop');
    //tempProduct2 const tempProduct2 = getValues('tempProduct2');
    //tempProduct3 const tempProduct3 = getValues('tempProduct3');
    //tempProduct4 const tempProduct4 = getValues('tempProduct4');
    return (
      (isNaN(classic) ? 0 : classic) +
        (isNaN(supafixc0) ? 0 : supafixc0) +
        (isNaN(supafixc1) ? 0 : supafixc1) +
        (isNaN(supaset) ? 0 : supaset) +
        (isNaN(watershield) ? 0 : watershield) +
      (isNaN(pop) ? 0 : pop) >
      //tempProduct2 (isNaN(tempProduct2) ? 0 : tempProduct2) +
      //tempProduct3 (isNaN(tempProduct3) ? 0 : tempProduct3) +
      //tempProduct4 (isNaN(tempProduct4) ? 0 : tempProduct4)
      0
    );
  };

  const productOptions: RegisterOptions = {
    valueAsNumber: true,
    min: {
      value: 0,
      message: 'Product cannot be negative!',
    },
    max: {
      value: 10000,
      message: "You can only order 10'000 bags at a time!",
    },
    validate: {
      moreThanZero,
    },
  };
  const addressOptions: RegisterOptions = {
    required: 'Please enter an address...',
    maxLength: {
      value: 100,
      message: 'Address cannot be more than 100 characters!',
    },
  };

  useEffect(() => {
    if (outletsError) {
      setShowOutletsAlert(true);
    } else if (showOutletsAlert) {
      setShowOutletsAlert(false);
    }
    return () => {
      setShowOutletsAlert(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outletsError]);

  useEffect(() => {
    if (distributorError) {
      toast.error(distributorError.message, {
        closeOnClick: true,
      });
    }
  }, [distributorError]);

  useEffect(() => {
    if (isSubmitSuccessful && !loading) {
      navigate('/orders', { replace: true });
    }
  }, [isSubmitSuccessful, loading, navigate]);

  const handleOnConfirm = () => {
    setShowConfirmation(false);
    handleSubmit(onSubmit)();
  };

  const handleOnCancel = () => {
    setShowConfirmation(false);
  };

  const onSubmit: SubmitHandler<NewOrderFormValues> = (data) => {
    const distributor = user.roles.includes('distributor')
      ? { id: user.id, name: user.name, phone: user.phone }
      : distributors.find((d) => d.id === data.distributor.value);
    const outlet = outlets.find((d) => d.id === data.outlet.value);
    const fsa = user.roles.includes('sop')
      ? outlet.primaryFSA //fsas.find((f) => f.id === data.fsaId)
      : user;
    const sop = user.roles.includes('sop') ? user : user.sop;
    const isDistributorOrder = checkAccess(['distributor']);
    create({
      address: data.address,
      cluster: outlet.cluster,
      createdAt: Timestamp.now(),
      createdBy: {
        id: user.id,
        name: user.name,
        phone: user.phone,
        roles: user.roles,
      },
      createdOn: 'app',
      distributor: {
        id: distributor.id,
        name: distributor.name,
        phone: distributor.phone,
      },
      fsa: {
        id: fsa.id,
        name: fsa.name,
        phone: fsa.phone,
      },
      outlet: {
        id: outlet.id,
        name: outlet.name,
      },
      products: {
        classic: isNaN(data.classic) ? 0 : Math.trunc(data.classic),
        supaset: isNaN(data.supaset) ? 0 : Math.trunc(data.supaset),
        supafixc0: isNaN(data.supafixc0) ? 0 : Math.trunc(data.supafixc0),
        supafixc1: isNaN(data.supafixc1) ? 0 : Math.trunc(data.supafixc1),
        watershield: isNaN(data.watershield) ? 0 : Math.trunc(data.watershield),
        pop: isNaN(data.pop) ? 0 : Math.trunc(data.pop),
        //tempProduct2 tempProduct2: isNaN(data.tempProduct2) ? 0 : Math.trunc(data.tempProduct2),
        //tempProduct3 tempProduct3: isNaN(data.tempProduct3) ? 0 : Math.trunc(data.tempProduct3),
        //tempProduct4 tempProduct4: isNaN(data.tempProduct4) ? 0 : Math.trunc(data.tempProduct4),
      },
      retailer: {
        chatbotTOSAccepted: outlet.retailer.chatbotTOSAccepted,
        hasInteracted: outlet.retailer.hasInteracted,
        hasWhatsApp: outlet.retailer.hasWhatsApp,
        id: outlet.retailer.id,
        name: outlet.retailer.name,
        phone: outlet.retailer.phone,
      },
      sop: {
        email: sop.email,
        id: sop.id,
        name: sop.name,
        phone: sop.phone,
      },
      status: 'pending',
      statusHistory: {
        pending: Timestamp.now(),
      },
      isDistributorOrder: isDistributorOrder,
      userIds: [distributor.id, fsa.id, outlet.retailer.id, sop.id],
      testIds: [],
    });
  };

  return (
    <>
      <Alert
        message={outletsError && outletsError.message}
        open={showOutletsAlert}
        setOpen={(open) => setShowOutletsAlert(open)}
        title="Error"
      />
      <Alert
        message={distributorError && distributorError.message}
        open={showDistributorAlert}
        setOpen={(open) => setShowDistributorAlert(open)}
        title="Error"
      />
      <Loader show={loading} />
      <Confirm
        title="Are you sure you want to create this new order?"
        open={showConfirmation}
        onConfirm={handleOnConfirm}
        onCancel={handleOnCancel}
        setOpen={(open) => {
          setShowConfirmation(open);
        }}
      />
      <Content>
        <Card className="grid grid-cols-12">
          <Back to="/orders" className="col-span-4" />
          <div className="col-span-4 flex justify-center items-center text-center text-xl font-bold text-lh-head-black">
            <FontAwesomeIcon
              icon={faPlusCircle}
              className="mr-2 text-lh-head-black"
            />
            New order
          </div>
          <form
            name="newOrderForm"
            onSubmit={handleSubmit(onSubmit)}
            className="grid grid-cols-12 col-span-full mt-2"
          >
            {/*
            {fsas.length > 0 && (
              <>
                <label htmlFor="fsaId">FSA</label>
                <Select
                  name="fsaId"
                  register={register}
                  options={fsaOptions}
                  error={errors.fsaId}
                  placeholder="Select FSA..."
                  items={
                    fsas
                      ? fsas.map((fsa) => ({
                          value: fsa.id,
                          key: fsa.id,
                          label: fsa.name,
                        }))
                      : []
                  }
                  className="col-span-12 text-lh-text-black"
                />
              </>
            )}
                
            <label
              htmlFor="fsaId"
              className="font-bold col-span-12 mb-2 text-lh-head-black"
            >
              FSA
            </label>
            <SearchableSelect
              value={fsa}
              updateValue={setFSA}
              buttonName="Select FSA..."
              options={fsaOptions}
              error={errors.fsaId}
              isOpen={dropdownOpen}
              toggleOpen={setDropdownOpen}
              items={
                fsas
                  ? fsas.map((fsa) => ({
                      value: fsa.id,
                      key: fsa.id,
                      label: fsa.name,
                    }))
                  : []
              }
              className="col-span-12 text-lh-text-black"
            />
            */}
            <SearchableSelect
              control={control}
              name="outlet"
              rules={{ required: true }}
              label="Outlet"
              placeholder="Select Outlet..."
              values={
                outlets
                  ? outlets.map((outlet) => ({
                      label: outlet.name,
                      value: outlet.id,
                    }))
                  : []
              }
              className="col-span-12 text-lh-text-black"
            />
            {checkAccess(['fsa', 'sop']) && (
              <SearchableSelect
                control={control}
                name="distributor"
                rules={{ required: true }}
                label="Distributor"
                placeholder="Select Distributor..."
                values={
                  distributors
                    ? distributors.map((distributor) => ({
                        label: distributor.name,
                        value: distributor.id,
                      }))
                    : []
                }
                className="col-span-12 text-lh-text-black"
              />
            )}
            <h2 className="font-bold col-span-12 mt-2 mb-2 text-lh-head-black">
              Products
            </h2>
            <label
              htmlFor="classic"
              className="col-span-4 pt-1 text-lh-text-black"
            >
              Classic
            </label>
            <Input
              name="classic"
              register={register}
              options={productOptions}
              error={errors.classic}
              type="number"
              placeholder="0"
              className="col-span-4 text-right col-start-8 text-lh-text-black"
            />
            <label
              htmlFor="classic"
              className="col-span-1 ml-2 pt-1 text-lh-text-black"
            >
              bags
            </label>
            <label
              htmlFor="supaset"
              className="col-span-4 pt-1 text-lh-text-black"
            >
              Supaset
            </label>
            <Input
              name="supaset"
              register={register}
              options={productOptions}
              type="number"
              placeholder="0"
              className="col-span-4 text-right col-start-8 text-lh-text-black"
            />
            <label
              htmlFor="supaset"
              className="col-span-1 ml-2 pt-1 text-lh-text-black"
            >
              bags
            </label>
            <label
              htmlFor="supafixc0"
              className="col-span-4 pt-1 text-lh-text-black"
            >
              SupafixC0
            </label>
            <Input
              name="supafixc0"
              register={register}
              options={productOptions}
              type="number"
              placeholder="0"
              className="col-span-4 text-right col-start-8 text-lh-text-black"
            />
            <label
              htmlFor="supafixc0"
              className="col-span-1 ml-2 pt-1 text-lh-text-black"
            >
              bags
            </label>
            <label
              htmlFor="supafixc1"
              className="col-span-4 pt-1 text-lh-text-black"
            >
              SupafixC1
            </label>
            <Input
              name="supafixc1"
              register={register}
              options={productOptions}
              type="number"
              placeholder="0"
              className="col-span-4 text-right col-start-8 text-lh-text-black"
            />
            <label
              htmlFor="supafixc1"
              className="col-span-1 ml-2 pt-1 text-lh-text-black"
            >
              bags
            </label>
            <label
              htmlFor="watershield"
              className="col-span-4 pt-1 text-lh-text-black"
            >
              Watershield
            </label>
            <Input
              name="watershield"
              register={register}
              options={productOptions}
              type="number"
              placeholder="0"
              className="col-span-4 text-right col-start-8 text-lh-text-black"
            />
            <label
              htmlFor="watershield"
              className="col-span-1 ml-2 pt-1 text-lh-text-black"
            >
              bags
            </label>
            
            <label
              htmlFor="pop"
              className="col-span-4 pt-1 text-lh-text-black"
            >
              POP
            </label>
            <Input
              name="pop"
              register={register}
              options={productOptions}
              type="number"
              placeholder="0"
              className="col-span-4 text-right col-start-8 text-lh-text-black"
            />
            <label
              htmlFor="pop"
              className="col-span-1 ml-2 pt-1 text-lh-text-black"
            >
                bags
            </label>
             
            {/* TempProduct2
            <label
              htmlFor="tempProduct2"
              className="col-span-4 pt-1 text-lh-text-black"
            >
             TempProduct2
            </label>
            <Input
              name="tempProduct2"
              register={register}
              options={productOptions}
              type="number"
              placeholder="0"
              className="col-span-4 text-right col-start-8 text-lh-text-black"
            />
            <label
              htmlFor="tempProduct2"
              className="col-span-1 ml-2 pt-1 text-lh-text-black"
            >
              bags
            </label>
              TempProduct2 */}
            {/* TempProduct3
            <label
              htmlFor="tempProduct3"
              className="col-span-4 pt-1 text-lh-text-black"
            >
              TempProduct3
            </label>
            <Input
              name="tempProduct3"
              register={register}
              options={productOptions}
              type="number"
              placeholder="0"
              className="col-span-4 text-right col-start-8 text-lh-text-black"
            />
            <label
              htmlFor="tempProduct3"
              className="col-span-1 ml-2 pt-1 text-lh-text-black"
            >
              bags
            </label>
              TempProduct3 */}
            {/* TempProduct4
            <label
              htmlFor="tempProduct4"
              className="col-span-4 pt-1 text-lh-text-black"
            >
              TempProduct4
            </label>
            <Input
              name="tempProduct4"
              register={register}
              options={productOptions}
              type="number"
              placeholder="0"
              className="col-span-4 text-right col-start-8 text-lh-text-black"
            />
            <label
              htmlFor="tempProduct4"
              className="col-span-1 ml-2 pt-1 text-lh-text-black"
            >
              bags
            </label>
              TempProduct4 */}
            <label
              htmlFor="address"
              className="col-span-full font-bold text-lh-head-black"
            >
              Delivery Address
            </label>
            <Input
              name="address"
              register={register}
              options={addressOptions}
              error={errors.address}
              type="text"
              className="col-span-full text-lh-text-black"
            />
          </form>
          <Button
            buttonDisabled={!isValid}
            text="Place order"
            color="blue"
            className="col-span-full"
            onClick={() => {
              setShowConfirmation(true);
            }}
          />
          <DevTool control={control} />
        </Card>
      </Content>
    </>
  );
};

export default NewOrder;
