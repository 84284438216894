import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faSync,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { PageDirection } from '../../../../global';
import Delivery from '../../components/deliveries/Delivery';
import Content from '../../components/layout/Content';
import TopBar from '../../components/layout/TopBar';
import Alert from '../../components/ui/Alert';
import List from '../../components/ui/list/List';
import Loader from '../../components/ui/Loader';
import ButtonBottomRight from '../../components/ui/ButtonBottomRight';
import SearchAndSort from '../../components/ui/SearchAndSort';
import { useAuth } from '../../lib/hooks/use-auth';
import {
  useDeliveries,
  useDeliveriesTypesense,
} from '../../lib/hooks/use-deliveries';
import useStore from '../../lib/hooks/use-store';

const Deliveries = React.memo(() => {
  const { typesenseKeyUserIds } = useAuth();
  const [direction, setDirection] = useState<PageDirection>('desc');
  const [searchText, setSearchText] = useState(null);
  const [searchEnabled, setSearchEnabled] = useState(false);
  const [state, dispatch] = useStore();
  const { error, deliveries, loading, load, hasMore, reset } = useDeliveries({
    status: state.statusDeliveries,
    direction,
  });
  const {
    hasMore: hasMoreTypesense,
    load: loadTypesense,
    loading: loadingTypesense,
    deliveries: deliveriesTypesense,
  } = useDeliveriesTypesense({
    status: state.statusDeliveries,
    direction,
    searchText: searchText,
  });

  const [showAlert, setShowAlert] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (typesenseKeyUserIds) {
      setSearchEnabled(true);
    }
  });

  const handleStatusChange = (newStatus) => () => {
    if (newStatus === 'archived') {
      dispatch('SET_STATUS_DELIVERIES', ['confirmed', 'rejected']);
    } else {
      dispatch('SET_STATUS_DELIVERIES', ['open', 'claimed']);
    }
  };

  const handleDirectionChange = () => {
    if (direction === 'asc') {
      setDirection('desc');
    } else {
      setDirection('asc');
    }
  };

  useEffect(() => {
    if (error) {
      setShowAlert(true);
    }

    return () => {
      setShowAlert(false);
    };
  }, [error]);

  return (
    <Content topBar>
      <button
        onClick={handleDirectionChange}
        className="rounded-full flex justify-center items-center h-8 w-8 fixed top-5 right-20 text-xl z-30"
      >
        <FontAwesomeIcon
          icon={direction === 'asc' ? faAngleDoubleUp : faAngleDoubleDown}
        />
      </button>
      <ButtonBottomRight
        onClick={reset}
        blocked={loading}
        arialabel="reload"
        icon={faSync}
      />
      <Loader show={loading && deliveries.length === 0} />
      <Alert
        message={error && error.message}
        open={showAlert}
        setOpen={(open) => setShowAlert(open)}
        title="Error"
      />
      <TopBar>
        <div
          className={clsx(
            'flex justify-center content-center flex-col text-center flex-grow cursor-pointer text-lh-dark-blue text-xl font-medium border-b-2 border-lh-dark-blue h-full',
            state.statusDeliveries.includes('open') &&
              'border-b-4 border-lh-dark-blue font-semibold'
          )}
          onClick={handleStatusChange('open')}
        >
          Open
        </div>
        <div
          className={clsx(
            'flex justify-center content-center flex-col text-center flex-grow cursor-pointer text-lh-dark-blue text-xl font-medium border-b-2 border-lh-dark-blue h-full',
            state.statusDeliveries.includes('confirmed') &&
              'border-b-4 border-lh-dark-blue font-semibold'
          )}
          onClick={handleStatusChange('archived')}
        >
          Archive
        </div>
      </TopBar>
      <SearchAndSort
        onCategoryChange={() => {}}
        selectedCategory={state.retailerStatus}
        categories={{}}
        displayCategory={false}
        displaySearch={true}
        searchPlaceHolder="LOGONOrderNumber, Distributor, Product Name"
        className={'mt-4'}
        setSearchText={setSearchText}
        searchDisabled={!searchEnabled}
      />
      {(!searchText || (searchText && searchText.length < 3)) && (
        <List load={load} hasMore={hasMore} loading={loading}>
          {deliveries && deliveries.length > 0 ? (
            deliveries.map((delivery) => (
              <Delivery delivery={delivery} key={delivery.id} />
            ))
          ) : (
            <p className="justify-between mx-2 mb-2 h-18 list-none shadow-md rounded-lg pb-4 px-2 pt-2 bg-white text-center">
              No {state.status.join(' / ')} deliveries.
            </p>
          )}
        </List>
      )}
      {searchText && searchText.length >= 3 && (
        <>
          <Loader show={loadingTypesense} />
          <List
            load={loadTypesense}
            hasMore={hasMoreTypesense}
            loading={loadingTypesense}
          >
            {deliveriesTypesense && deliveriesTypesense.length > 0 ? (
              deliveriesTypesense.map((delivery) => (
                <Delivery delivery={delivery} key={delivery.id} />
              ))
            ) : (
              <p className="justify-between mx-2 mb-2 h-18 list-none shadow-md rounded-lg pb-4 px-2 pt-2 bg-white text-center">
                No {state.status.join(' / ')} deliveries.
              </p>
            )}
          </List>
        </>
      )}
    </Content>
  );
});

export default Deliveries;
