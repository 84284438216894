import {
  faAngleLeft,
  faAngleRight,
  faSync,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import Inventory from '../../components/inventories/Inventory';
import Content from '../../components/layout/Content';
import TopBar from '../../components/layout/TopBar';
import Alert from '../../components/ui/Alert';
import List from '../../components/ui/list/List';
import Loader from '../../components/ui/Loader';
import ButtonBottomRight from '../../components/ui/ButtonBottomRight';
import { useInventories } from '../../lib/hooks/use-inventories';
import useStore from '../../lib/hooks/use-store';

const Inventories = () => {
  const [state, dispatch] = useStore();
  const [showInventoriesAlert, setShowInventoriesAlert] =
    useState<boolean>(false);
  const { error, inventories, loading, reset } = useInventories({
    day: state.dateInventory.date(),
    month: state.dateInventory.month() + 1,
    year: state.dateInventory.year(),
    pageSize: 0,
  });

  const isBeforeAugust = useMemo(
    () => state.dateInventory.isBefore(dayjs('2022-08-02'), 'day'),
    [state]
  );

  const isSameDay = useMemo(
    () => state.dateInventory.isSame(dayjs(), 'day'),
    [state]
  );

  const onDayChange = (direction) => {
    if (direction === 'add') {
      if (isSameDay) return;
      dispatch('ADD_DAY_INVENTORY', {});
    } else {
      if (isBeforeAugust) return;
      dispatch('SUBTRACT_DAY_INVENTORY', {});
    }
  };

  return (
    <Content topBar>
      <Alert
        title="Error"
        message={error && error.message}
        open={showInventoriesAlert}
        setOpen={(open) => setShowInventoriesAlert(open)}
      />
      <ButtonBottomRight
        onClick={reset}
        blocked={loading}
        arialabel="reload"
        icon={faSync}
      />
      <Loader show={loading} />
      <TopBar>
        <div className="w-full grid grid-cols-12">
          <div className="w-full grid col-span-12 grid-cols-12 justify-center mt-4">
            <button
              className={
                'font-semibold md:col-span-1 md:col-start-5 col-span-1 col-start-4 text-center cursor-pointer ' +
                (isSameDay ? 'hidden' : 'block')
              }
              onClick={() => onDayChange('substract')}
            >
              <FontAwesomeIcon
                icon={faAngleLeft}
                className={'font-semibold '}
              />
            </button>

            <p
              className={
                'font-semibold align-middle text-center md:col-span-2 md:col-start-6 col-span-4 col-start-5 pt-4'
              }
            >
              {state.dateInventory.format('DD.MM.YYYY') ===
              dayjs().format('DD.MM.YYYY')
                ? 'Today'
                : state.dateInventory.format('DD.MM.YYYY')}
            </p>
            <button
              className={
                'font-semibold md:col-span-1 md:col-start-8 col-span-1 col-start-9 text-center cursor-pointer ' +
                (isSameDay ? 'hidden' : 'block')
              }
              onClick={() => onDayChange('add')}
            >
              <FontAwesomeIcon
                icon={faAngleRight}
                className={'font-semibold'}
              />
            </button>
          </div>
        </div>
      </TopBar>
      <List className={'mt-0 pt-0'}>
        {inventories.length > 0 &&
          inventories.map((i) => <Inventory inventory={i} key={i.id} />)}
      </List>
    </Content>
  );
};

export default Inventories;
