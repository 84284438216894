import { useParams } from 'react-router-dom';
import Alert from '../../../components/ui/Alert';
import Card from '../../../components/ui/Card';
import Back from '../../../components/ui/Back';
import Loader from '../../../components/ui/Loader';
import Content from '../../../components/layout/Content';
import { useEffect, useMemo, useState } from 'react';
import { useInventory } from '../../../lib/hooks/use-inventories';
import dayjs from 'dayjs';
import Products from '../../../components/inventories/Products';
import isToday from 'dayjs/plugin/isToday';
import InitialInventoryModal, {
  InitialInventoryModalForm,
} from '../../../components/inventories/InitialInventoryModal';
import Button from '../../../components/ui/Button';

dayjs.extend(isToday);

type TargetDetailsParams = 'id';

const Inventory = () => {
  const { id } = useParams<TargetDetailsParams>();
  const { inventory, error, loading, setInitial } = useInventory(id);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [hasSetInitial, setHasSetInitial] = useState<boolean>(false);

  const date = useMemo(() => {
    if (inventory)
      return dayjs(`${inventory.year}-${inventory.month}-${inventory.day}`);
    return undefined;
  }, [inventory]);

  const setInitialInventory = (data: InitialInventoryModalForm) => {
    setInitial(data);
    setHasSetInitial(true);
    setOpen(false);
  };

  useEffect(() => {
    if (inventory && !hasSetInitial) {
      setHasSetInitial(inventory.initialSet);
    }
  }, [inventory, hasSetInitial]);

  useEffect(() => {
    if (error) {
      setShowAlert(true);
    }
  }, [error]);

  return (
    <Content>
      <Alert
        title="Error"
        message={error && error.message}
        open={showAlert}
        setOpen={(open) => setShowAlert(open)}
      />
      <Alert
        title="Error"
        message={error && error.message}
        open={showAlert}
        setOpen={(open) => setShowAlert(open)}
      />
      <Loader show={loading} />
      <InitialInventoryModal
        open={open}
        setOpen={setOpen}
        onSet={setInitialInventory}
      />
      <Card className="grid grid-cols-12">
        <Back to="/inventories" className="col-span-4" />
        {inventory && (
          <h2 className="col-span-4 flex justify-center items-center text-center text-xl font-bold text-lh-head-black">
            {inventory.distributor.name}
          </h2>
        )}
        {inventory && !hasSetInitial && date.isToday() && (
          <Button
            text="Set initial"
            color="green"
            className="col-span-12 mt-2 mb-6"
            onClick={() => setOpen(true)}
          />
        )}
        {inventory ? (
          <>
            {date.isToday() ? (
              <Products
                className="col-span-full"
                products={inventory.current}
                title={'Current Inventory'}
              />
            ) : (
              <Products
                className="col-span-full"
                products={inventory.eod}
                title={'End of Day Inventory'}
              />
            )}
            <Products
              className="col-span-full mt-4"
              products={inventory.incoming}
              title={'Added Inventory Today'}
            />
          </>
        ) : (
          <div className="flex justify-center">
            <p className="text-lh-text-black">No Inventory Found</p>
          </div>
        )}
      </Card>
    </Content>
  );
};

export default Inventory;
