import { useEffect, useState } from 'react';
import { RegisterOptions, SubmitHandler, useForm } from 'react-hook-form';
import Textarea from '../forms/Textarea';
import Button from '../ui/Button';
import Modal from '../ui/Modal';
import { updateCopilot } from '../../lib/db/copilot';
import { Timestamp } from 'firebase/firestore';
import Input from '../forms/Input';

type HandleActionForm = {
  orderPlaced: boolean;
  note: string;
};

type HandleActionProps = {
  open: boolean;
  onReject: SubmitHandler<HandleActionForm>;
  setOpen: (open: boolean) => void;
  actionType: string;
  id: string;
};

const HandleAction = ({ open, setOpen, actionType, id }: HandleActionProps) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    trigger,
  } = useForm<HandleActionForm>({
    mode: 'all',
  });
  const [noteOptions, setNoteOptions] = useState<RegisterOptions>({
    required: {
      value: true,
      message: 'Note is required',
    },
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (value.orderPlaced) {
        setNoteOptions({
          required: false,
        });
      } else {
        setNoteOptions({
          required: {
            value: true,
            message: 'Note is required!',
          },
        });
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (errors.note) {
      trigger('note');
    }
  }, [trigger, noteOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = (data: HandleActionForm) => {
    updateCopilot(id, {
      actionTaken: true,
      action: {
        created: Timestamp.now(),
        type: actionType,
        note: data.note,
        orderPlaced: data.orderPlaced,
      },
    });
    setOpen(false);
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <form
        name="handleActionForm"
        className="flex flex-col"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="font-semibold text-lg">{actionType}</div>
        <label htmlFor="orderPlaced" className="my-2 flex">
          <Input
            className="w-fit mx-2"
            register={register}
            type="checkbox"
            name="orderPlaced"
          />
          <p>Order was placed after follow up</p>
        </label>

        <label className="font-bold mb-2" htmlFor="note">
          Note:{' '}
        </label>
        <Textarea
          name="note"
          register={register}
          options={noteOptions}
          cols={30}
          rows={5}
          className="w-full"
          error={errors.note}
        />
        <Button text="Confirm" color="blue" className="w-full" type="submit" />
      </form>
    </Modal>
  );
};

export default HandleAction;
