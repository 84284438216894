import { useState, useEffect } from 'react';
import { SubmitHandler, useForm, RegisterOptions } from 'react-hook-form';
import { useCheckAccess } from '../../lib/auth/use-checkAccess';
import { useAssociates } from '../../lib/hooks/use-associates';
import { useRoutes } from '../../lib/hooks/use-routes';
import { Confirm } from '../forms/Confirm';
import Input from '../forms/Input';
import SearchableSelect from 'react-select';
import Select from '../forms/Select';
import Button from '../ui/Button';
import Modal from '../ui/Modal';

const days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

type ChangeRouteForm = {
  routeName: string;
  defaultDay: string;
  defaultDayNumber: number;
  fsa?: {
    id: String;
    name: String;
    phone: String;
  };
};

type ChangeRouteProps = {
  open: boolean;
  //onReject: SubmitHandler<SelectOutletForm>;
  setOpen: (boolean) => void;
  onChangeRouteName: SubmitHandler<ChangeRouteForm>;
  confirmText: string;
  buttonText: string;
  routeName: string;
  fsaId: string;
  defaultDay: string;
};

const ChangeRoute = ({
  open,
  //onReject,
  setOpen,
  onChangeRouteName,
  confirmText,
  buttonText,
  routeName,
  fsaId,
  defaultDay,
}: ChangeRouteProps) => {
  const {
    register,
    getValues,
    formState: { errors },
  } = useForm<ChangeRouteForm>({
    defaultValues: {
      routeName: routeName,
      defaultDay: defaultDay === null ? 'placeholder' : defaultDay,
    },
  });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [block, setBlock] = useState(false);
  const [fsaSelected, setFSASelected] = useState(null);
  const checkAccess = useCheckAccess();

  const { associates } = useAssociates({
    pageSize: 0,
    roles: 'fsa',
  });

  const { reset } = useRoutes({
    fsaId: fsaId ? fsaId : '',
  });

  useEffect(() => {
    if (associates) {
      const fsa = associates.find((associate) => {
        return associate.id === fsaId;
      });
      if (fsa) {
        setFSASelected({
          value: fsa,
          key: fsa.id,
          label: fsa.name,
        });
      }
    }
  }, [associates, fsaId]);

  const routeDayOptions: RegisterOptions = {
    required: 'Please select a route day...',
    maxLength: {
      value: 100,
      message: 'Day cannot be more than 100 characters!',
    },
  };

  const handleOnConfirm = () => {
    setBlock(false);
    setShowConfirmation(false);
    if (checkAccess(['sop'])) {
      onChangeRouteName({
        routeName: getValues('routeName'),
        defaultDay:
          getValues('defaultDay') === 'placeholder'
            ? null
            : getValues('defaultDay'),
        defaultDayNumber:
          getValues('defaultDay') === 'placeholder'
            ? null
            : days.findIndex((index) => index === getValues('defaultDay')),
        fsa: {
          id: fsaSelected.value.id,
          name: fsaSelected.value.name,
          phone: fsaSelected.value.phone,
        },
      });
    } else {
      onChangeRouteName({
        routeName: getValues('routeName'),
        defaultDay:
          getValues('defaultDay') === 'placeholder'
            ? null
            : getValues('defaultDay'),
        defaultDayNumber:
          getValues('defaultDay') === 'placeholder'
            ? null
            : days.findIndex((index) => index === getValues('defaultDay')),
      });
    }
    reset();
  };

  const handleOnCancel = () => {
    setBlock(false);
    setShowConfirmation(false);
  };

  return (
    <Modal open={open} setOpen={setOpen} block={block}>
      <Confirm
        title={confirmText}
        open={showConfirmation}
        onConfirm={handleOnConfirm}
        onCancel={handleOnCancel}
        setOpen={(open) => {
          setShowConfirmation(open);
          setBlock(open);
        }}
      />
      <form name="selectOutletForm" className="flex flex-col">
        <label className="font-bold mb-2">Change route name: </label>
        <Input
          register={register}
          options={{ required: true }}
          type="text"
          name="routeName"
          placeholder="Route name"
          className="col-span-6 bg-gray-200 border-2 rounded-lg mb-1 mt-1 text-lh-text-black pr-2"
        />
        {checkAccess(['sop']) && (
          <SearchableSelect
            options={
              associates
                ? associates.map((associate) => ({
                    value: associate,
                    label: associate.name,
                  }))
                : []
            }
            placeholder={'Select FSA...'}
            onChange={(fsa) => setFSASelected(fsa)}
            value={fsaSelected}
            defaultValue={fsaSelected}
            className="col-span-12 text-lh-text-black"
          />
        )}
        <label className="font-bold mb-2">Change route default day: </label>
        <Select
          name="defaultDay"
          register={register}
          options={routeDayOptions}
          error={errors.defaultDay}
          placeholder="No default day"
          items={days.map((day) => ({
            value: day,
            key: day,
            label: day,
          }))}
          className="col-span-full text-lh-text-black"
        />
      </form>
      <Button
        text={buttonText}
        color="green"
        className="w-full"
        onClick={() => {
          setBlock(true);
          setShowConfirmation(true);
        }}
      />
    </Modal>
  );
};

export default ChangeRoute;
