/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useOnClickOutside } from '../../lib/hooks/use-onclickoutside';
import Burger from '../ui/Burger';
import Menu from './Menu';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../lib/hooks/use-auth';
import Loader from '../ui/Loader';
import { useUser } from '../../lib/hooks/use-user';
import NotificationBadge from '../notifications/NotificationBadge';
import realLogo from '../../assets/REAL_Logo_transparent.png';
import { handleNotifications } from '../../lib/utils/handleNotifications';
import { getToken, onMessage } from 'firebase/messaging';
import { messaging, messagingIsSupported } from '../../lib/utils/firebase';
import { toast } from 'react-toastify';

const NAV_TEXT = [
  { path: /^\/enter.*/, title: 'Login' },
  { path: /^\/home.*/, title: 'Home' },
  { path: /^\/orders.*/, title: 'Orders' },
  { path: /^\/sales.*/, title: 'Sales' },
  { path: /^\/retailers$/, title: 'Outlets' },
  { path: /^\/retailers.*\/edit/, title: 'Edit Retailer' },
  { path: /^\/retailers.*/, title: 'Outlet' },
  { path: /^\/outlets$/, title: 'Outlets' },
  { path: /^\/outlets.*\/edit/, title: 'Edit Outlet' },
  { path: /^\/outlets.*/, title: 'Outlet' },
  { path: /^\/targets.*/, title: 'Targets' },
  { path: /^\/denied.*/, title: 'Access Denied' },
  { path: /^\/inventories.*/, title: 'Inventories' },
  { path: /^\/profile.*/, title: 'Profile' },
  { path: /^\/visits.*/, title: 'Visits' },
  { path: /^\/routes.*/, title: 'Routes' },
  { path: /^\/routes_planning.*$/, title: 'Routes planning' },
  { path: /^\/notifications.*$/, title: 'Notifications' },
  { path: /^\/deliveries.*$/, title: 'Deliveries' },
  { path: /^\/sites.*$/, title: 'Sites' },
  { path: /^\/siteVisits.*$/, title: 'Site Visits' },
  { path: /^\/leads.*$/, title: 'Leads' },
  { path: /^\/copilot.*$/, title: 'Sales Co-Pilot' },
];

const Layout = () => {
  const { user, loading, setToken } = useAuth();
  const { user: userObj, loading: userLoading } = useUser();
  const [title, setTitle] = useState('');
  const [open, setOpen] = useState(false);
  const node = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const activeTitle = NAV_TEXT.find((text) =>
      location.pathname.match(text.path)
    );
    if (activeTitle) {
      setTitle(activeTitle.title);
    }
  }, [location]);

  useOnClickOutside(node, () => setOpen(false));

  // Fix: Navigate function does not work inside handleNotifications function!
  const onMessageClick = (data) => {
    navigate(data.link);
  };

  useEffect(() => {
    if (messagingIsSupported === false) {
      toast.warning('Your browser does not support notifications');
      return;
    }
    if (!messagingIsSupported) {
      return;
    }
    Notification.requestPermission().then(() => {
      getToken(messaging)
        .then((token) => {
          if (token) {
            setToken(token);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }, [user, messaging]);

  useEffect(() => {
    if (!messaging) {
      return;
    }
    onMessage(messaging, (payload) => {
      console.log(payload);
      handleNotifications(onMessageClick, payload);
    });
  }, [messaging]);

  return (
    <>
      <Loader
        show={loading || userLoading || !!(!userObj && user)}
        transparent={false}
      />
      {user && (
        <div ref={node}>
          <Burger open={open} setOpen={setOpen} />
          <Menu open={open} setOpen={setOpen} />
        </div>
      )}
      <img
        src={realLogo}
        alt="REAL logo"
        className={'fixed top-1 left-20 h-[3.8rem] z-40 '}
      />
      <div className="bg-gradient-to-r from-gradient-1 via-gradient-2 to-gradient-3 fixed pb-2 top-0 left-0 w-full z-20 h-20">
        <div
          id="titleBar"
          className="flex items-center justify-center w-full h-full z-20 bg-white"
        >
          <h1 className="font-semibold text-2xl">{title}</h1>
        </div>
      </div>
      <NotificationBadge
        count={userObj && userObj.notifications ? userObj.notifications : 0}
      />

      <main className="flex justify-center w-full">
        <Outlet />
      </main>
    </>
  );
};

export default Layout;
