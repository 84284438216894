import { RecaptchaVerifier } from 'firebase/auth';
import { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSignInWithPhone } from '../../lib/auth/use-signInWithPhone';
import { auth } from '../../lib/utils/firebase';
import { useAuth } from '../../lib/hooks/use-auth';
import Loader from '../../components/ui/Loader';
import { toast } from 'react-toastify';
import { SendCode } from '../../components/login/SendCode';
import { VerifyCode } from '../../components/login/VerifyCode';
import { useLocalStorage } from '../../lib/hooks/useLocalStorage';
import dayjs from 'dayjs';
import { Countdown } from '../../components/ui/Countdown';
import Card from '../../components/ui/Card';

type PhoneLoginAttempts = {
  attempts: number;
  firstAttemptAt: number;
};

const PhoneSignIn = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [signInWithPhone, verifyCode, loading, error] =
    useSignInWithPhone(auth);
  const [recaptcha, setRecaptcha] = useState(null);
  const element = useRef(null);
  const [sentCode, setSentCode] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>();
  const [countdownRunning, setCountdownRunning] = useState<boolean>(false);
  const [phoneLoginAttempts, setPhoneLoginAttempts] =
    useLocalStorage<PhoneLoginAttempts>('phoneLoginAttempts', {
      attempts: 0,
      firstAttemptAt: null,
    });

  useEffect(() => {
    if (phoneLoginAttempts === null) {
      setPhoneLoginAttempts({
        attempts: 0,
        firstAttemptAt: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(error.message, { autoClose: false });
    }
    if (
      error?.code === 'auth/too-many-requests' &&
      phoneLoginAttempts?.attempts < 5
    ) {
      setPhoneLoginAttempts({
        attempts: 5,
        firstAttemptAt: phoneLoginAttempts.firstAttemptAt ?? dayjs().unix(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    auth.useDeviceLanguage();
    const verifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
      size: 'invisible',
    });
    setRecaptcha(verifier);
  }, []);

  useEffect(() => {
    if (user !== null) navigate('/', { replace: true });
  }, [user, navigate]);

  const handleSendCode = async (newPhone: string) => {
    try {
      if (newPhone === phone && countdownRunning) {
        toast.error('Please wait before sending another code.');
        return;
      }
      handleAttempts();
      setPhone(newPhone);
      await recaptcha.verify();
      await signInWithPhone(newPhone, recaptcha);
      setSentCode(true);
      setCountdownRunning(true);
      if (error && error.code === 'auth/argument-error') {
        // getRecaptcha();
        return;
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleResendCode = async () => {
    try {
      handleAttempts();
      await signInWithPhone(phone, recaptcha);
      setSentCode(true);
      setCountdownRunning(true);
      if (error && error.code === 'auth/argument-error') {
        // getRecaptcha();
        return;
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleVerifyCode = async (code: string) => {
    await verifyCode(code);
  };

  const handleBack = () => {
    setSentCode(false);
    setCountdownRunning(false);
  };

  const handleAttempts = () => {
    if (phoneLoginAttempts === null) {
      setPhoneLoginAttempts({
        attempts: 1,
        firstAttemptAt: dayjs().unix(),
      });
    }

    const { attempts, firstAttemptAt } = phoneLoginAttempts;

    const firstAttemptYoungerAsOneHour =
      firstAttemptAt + 3600 <= dayjs().unix();

    if (firstAttemptAt === null || firstAttemptYoungerAsOneHour) {
      setPhoneLoginAttempts({
        attempts: 1,
        firstAttemptAt: dayjs().unix(),
      });
    } else if (attempts >= 5) {
      const tryAgainIn = Math.floor(
        (60 * 60 - (dayjs().unix() - firstAttemptAt)) / 60
      );
      throw new Error(`Too many attempts. Try again in ${tryAgainIn} minutes`);
    } else {
      setPhoneLoginAttempts({
        attempts: attempts + 1,
        firstAttemptAt: firstAttemptAt,
      });
    }
  };

  return (
    <>
      <Loader show={loading} />
      <Card className="mt-24 w-full flex justify-center flex-col px-4">
        <div id="recaptcha-container" ref={element} />
        {!sentCode && (
          <SendCode
            handleSendCode={handleSendCode}
            phone={phone}
            disableSendButton={countdownRunning}
          />
        )}
        {sentCode && (
          <VerifyCode
            handleVerifyCode={handleVerifyCode}
            phone={phone}
            back={handleBack}
          />
        )}
        {countdownRunning && (
          <div className="flex justify-center w-full pt-4">
            <p className="text-sm font-thin text-black">
              Resend code in{' '}
              <span className="font-bold ">
                <Countdown
                  seconds={60}
                  countdownRunning={countdownRunning}
                  setCountdownRunning={setCountdownRunning}
                />
              </span>{' '}
              seconds
            </p>
          </div>
        )}
        {!countdownRunning && sentCode && (
          <div className="flex justify-center w-full pt-8">
            <p className="text-sm font-thin text-black">
              Didn't receive the code?{' '}
              <span
                className="font-bold cursor-pointer underline"
                onClick={handleResendCode}
              >
                Resend
              </span>
            </p>
          </div>
        )}
      </Card>
    </>
  );
};

export default PhoneSignIn;
