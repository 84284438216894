import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import { CreateLeadData, UpdateLeadData } from '../../../../global';
import { firestore } from '../utils/firebase';

export const createLead = async (data: CreateLeadData): Promise<void> => {
  const ref = collection(firestore, 'leads');
  await addDoc(ref, data);
};

export const updateLead = async (
  id: string,
  data: UpdateLeadData
): Promise<void> => {
  const ref = doc(firestore, 'leads', id);
  await updateDoc(ref, data);
};
