import {
  doc,
  updateDoc,
  addDoc,
  collection,
  deleteField,
} from 'firebase/firestore';
import {
  CreateSiteData,
  CreateSiteOwnerData,
  UpdateSiteOwnerData,
  UserChanges,
} from '../../../../global';
import { firestore } from '../utils/firebase';
import { createSite } from './sites';

const updateSiteOwner = async (
  id: string,
  data: UpdateSiteOwnerData
): Promise<void> => {
  const ref = doc(firestore, 'users', id);
  await updateDoc(ref, data);
};

export const confirmSiteOwnerRegistration = async (id: string) => {
  await updateSiteOwner(id, { status: 'confirmed' });
};

export const rejectSiteOwnerRegistration = async (
  id: string,
  comment: string
) => {
  await updateSiteOwner(id, { status: 'rejected', rejectionComment: comment });
};

export const confirmSiteOwnerRejection = async (id: string) => {
  await updateSiteOwner(id, { status: 'rejectionConfirmed' });
};

export const registerSiteOwnerChanges = async (
  id: string,
  force: boolean,
  changes: UserChanges
) => {
  if (force) {
    await updateSiteOwner(id, {
      status: 'confirmed',
      ...changes,
      changes: deleteField(),
    });
  } else {
    const updateObj = {};
    Object.keys(changes).forEach((key) => {
      updateObj[`changes.${key}`] = changes[key];
    });
    await updateSiteOwner(id, {
      status: 'changes',
      ...updateObj,
    });
  }
};

export const confirmSiteOwnerChanges = async (
  id: string,
  changes: UserChanges
) => {
  await updateSiteOwner(id, {
    status: 'confirmed',
    ...changes,
    changes: deleteField(),
  });
};

export const confirmSiteOwnerChangesRejection = async (id: string) => {
  await updateSiteOwner(id, {
    status: 'confirmed',
  });
};

export const rejectSiteOwnerChanges = async (
  id: string,
  changes: UserChanges,
  comment?: string
) => {
  await updateSiteOwner(id, {
    status: 'changesRejected',
    changes: deleteField(),
    rejectionComment: comment,
  });
};

export const createSiteOwner = async (
  force: boolean,
  data: CreateSiteOwnerData
): Promise<void> => {
  const siteOwnerData = {
    name: data.name,
    phone: data.phone,
    status: force ? 'confirmed' : 'unconfirmed',
    siteChanged: false,
    roles: ['siteOwner'],
    category: data.category,
  };

  const siteOwnerRef = await addDoc(
    collection(firestore, 'users'),
    siteOwnerData
  );

  const siteData: CreateSiteData = {
    cluster: data.cluster,
    location: data.location,
    name: data.siteName,
    type: data.siteType,
    primaryTAE: data.primaryTAE,
    siteOwner: {
      id: siteOwnerRef.id,
      name: data.name,
      phone: data.phone,
      siteOwnerCategory: data.category,
    },
    sop: data.sop,
    status: force ? 'confirmed' : 'unconfirmed',
    userIds: [siteOwnerRef.id, data.primaryTAE.id, data.sop.id],
  };

  await createSite(siteData);
};
