import { initStore } from '../hooks/use-store';

type Role = 'fsa' | 'sop' | 'distributor' | 'fsm' | 'retailer';

const configureRolesStore = () => {
  const actions = {
    SET_ROLES: (curState, roles: Role[]) => {
      return { ...curState, roles };
    },
  };
  initStore(actions, { roles: ['fsa', 'distributor'] });
};

export default configureRolesStore;
