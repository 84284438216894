import React, { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';

type CodeProps = {
  fields?: number;
  value?: string;
  onChange?: (value: string) => void;
  isValid?: boolean;
};

const BACKSPACE_KEY = 8;
const LEFT_ARROW_KEY = 37;
const UP_ARROW_KEY = 38;
const RIGHT_ARROW_KEY = 39;
const DOWN_ARROW_KEY = 40;
const E_KEY = 69;

export const Code: React.FC<CodeProps> = ({
  fields = 6,
  value = '',
  onChange,
  isValid = true,
}) => {
  const [input, setInput] = useState<string[]>([]);

  const textInput = useRef([]);

  const id = useMemo(() => uuid(), []);

  useEffect(() => {
    const inputs = [];
    for (let i = 0; i < fields; i++) {
      inputs.push(value[i] || '');
    }
    setInput(inputs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = String(e.target.value);
    let fullValue = newValue;

    if (newValue !== '') {
      const newInput = [...input];

      if (newValue.length > 1) {
        newValue.split('').forEach((char, index) => {
          if (Number(e.target.dataset.id) + index < fields) {
            newInput[Number(e.target.dataset.id) + index] = char;
          }
        });
      } else {
        newInput[Number(e.target.dataset.id)] = newValue;
      }

      newInput.forEach((value, index) => {
        if (textInput.current[index]) {
          textInput.current[index].value = value;
        }
      });

      const notLast = Number(e.target.dataset.id) < input.length - 1;

      const newTarget =
        textInput.current[
          notLast
            ? Number(e.target.dataset.id) + 1
            : Number(e.target.dataset.id)
        ];

      if (newTarget && notLast) {
        newTarget.focus();
        newTarget.select();
      }

      if (newTarget && !notLast) {
        newTarget.blur();
      }

      fullValue = newInput.join('');

      setInput(newInput);
    }

    if (onChange && fullValue) {
      onChange(fullValue);
    }
  };

  const handleKeyDown = (e) => {
    const target = Number(e.target.dataset.id),
      nextTarget = textInput.current[target + 1],
      prevTarget = textInput.current[target - 1];

    let newInput, newValue;

    switch (e.keyCode) {
      case BACKSPACE_KEY:
        e.preventDefault();
        textInput.current[target].value = '';
        newInput = [...input];
        newInput[target] = '';
        newValue = newInput.join('');

        setInput(newInput);

        if (textInput.current[target].value === '') {
          if (prevTarget) {
            prevTarget.focus();
            prevTarget.select();
          }
        }
        if (onChange) {
          onChange(newValue);
        }
        break;

      case LEFT_ARROW_KEY:
        e.preventDefault();
        if (prevTarget) {
          prevTarget.focus();
          prevTarget.select();
        }
        break;

      case RIGHT_ARROW_KEY:
        e.preventDefault();
        if (nextTarget) {
          nextTarget.focus();
          nextTarget.select();
        }
        break;

      case UP_ARROW_KEY:
        e.preventDefault();
        break;

      case DOWN_ARROW_KEY:
        e.preventDefault();
        break;

      case E_KEY: // This case needs to be handled because of https://stackoverflow.com/questions/31706611/why-does-the-html-input-with-type-number-allow-the-letter-e-to-be-entered-in
        if (e.target.type === 'number') {
          e.preventDefault();
          break;
        }
        break;

      default:
        break;
    }
  };

  const styles = clsx(
    'rounded-md border m-1 pl-4 pr-0 w-14 h-16 text-3xl shadow-md caret-transparent selection:bg-transparent',
    !isValid && 'border-red-500 shadow-red-200',
    isValid && 'border-gray-100'
  );

  return (
    <div className="flex justify-center w-full mt-4">
      {input.map((value, index) => {
        return (
          <input
            key={`${id}-${index}`}
            className={styles}
            data-id={index}
            type="number"
            ref={(el) => (textInput.current[index] = el)}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            value={value}
            min={0}
            max={9}
          />
        );
      })}
    </div>
  );
};
