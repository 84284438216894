import { useRef } from 'react';
import ReactDOM from 'react-dom';
import Button from '../ui/Button';

type ConfirmProps = {
  open: boolean;
  title?: string;
  link?: string;
  confirmText?: string;
  cancelText?: string;
  setOpen: (boolean) => void;
  onConfirm: () => void;
  onCancel: () => void;
  oneButtonText?: string;
};

export const ConfirmTOS = ({
  open,
  title = 'Are you sure?',
  link = '',
  confirmText = 'Yes',
  cancelText = 'No',
  setOpen,
  onConfirm,
  onCancel,
  oneButtonText,
}: ConfirmProps) => {
  const node = useRef<HTMLDivElement>(null);

  return open
    ? ReactDOM.createPortal(
        <div
          className="fixed z-50 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div
              ref={node}
              className="inline-block max-w-xs bg-white rounded-3xl px-6 py-4 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle"
            >
              <h1 className="mb-4 text-lh-head-black text-lg font-bold">
                Terms of Service
              </h1>
              <h1 className="mb-4 text-lh-head-black text-lg">{title}</h1>
              <a
                className=" text-lh-dark-blue"
                href={link}
                target="_blank"
                rel="noreferrer"
              >
                {link}
              </a>
              <div className="flex justify-between mt-2">
                {oneButtonText ? (
                  <Button
                    onClick={onConfirm}
                    text={oneButtonText}
                    color="green"
                    className="flex-grow mr-1"
                  />
                ) : (
                  <>
                    <Button
                      onClick={onConfirm}
                      text={confirmText}
                      color="green"
                      className="flex-grow mr-1"
                    />
                    <Button
                      onClick={onCancel}
                      text={cancelText}
                      color="red"
                      className="flex-grow ml-1"
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>,
        document.body
      )
    : null;
};
