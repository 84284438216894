import { DevTool } from '@hookform/devtools';
import { useEffect, useMemo, useState } from 'react';
import { RegisterOptions, useForm } from 'react-hook-form';
import { To, useNavigate, useParams } from 'react-router-dom';
import { OutletChannel, Option, Location } from '../../../../../global';
import { Confirm } from '../../../components/forms/Confirm';
import Input from '../../../components/forms/Input';
import SearchableSelect from '../../../components/forms/SearchableSelect';
import Select from '../../../components/forms/Select';
import Content from '../../../components/layout/Content';
import Alert from '../../../components/ui/Alert';
import Back from '../../../components/ui/Back';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import Loader from '../../../components/ui/Loader';
import { useCheckAccess } from '../../../lib/auth/use-checkAccess';
import { useAssociates } from '../../../lib/hooks/use-associates';
import { useOutlet } from '../../../lib/hooks/use-outlets';
import { useRetailer } from '../../../lib/hooks/use-retailers';
import { useGeolocation } from '../../../lib/utils/useGeolocation';
import useInterval from '../../../lib/utils/useInterval';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Map from '../../../components/map/Map';
import Layers from '../../../components/map/layers/Layers';
import IconUpdateLayer from '../../../components/map/layers/IconUpdateLayer';
import IconLayer from '../../../components/map/layers/IconLayer';

import storeImage from '../../../assets/icons/store-solid-white.svg';

type EditOutletParams = 'id';

type EditOutletForm = {
  geohash: string;
  name: string;
  primaryDistributorId: Option;
  primaryFSAId: Option;
  channel: OutletChannel;
  lat: number;
  lng: number;
  accuracy: number;
  addressStreet: string;
  addressNr: string;
  addressLandmark: string;
  addressCity: string;
  addressArea: string;
};

const CHANNEL_TYPES = [
  {
    key: 'blockmaker',
    label: 'Blockmaker',
    value: 'blockmaker',
  },
  {
    key: 'blockmaker_retailer',
    label: 'Blockmaker / Retailer',
    value: 'blockmaker_retailer',
  },
  {
    key: 'container',
    label: 'Container',
    value: 'container',
  },
  {
    key: 'neighborhood_shop',
    label: 'Neighborhood Shop',
    value: 'neighborhood_shop',
  },
  {
    key: 'palleter',
    label: 'Palleter',
    value: 'palleter',
  },
  {
    key: 'tiel_seller',
    label: 'Tile Seller',
    value: 'tile_seller',
  },
];

export const EditOutlet = () => {
  const MIN_ACCURACY = 100;
  const { id } = useParams<EditOutletParams>();
  const navigate = useNavigate();
  const checkAccess = useCheckAccess();
  const [retailerId, setRetailerId] = useState('');
  const { error: retailerError, loading: retailerLoading } =
    useRetailer(retailerId);
  const {
    outlet,
    error: outletError,
    loading: outletLoading,
    update: outletUpdate,
    requestDeletion,
    cancelDeletion,
  } = useOutlet(id);
  const {
    error: distributorsError,
    loading: distributorsLoading,
    associates: distributors,
  } = useAssociates({ roles: 'distributor', pageSize: 0 });
  const {
    error: fsasError,
    loading: fsasLoading,
    associates: fsas,
  } = useAssociates({ roles: 'fsa', pageSize: 0 });
  const {
    register,
    formState: { isValid, isDirty, errors, dirtyFields },
    reset,
    handleSubmit,
    control,
    setValue,
    trigger,
  } = useForm<EditOutletForm>();
  const [showOutletAlert, setShowOutletAlert] = useState<boolean>(false);
  const [showRetailerAlert, setShowRetailerAlert] = useState<boolean>(false);
  const [showDistributorsAlert, setShowDistributorsAlert] =
    useState<boolean>(false);
  const [showFSAsAlert, setShowFSAsAlert] = useState<boolean>(false);
  const [showLocationAlert, setShowLocationAlert] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [showDeletionRequestConfirmation, setShowDeletionRequestConfirmation] =
    useState<boolean>(false);
  const [
    showCancelDeletionRequestConfirmation,
    setShowCancelDeletionRequestConfirmation,
  ] = useState<boolean>(false);
  const [backString, setBackString] = useState<To>('/retailers');
  const [geohashCaptured, setGeoHashCaptured] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number>(60);
  const {
    coords,
    positionError,
    isGeolocationAvailable,
    isGeolocationEnabled,
  } = useGeolocation({
    userDecisionTimeout: 5000,
    watchPosition: true,
  });
  const [location, setLocation] = useState<Location>();

  const locationErrorMessage = useMemo(() => {
    switch (positionError?.code) {
      case 1: // PERMISSION_DENIED
        return 'Please enable location services following the user manual: https://www.mcomtech.ch/guide';
      case 2: // POSITION_UNAVAILABLE
        return 'Location services are unavailable. Please try again later.';
      case 3: // TIMEOUT
        return 'Location services timed out. Please try again later.';
      case undefined:
        return undefined;
      default:
        return 'Unknown error';
    }
  }, [positionError]);

  const countDownDelay = useMemo(
    () => (countdown === 0 ? null : 1000),
    [countdown]
  );

  const waitingForAccuracy = useMemo(() => {
    if (
      countdown === 0 ||
      !isGeolocationAvailable ||
      !isGeolocationEnabled ||
      positionError
    ) {
      return false;
    }
    return !coords || coords.accuracy > MIN_ACCURACY;
  }, [
    coords,
    MIN_ACCURACY,
    countdown,
    isGeolocationAvailable,
    isGeolocationEnabled,
    positionError,
  ]);

  useInterval(() => {
    setCountdown(countdown - 1);
  }, countDownDelay);

  const nameOptions: RegisterOptions = {
    required: 'Please enter a name!',
    minLength: {
      value: 3,
      message: 'Please enter a name with at least 3 characters!',
    },
    maxLength: {
      value: 50,
      message: 'The name cannot exceed 50 characters!',
    },
  };

  const addressStreetOptions: RegisterOptions = {
    required: 'Please enter a street name!',
    minLength: {
      value: 3,
      message: 'Please enter a street name with at least 3 characters!',
    },
    maxLength: {
      value: 50,
      message: 'The street name cannot exceed 50 characters!',
    },
  };

  const addressNumberOptions: RegisterOptions = {
    required: 'Please enter a street number!',
    minLength: {
      value: 1,
      message: 'Please enter a street name with at least 1 characters!',
    },
    maxLength: {
      value: 50,
      message: 'The street name cannot exceed 10 characters!',
    },
  };

  const addressLandmarkOptions: RegisterOptions = {
    required: 'Please enter a landmark name!',
    minLength: {
      value: 3,
      message: 'Please enter a landmark name with at least 3 characters!',
    },
    maxLength: {
      value: 50,
      message: 'The landmark name cannot exceed 50 characters!',
    },
  };

  const addressAreaOptions: RegisterOptions = {
    required: 'Please enter an area / neighborhood name!',
    minLength: {
      value: 3,
      message:
        'Please enter an area / neighborhood name with at least 3 characters!',
    },
    maxLength: {
      value: 50,
      message: 'The area / neighborhood name cannot exceed 50 characters!',
    },
  };

  const addressCityOptions: RegisterOptions = {
    required: 'Please enter a city name!',
    minLength: {
      value: 3,
      message: 'Please enter a city name with at least 3 characters!',
    },
    maxLength: {
      value: 50,
      message: 'The city name cannot exceed 50 characters!',
    },
  };

  const channelOptions: RegisterOptions = {
    required: 'Please select a channel type!',
  };

  useEffect(() => {
    if (outlet) {
      setRetailerId(outlet.retailer.id);
    }
  }, [outlet]);

  useEffect(() => {
    if (outlet) {
      const values = {
        name: outlet.name,
        primaryDistributorId: {
          value: outlet.primaryDistributor.id,
          label: outlet.primaryDistributor.name,
        },
        primaryFSAId: {
          value: outlet.primaryFSA.id,
          label: outlet.primaryFSA.name,
        },
      };
      if (outlet.location) {
        values['lat'] = outlet.location.lat;
        values['lng'] = outlet.location.lng;
        values['geohash'] = outlet.location.geohash;
        values['accuracy'] = outlet.location.accuracy || undefined;
      }
      if ('channel' in outlet) {
        values['channel'] = outlet.channel;
      }
      if ('address' in outlet) {
        values['addressStreet'] = outlet.address.street;
        values['addressNr'] = outlet.address.nr;
        values['addressLandmark'] = outlet.address.landmark;
        values['addressCity'] = outlet.address.city;
        values['addressArea'] = outlet.address.area;
      }
      reset(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outlet]);

  useEffect(() => {
    if (outletError) {
      setShowOutletAlert(true);
    } else {
      setShowOutletAlert(false);
    }
  }, [outletError]);

  useEffect(() => {
    if (distributorsError) {
      setShowDistributorsAlert(true);
    } else {
      setShowDistributorsAlert(false);
    }
  }, [distributorsError]);

  useEffect(() => {
    if (fsasError) {
      setShowFSAsAlert(true);
    } else {
      setShowFSAsAlert(false);
    }
  }, [fsasError]);

  useEffect(() => {
    if (outlet) {
      setBackString(`/retailers/${outlet.retailer.id}`);
    }
  }, [outlet]);

  useEffect(() => {
    if (positionError) {
      setShowLocationAlert(true);
    }
  }, [positionError]);

  const handleCaptureLocation = () => {
    if (coords) {
      const { lat, lng, geohash, accuracy } = coords;
      setLocation({ lat, lng, geohash, accuracy });
      setValue('lat', lat, { shouldDirty: true });
      setValue('lng', lng, { shouldDirty: true });
      setValue('geohash', geohash, { shouldDirty: true });
      setValue('accuracy', accuracy, { shouldDirty: true });
      setGeoHashCaptured(true);
      trigger(['lat', 'lng', 'geohash', 'accuracy']);
    }
  };

  const submitHandler = (data: EditOutletForm) => {
    if (isDirty) {
      const updateObj = {};
      let firstCapture = false;
      if (dirtyFields.name) {
        updateObj['name'] = data.name;
      }
      if (dirtyFields.primaryDistributorId) {
        const dist = distributors.find(
          (dist) => dist.id === data.primaryDistributorId.value
        );
        updateObj['primaryDistributor'] = {
          id: dist.id,
          name: dist.name,
          phone: dist.phone,
        };
      }
      if (dirtyFields.primaryFSAId) {
        const fsa = fsas.find((fsa) => fsa.id === data.primaryFSAId.value);
        updateObj['primaryFSA'] = {
          id: fsa.id,
          name: fsa.name,
          phone: fsa.phone,
        };
      }
      if (dirtyFields.channel) {
        updateObj['channel'] = data.channel;
      }
      if (
        dirtyFields.geohash ||
        dirtyFields.lat ||
        dirtyFields.lng ||
        dirtyFields.accuracy
      ) {
        updateObj['location'] = {
          geohash: data.geohash,
          lat: data.lat,
          lng: data.lng,
          accuracy: data.accuracy,
        };
        if (!outlet.location) {
          firstCapture = true;
        }
      }
      if (
        dirtyFields.addressStreet ||
        dirtyFields.addressNr ||
        dirtyFields.addressLandmark ||
        dirtyFields.addressCity ||
        dirtyFields.addressArea
      ) {
        updateObj['address'] = {
          street: data.addressStreet,
          nr: data.addressNr,
          landmark: data.addressLandmark,
          city: data.addressCity,
          area: data.addressArea,
        };
      }

      outletUpdate({ ...updateObj, firstCapture });
      navigate(backString, { replace: true });
    }
  };

  const handleRequestDeletion = () => {
    requestDeletion();
    navigate(backString, { replace: true });
  };

  const handleRequestDeletionConfirm = () => {
    setShowDeletionRequestConfirmation(false);
    handleRequestDeletion();
  };

  const handleRequestDeletionCancel = () => {
    setShowDeletionRequestConfirmation(false);
  };

  const handleCancelDeletionRequest = () => {
    setShowCancelDeletionRequestConfirmation(true);
  };

  const handleCancelDeletion = () => {
    cancelDeletion();
    navigate(backString, { replace: true });
  };

  const handleCancelDeletionConfirm = () => {
    setShowCancelDeletionRequestConfirmation(false);
    handleCancelDeletion();
  };

  const handleCancelDeletionCancel = () => {
    setShowCancelDeletionRequestConfirmation(false);
  };

  const handleOnConfirm = () => {
    setShowConfirmation(false);
    handleSubmit(submitHandler)();
  };

  const handleOnCancel = () => {
    setShowConfirmation(false);
  };

  return (
    <Content>
      <Loader
        show={
          outletLoading || distributorsLoading || fsasLoading || retailerLoading
        }
      />
      <Alert
        message={retailerError && retailerError.message}
        open={showRetailerAlert}
        setOpen={(open) => setShowRetailerAlert(open)}
        title="Error"
      />
      <Alert
        message={outletError && outletError.message}
        open={showOutletAlert}
        setOpen={(open) => setShowOutletAlert(open)}
        title="Error"
      />
      <Alert
        message={distributorsError && distributorsError.message}
        open={showDistributorsAlert}
        setOpen={(open) => setShowDistributorsAlert(open)}
        title="Error"
      />
      <Alert
        message={fsasError && fsasError.message}
        open={showFSAsAlert}
        setOpen={(open) => setShowFSAsAlert(open)}
        title="Error"
      />
      <Alert
        message={positionError && locationErrorMessage}
        open={showLocationAlert}
        setOpen={(open) => setShowLocationAlert(open)}
        title="Error"
      />
      <Confirm
        title="Are you sure you want to save these changes?"
        open={showConfirmation}
        onConfirm={handleOnConfirm}
        onCancel={handleOnCancel}
        setOpen={setShowConfirmation}
      />
      <Confirm
        title="Are you sure your want to request the deletion of this outlet?"
        open={showDeletionRequestConfirmation}
        onConfirm={handleRequestDeletionConfirm}
        onCancel={handleRequestDeletionCancel}
        setOpen={setShowDeletionRequestConfirmation}
      />
      <Confirm
        title="Are you sure your want to cancel the request?"
        open={showCancelDeletionRequestConfirmation}
        onConfirm={handleCancelDeletionConfirm}
        onCancel={handleCancelDeletionCancel}
        setOpen={setShowCancelDeletionRequestConfirmation}
      />
      <Card>
        <div className="flex col-span-full justify-between">
          <Back to={backString} />
          {checkAccess(['fsa', 'sop', 'fsm']) &&
            outlet?.active &&
            !outlet.deletionRequested && (
              <Button
                className="py-0"
                color="red"
                onClick={() => setShowDeletionRequestConfirmation(true)}
              >
                Request Deletion
              </Button>
            )}
          {checkAccess(['fsa', 'sop', 'fsm']) &&
            outlet?.active &&
            outlet.deletionRequested && (
              <div className="flex items-center">
                <p className="items-center">Deletion request pending...</p>
                <button
                  className="underline hover:text-blue-500"
                  onClick={handleCancelDeletionRequest}
                >
                  Cancel
                </button>
              </div>
            )}
        </div>
        <form
          name="edit-outlet-form"
          onSubmit={(event) => event.preventDefault()}
          className="col-span-full flex flex-col"
        >
          <label htmlFor="name">Name</label>
          <Input
            register={register}
            name="name"
            placeholder="Name..."
            error={errors.name}
            options={nameOptions}
          />
          <label htmlFor="channel">Channel</label>
          <Select
            register={register}
            name="channel"
            items={CHANNEL_TYPES}
            placeholder="Select channel..."
            error={errors.channel}
            options={channelOptions}
          />
          <SearchableSelect
            control={control}
            name="primaryDistributorId"
            rules={{ required: true }}
            label="Primary Distributor"
            placeholder="Select Distributor..."
            values={
              distributors
                ? distributors.map((fsa) => ({
                    value: fsa.id,
                    label: fsa.name,
                  }))
                : []
            }
            className="w-full text-lh-text-black mb-4"
          />
          {checkAccess(['sop']) && (
            <SearchableSelect
              control={control}
              name="primaryFSAId"
              rules={{ required: true }}
              label="Primary FSA"
              placeholder="Select FSA..."
              values={
                fsas
                  ? fsas.map((fsa) => ({
                      value: fsa.id,
                      label: fsa.name,
                    }))
                  : []
              }
              className="w-full text-lh-text-black mb-4"
            />
          )}
          <label
            htmlFor="Address"
            className="font-bold col-span-12 mb-2 mt-2 text-lh-head-black"
          >
            Address
          </label>
          <label htmlFor="addressStreet">Street name</label>
          <Input
            register={register}
            name="addressStreet"
            placeholder="Street..."
            error={errors.addressStreet}
            options={addressStreetOptions}
          />
          <label htmlFor="addressNr">Building number</label>
          <Input
            register={register}
            name="addressNr"
            placeholder="Nr..."
            error={errors.addressNr}
            options={addressNumberOptions}
          />
          <label htmlFor="addressLandmark">Closest landmark</label>
          <Input
            register={register}
            name="addressLandmark"
            placeholder="Landmark..."
            error={errors.addressLandmark}
            options={addressLandmarkOptions}
          />
          <label htmlFor="addressArea">Area / Neighborhood</label>
          <Input
            register={register}
            name="addressArea"
            placeholder="Area..."
            error={errors.addressArea}
            options={addressAreaOptions}
          />
          <label htmlFor="addressCity">City/Town</label>
          <Input
            register={register}
            name="addressCity"
            placeholder="City/Town..."
            error={errors.addressCity}
            options={addressCityOptions}
          />
          {waitingForAccuracy && (
            <div className="col-span-7 col-start-2 flex flex-row justify-center">
              <FontAwesomeIcon
                icon={faSpinner}
                className="animate-spin h-5 w-5 mr-3"
              ></FontAwesomeIcon>
              <p>Waiting for device location...{countdown} seconds left.</p>
            </div>
          )}
          <Button
            buttonDisabled={waitingForAccuracy || !!positionError}
            onClick={handleCaptureLocation}
            text={
              geohashCaptured
                ? 'Re-Capture Location'
                : 'Capture Outlet Location'
            }
            color="green"
          />
          <input {...register('lat')} className="hidden" />
          <input {...register('lng')} className="hidden" />
          <input {...register('geohash')} className="hidden" />
          <input {...register('accuracy')} className="hidden" />
          {geohashCaptured && (
            <>
              <p className="text-green-500 font-thin text-sm">
                The location has ben captured!
              </p>
              <Map center={location} className="col-span-full h-56 mt-4">
                <Layers>
                  {outlet.location ? (
                    <IconUpdateLayer
                      oldLocation={outlet.location}
                      newLocation={location}
                      icon={storeImage}
                      name={'Location'}
                    />
                  ) : (
                    <IconLayer
                      location={location}
                      icon={storeImage}
                      name={'Location'}
                    />
                  )}
                </Layers>
              </Map>
            </>
          )}
          <Button
            buttonDisabled={!isValid || !isDirty}
            text="Save"
            color="green"
            onClick={() => {
              setShowConfirmation(true);
            }}
          />
        </form>
        <DevTool control={control} />
      </Card>
    </Content>
  );
};
