import dayjs from 'dayjs';
import { useState } from 'react';
import { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import Alert from '../../../components/ui/Alert';
import Card from '../../../components/ui/Card';
import { useLead } from '../../../lib/hooks/use-leads';
import RejectLead from '../../../components/leads/RejectLead';
import FulfillLead from '../../../components/leads/FulfillLead';
import Loader from '../../../components/ui/Loader';
import Back from '../../../components/ui/Back';
import Button from '../../../components/ui/Button';
import { useCheckAccess } from '../../../lib/auth/use-checkAccess';
import Content from '../../../components/layout/Content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faComment,
  faUserCircle,
  faClock,
  faCheckCircle,
  faTimesCircle,
  faTrashAlt,
} from '@fortawesome/free-regular-svg-icons';
import { faTruck } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../../lib/hooks/use-auth';
import CancelLead from '../../../components/leads/CancelLead';
import SearchableSelect from '../../../components/forms/SearchableSelect';
import { useAssociates } from '../../../lib/hooks/use-associates';
import { useForm } from 'react-hook-form';
import AssignLead from '../../../components/leads/AssignLead';
import clsx from 'clsx';

type LeadDetailsParams = 'id';

const Lead = () => {
  const { id } = useParams<LeadDetailsParams>();
  const { id: userId } = useAuth();
  const checkAccess = useCheckAccess();
  const navigate = useNavigate();
  const { lead, error, update, loading } = useLead(id);
  const [showAssign, setShowAssign] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [showFulfill, setShowFulfill] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [displayComment, setDisplayComment] = useState(false);
  const [displayAssignDistributorButton, setDisplayAssignDistributorButton] =
    useState(false);
  const [displayFulfillButton, setDisplayFulfillButton] = useState(false);
  const [displayCancelButton, setDisplayCancelButton] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const { associates: distributors } = useAssociates({
    roles: 'distributor',
    pageSize: 0,
  });

  const {
    formState: { isValid },
    getValues,
    control,
  } = useForm({ mode: 'all', reValidateMode: 'onChange' });

  useEffect(() => {
    if (error) {
      setShowAlert(true);
    } else if (showAlert) {
      setShowAlert(false);
    }
    return () => {
      setShowAlert(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (lead) {
      switch (lead.status) {
        case 'pending':
          setDisplayAssignDistributorButton(true);
          setDisplayCancelButton(lead.createdBy.id === userId);
          break;
        case 'accepted':
          setDisplayFulfillButton(true);
          setDisplayComment(true);

          break;
        case 'assigned':
          setDisplayFulfillButton(true);
          setDisplayComment(true);

          break;
        case 'fulfilled':
          setDisplayComment(true);

          break;
        case 'rejected':
          setDisplayComment(true);

          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lead]);

  const handleAssign = async (data) => {
    const values = getValues();
    const distributor = distributors.find(
      (d) => d.id === values['distributor'].value
    );

    await update({
      status: 'assigned',
      distributor: {
        id: distributor.id,
        name: distributor.name,
        phone: distributor.phone,
      },
    });
    navigate('/leads', { replace: true });
  };
  const handleReject = async (data) => {
    await update({ status: 'rejected', comment: data.comment });
    navigate('/leads', { replace: true });
  };
  const handleCancel = async (data) => {
    await update({ status: 'canceled', comment: data.comment });
    navigate('/leads', { replace: true });
  };
  const handleFulfill = async (data) => {
    const fulfillments = lead.fulfillments ? lead.fulfillments + 1 : 1;

    const classic = isNaN(data.classic) ? 0 : data.classic;
    const supaset = isNaN(data.supaset) ? 0 : data.supaset;
    const supafixc0 = isNaN(data.supafixc0) ? 0 : data.supafixc0;
    const supafixc1 = isNaN(data.supafixc1) ? 0 : data.supafixc1;
    const watershield = isNaN(data.watershield) ? 0 : data.watershield;
    const pop = isNaN(data.pop) ? 0 : data.pop;
    // tempProduct2 = isNaN(data.tempProduct2) ? 0 : data.tempProduct2;
    // tempProduct3 = isNaN(data.tempProduct3) ? 0 : data.tempProduct3;
    // tempProduct4 = isNaN(data.tempProduct4) ? 0 : data.tempProduct4;

    const productsDelivered = {
      classic:
        lead.productsDelivered && lead.productsDelivered.classic
          ? lead.productsDelivered.classic + classic
          : classic,
      supaset:
        lead.productsDelivered && lead.productsDelivered.supaset
          ? lead.productsDelivered.supaset + supaset
          : supaset,
      supafixc0:
        lead.productsDelivered && lead.productsDelivered.supafixc0
          ? lead.productsDelivered.supafixc0 + supafixc0
          : supafixc0,
      supafixc1:
        lead.productsDelivered && lead.productsDelivered.supafixc1
          ? lead.productsDelivered.supafixc1 + supafixc1
          : supafixc1,
      watershield:
        lead.productsDelivered && lead.productsDelivered.watershield
          ? lead.productsDelivered.watershield + watershield
          : watershield,
       
      pop:
        lead.productsDelivered && lead.productsDelivered.pop
          ? lead.productsDelivered.pop + pop
          : pop,
        
      /* tempProduct2
      tempProduct2:
        lead.productsDelivered && lead.productsDelivered.tempProduct2
          ? lead.productsDelivered.tempProduct2 + tempProduct2
          : tempProduct2,
        tempProduct2 */
      /* tempProduct3
      tempProduct3:
        lead.productsDelivered && lead.productsDelivered.tempProduct3
          ? lead.productsDelivered.tempProduct3 + tempProduct3
          : tempProduct3,
        tempProduct3 */
      /* tempProduct4
      tempProduct4:
        lead.productsDelivered && lead.productsDelivered.tempProduct4
          ? lead.productsDelivered.tempProduct4 + tempProduct4
          : tempProduct4,
        tempProduct4 */
    };
    await update({
      status: 'fulfilled',
      productsDelivered,
      fulfillments: fulfillments,
    });
    navigate('/leads', { replace: true });
  };

  return (
    <>
      {checkAccess(['sop', 'fsa']) && (
        <AssignLead
          open={showAssign}
          onAssign={handleAssign}
          setOpen={(open) => setShowAssign(open)}
          distributorName={
            getValues()['distributor']?.label
              ? getValues()['distributor'].label
              : ''
          }
        />
      )}
      {checkAccess(['sop', 'distributor']) && (
        <RejectLead
          open={showReject}
          onReject={handleReject}
          setOpen={(open) => setShowReject(open)}
        />
      )}
      {checkAccess(['sop', 'distributor', 'fsa']) && (
        <CancelLead
          open={showCancel}
          onCancel={handleCancel}
          setOpen={(open) => setShowCancel(open)}
        />
      )}
      {checkAccess(['fsa', 'sop', 'distributor']) && (
        <FulfillLead
          open={showFulfill}
          lead={lead}
          onFulfill={handleFulfill}
          setOpen={(open) => setShowFulfill(open)}
          confirmText="Are you sure you want to fulfill the lead?"
          buttonText="Fulfill"
        />
      )}
      <Loader show={loading} />
      <Alert
        message={error && error.message}
        open={showAlert}
        setOpen={(open) => setShowAlert(open)}
        title="Error"
      />
      <Content>
        {loading || lead ? (
          <Card className="grid grid-cols-10">
            <Back to="/leads" className="col-span-3" />
            <div className="col-span-4 flex justify-center items-center text-center text-xl font-bold text-lh-head-black">
              {lead && (
                <FontAwesomeIcon
                  icon={lead.createdOn === 'chatbot' ? faComment : faUserCircle}
                  className="mr-2 text-lh-head-black"
                />
              )}
              {lead && lead.id}
            </div>
            <h2 className="col-span-8 font-bold">
              {lead && lead.site.name}
              <p className="col-span-full font-normal text-lh-text-black">
                CreatedAt: {lead && dayjs(lead.createdAt).format('DD.MM.YYYY HH:mm')}
              </p>
              {lead && lead.deliveredAt && (
                <p className="col-span-full font-normal text-lh-text-black">
                  FulfilledAt: {lead && lead.deliveredAt && dayjs(lead.deliveredAt).format('DD.MM.YYYY HH:mm')}
                </p>
              )}
            </h2>
            <div className="col-span-2 flex flex-col items-center font-bold text-lg text-lh-head-black">
              <p>Status</p>
              {lead &&
                lead.status &&
                (lead.status === 'pending' ? (
                  <FontAwesomeIcon icon={faClock} className="text-2xl mt-0" />
                ) : lead.status === 'accepted' ? (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-2xl mt-0"
                  />
                ) : lead.status === 'rejected' ? (
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    className="text-2xl mt-0"
                  />
                ) : lead.status === 'fulfilled' ? (
                  <FontAwesomeIcon icon={faTruck} className="text-2xl mt-0" />
                ) : lead.status === 'canceled' ? (
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    className="text-2xl mt-0"
                  />
                ) : (
                  ''
                ))}
            </div>
            <hr className="col-span-full mt-2 mb-2" />
            <h2 className="col-span-full font-bold text-lh-head-black">
              First Outlet
            </h2>
            <p
              className={
                lead &&
                clsx(
                  'col-span-8 ',
                  (lead.firstOutlet?.status === 'rejected' ||
                    lead.firstOutlet?.status === 'canceled') &&
                    'text-red-500',
                  (lead.firstOutlet?.status === 'scheduled' ||
                    lead.firstOutlet?.status === 'informed') &&
                    'text-yellow-500',
                  lead.firstOutlet?.status === 'accepted' && 'text-green-500'
                )
              }
            >
              {lead && lead.firstOutlet?.name}
            </p>
            <p
              className={
                lead &&
                clsx(
                  'col-span-2 text-center',
                  (lead.firstOutlet?.status === 'rejected' ||
                    lead.firstOutlet?.status === 'canceled') &&
                    'text-red-500',
                  (lead.firstOutlet?.status === 'scheduled' ||
                    lead.firstOutlet?.status === 'informed') &&
                    'text-yellow-500',
                  lead.firstOutlet?.status === 'accepted' && 'text-green-500'
                )
              }
            >
              {lead && lead.firstOutlet?.phone}
            </p>
            <h2 className="col-span-full font-bold text-lh-head-black">
              Second Outlet
            </h2>
            <p
              className={
                lead &&
                clsx(
                  'col-span-8 ',

                  (lead.secondOutlet?.status === 'scheduled' ||
                    lead.secondOutlet?.status === 'informed' ||
                    lead.secondOutlet?.status === 'waiting') &&
                    'text-yellow-500',
                  (lead.secondOutlet?.status === 'rejected' ||
                    lead.secondOutlet?.status === 'canceled') &&
                    'text-red-500',
                  lead.secondOutlet?.status === 'accepted' && 'text-green-500'
                )
              }
            >
              {lead && lead.secondOutlet?.name}
            </p>
            <p
              className={
                lead &&
                clsx(
                  'col-span-2 text-center',
                  (lead.secondOutlet?.status === 'rejected' ||
                    lead.secondOutlet?.status === 'canceled') &&
                    'text-red-500',
                  (lead.secondOutlet?.status === 'scheduled' ||
                    lead.secondOutlet?.status === 'informed' ||
                    lead.secondOutlet?.status === 'waiting') &&
                    'text-yellow-500',
                  lead.secondOutlet?.status === 'accepted' && 'text-green-500'
                )
              }
            >
              {lead && lead.secondOutlet?.phone}
            </p>
            {checkAccess(['fsa', 'sop']) &&
              lead &&
              lead.status === 'pending' &&
              (((lead.firstOutlet?.status === 'rejected' ||
                lead.firstOutlet?.status === 'canceled') &&
                (lead.secondOutlet?.status === 'rejected' ||
                  lead.secondOutlet?.status === 'canceled')) ||
                (lead &&
                  !('firstOutlet' in lead) &&
                  !('secondOutlet' in lead))) && (
                <form
                  name="selectDistributorForm"
                  className="grid grid-cols-12 col-span-full mt-2"
                >
                  <SearchableSelect
                    control={control}
                    name="distributor"
                    rules={{ required: true }}
                    label="Distributor"
                    placeholder="Select Distributor..."
                    values={
                      distributors
                        ? distributors.map((distributor) => ({
                            label: distributor.name,
                            value: distributor.id,
                          }))
                        : []
                    }
                    className="col-span-12 text-lh-text-black"
                  />
                </form>
              )}
            {checkAccess(['fsa', 'sop']) &&
              lead &&
              lead.status !== 'pending' &&
              'distributor' in lead &&
              lead.distributor !== null && (
                <>
                  <h2 className="col-span-full font-bold text-lh-head-black">
                    Distributor
                  </h2>
                  <p className="col-span-8 text-lh-text-black">
                    {lead && lead.distributor?.name}
                  </p>
                </>
              )}
            <h2 className="col-span-full font-bold mt-4 mb-2 text-lh-head-black">
              {lead &&
                (['accepted', 'fulfilled'].includes(lead.status)
                  ? 'Products fulfilled / ordered'
                  : 'Products ordered')}
            </h2>
            <h3 className="col-span-2 py-1 text-lh-text-black">Classic</h3>
            <p className="col-span-3 col-start-7 mb-4 text-right py-1 px-3 font-semibold text-lh-text-black">
              {lead &&
                (['accepted', 'fulfilled'].includes(lead.status)
                  ? (lead.productsDelivered
                      ? lead.productsDelivered.classic
                      : 0) +
                    '/' +
                    lead.products.classic
                  : lead.products.classic)}
            </p>
            <p className="col-span-1 py-1 text-gray-500 font-thin">bags</p>
            <h3 className="col-span-2 py-1 text-lh-text-black">Supaset</h3>
            <p className="col-span-3 col-start-7 mb-4 text-right py-1 px-3 font-semibold text-lh-text-black">
              {lead &&
                (['accepted', 'fulfilled'].includes(lead.status)
                  ? (lead.productsDelivered
                      ? lead.productsDelivered.supaset
                      : 0) +
                    '/' +
                    lead.products.supaset
                  : lead.products.supaset)}
            </p>
            <p className="col-span-1 py-1 text-gray-500 font-thin">bags</p>
            <h3 className="col-span-2 py-1 text-lh-text-black">SupafixC0</h3>
            <p className="col-span-3 col-start-7 mb-4 text-right py-1 px-3 font-semibold text-lh-text-black">
              {lead &&
                (['accepted', 'fulfilled'].includes(lead.status)
                  ? (lead.productsDelivered
                      ? lead.productsDelivered.supafixc0
                      : 0) +
                    '/' +
                    lead.products.supafixc0
                  : lead.products.supafixc0)}
            </p>
            <p className="col-span-1 py-1 text-gray-500 font-thin">bags</p>
            <h3 className="col-span-2 py-1 text-lh-text-black">SupafixC1</h3>
            <p className="col-span-3 col-start-7 mb-4 text-right py-1 px-3 font-semibold text-lh-text-black">
              {lead &&
                (['accepted', 'fulfilled'].includes(lead.status)
                  ? (lead.productsDelivered
                      ? lead.productsDelivered.supafixc1
                      : 0) +
                    '/' +
                    lead.products.supafixc1
                  : lead.products.supafixc1)}
            </p>
            <p className="col-span-1 py-1 text-gray-500 font-thin">bags</p>
            <h3 className="col-span-2 py-1 text-lh-text-black">Watershield</h3>
            <p className="col-span-3 col-start-7 mb-4 text-right py-1 px-3 font-semibold text-lh-text-black">
              {lead &&
                (['accepted', 'fulfilled'].includes(lead.status)
                  ? (lead.productsDelivered
                      ? lead.productsDelivered.watershield
                      : 0) +
                    '/' +
                    lead.products.watershield
                  : lead.products.watershield)}
            </p>
            <p className="col-span-1 py-1 text-gray-500 font-thin">bags</p>
            
            <h3 className="col-span-2 py-1 text-lh-text-black">POP</h3>
            <p className="col-span-3 col-start-7 mb-4 text-right py-1 px-3 font-semibold text-lh-text-black">
              {lead &&
                (['accepted', 'fulfilled'].includes(lead.status)
                  ? (lead.productsDelivered
                      ? lead.productsDelivered.pop
                      : 0) +
                    '/' +
                    lead.products.pop
                  : lead.products.pop)}
            </p>
            <p className="col-span-1 py-1 text-gray-500 font-thin">bags</p>
            
            {/* TempProduct2
            <h3 className="col-span-2 py-1 text-lh-text-black">TempProduct2</h3>
            <p className="col-span-3 col-start-7 mb-4 text-right py-1 px-3 font-semibold text-lh-text-black">
              {lead &&
                (['accepted', 'fulfilled'].includes(lead.status)
                  ? (lead.productsDelivered
                      ? lead.productsDelivered.tempProduct2
                      : 0) +
                    '/' +
                    lead.products.tempProduct2
                  : lead.products.tempProduct2)}
            </p>
            <p className="col-span-1 py-1 text-gray-500 font-thin">bags</p>
            TempProduct2 */}
            {/* TempProduct3
            <h3 className="col-span-2 py-1 text-lh-text-black">TempProduct3</h3>
            <p className="col-span-3 col-start-7 mb-4 text-right py-1 px-3 font-semibold text-lh-text-black">
              {lead &&
                (['accepted', 'fulfilled'].includes(lead.status)
                  ? (lead.productsDelivered
                      ? lead.productsDelivered.tempProduct3
                      : 0) +
                    '/' +
                    lead.products.tempProduct3
                  : lead.products.tempProduct3)}
            </p>
            <p className="col-span-1 py-1 text-gray-500 font-thin">bags</p>
            TempProduct3 */}
            {/* TempProduct4
            <h3 className="col-span-2 py-1 text-lh-text-black">TempProduct4</h3>
            <p className="col-span-3 col-start-7 mb-4 text-right py-1 px-3 font-semibold text-lh-text-black">
              {lead &&
                (['accepted', 'fulfilled'].includes(lead.status)
                  ? (lead.productsDelivered
                      ? lead.productsDelivered.tempProduct4
                      : 0) +
                    '/' +
                    lead.products.tempProduct4
                  : lead.products.tempProduct4)}
            </p>
            <p className="col-span-1 py-1 text-gray-500 font-thin">bags</p>
            TempProduct4 */}
            <h2 className="col-span-full font-bold mb-2 text-lh-head-black">
              Delivery Address
            </h2>
            <p className="col-span-full rounded-lg mb-4 py-1 px-3 blead-2 text-lh-text-black">
              {lead && lead.address}
            </p>
            {displayComment && (
              <>
                <h2 className="col-span-full font-bold mb-2 text-lh-head-black">
                  Comment
                </h2>
                <p className="col-span-full rounded-lg mb-4 py-1 px-3 blead-2 text-lh-text-black">
                  {lead && lead.comment}
                </p>
              </>
            )}

            {checkAccess(['sop', 'fsa']) &&
              displayAssignDistributorButton &&
              (((lead.firstOutlet?.status === 'rejected' ||
                lead.firstOutlet?.status === 'canceled') &&
                (lead.secondOutlet?.status === 'rejected' ||
                  lead.secondOutlet?.status === 'canceled')) ||
                (lead &&
                  !('firstOutlet' in lead) &&
                  !('secondOutlet' in lead))) && (
                <Button
                  onClick={() => setShowAssign(true)}
                  color="green"
                  type="button"
                  className="col-span-full mt-2 py-2"
                  buttonDisabled={!isValid}
                >
                  Assign Distributor
                </Button>
              )}
            {checkAccess(['sop', 'fsa', 'distributor']) &&
              displayCancelButton && (
                <Button
                  onClick={() => setShowCancel(true)}
                  color="red"
                  type="button"
                  className="col-span-full mt-2 py-2"
                >
                  Cancel
                </Button>
              )}
            {checkAccess(['fsa', 'sop', 'distributor']) &&
              displayFulfillButton && (
                <Button
                  onClick={() => setShowFulfill(true)}
                  color="blue"
                  type="button"
                  className="col-span-full mt-2 py-2"
                >
                  Fulfil
                </Button>
              )}
          </Card>
        ) : (
          <Card>Lead not found!</Card>
        )}
      </Content>
    </>
  );
};

export default Lead;
