import { addDoc, collection } from 'firebase/firestore';
import { CreateTier2FulfillmentData } from '../../../../global';
import { firestore } from '../utils/firebase';

export const createTier2Fulfillment = async (
  data: CreateTier2FulfillmentData
): Promise<void> => {
  const ref = collection(firestore, 'tier2fulfillment');
  await addDoc(ref, data);
};
