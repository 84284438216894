import { createContext, useEffect, useState } from 'react';
import {
  analytics,
  auth,
  firestore,
  getScopedTypesenseKey,
  getScopedTypesenseKeyUserIds,
} from '../utils/firebase';
import { useAuthState } from '../auth/use-authState';
import {
  doc,
  onSnapshot,
  Timestamp,
  Unsubscribe,
  updateDoc,
} from 'firebase/firestore';
import { User, AuthError, signOut } from 'firebase/auth';
import { logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import { useNavigate } from 'react-router-dom';
import { Role } from '../../../../global';

interface ContextProps {
  id: string | null | undefined;
  user: User | null | undefined;
  roles: Role[] | null;
  typesenseKey: string | null | undefined;
  typesenseKeyUserIds: string | null | undefined;
  loading: boolean;
  error: AuthError | undefined;
  setToken: ((token: string) => void) | undefined;
}

export const AuthContext = createContext<ContextProps>({
  id: null,
  user: null,
  roles: null,
  typesenseKey: null,
  typesenseKeyUserIds: null,
  loading: true,
  error: undefined,
  setToken: undefined,
});

export const AuthProvider = ({ children }: any) => {
  const [user, loading, error] = useAuthState(auth);
  const [id, setId] = useState<string>();
  const [roles, setRoles] = useState<Role[]>([]);
  const navigate = useNavigate();
  const [typesenseKey, setTypesenseKey] = useState<string>();
  const [typesenseKeyUserIds, setTypesenseKeyUserIds] = useState<string>();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    let timeout: NodeJS.Timeout;
    if (user) {
      const ref = doc(firestore, 'identities', user.uid);
      unsubscribe = onSnapshot(ref, (doc) => {
        if (doc.exists()) {
          if (timeout) {
            clearTimeout(timeout);
          }
          setRoles(doc.data()?.roles);
          setUserProperties(analytics, { roles: doc.data()?.roles });
          setUserId(analytics, id);

          if (doc.data().id !== id) setId(doc.data()?.id);
          setUserProperties(analytics, { user_id: doc.data()?.id });
          setUserId(analytics, id);

        } else {
          timeout = setTimeout(() => {
            signOut(auth);
            navigate('/denied', { replace: true });
          }, 10000);
        }
      });
    } else {
      setId(undefined);
      setRoles([]);
    }
    logEvent(analytics, 'user_login', { user_id: id, roles: roles.join(',') });
    return () => {
      if (unsubscribe) unsubscribe();
      if (timeout) clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (user && id) {
      getScopedTypesenseKey(user.uid).then((result: any) => {
        setTypesenseKey(result.data?.typesenseKey);
      });
      getScopedTypesenseKeyUserIds(user.uid).then((result: any) => {
        setTypesenseKeyUserIds(result.data?.typesenseKey);
      });
    }
  }, [id, user]);

  const setToken = async (token: string) => {
    if (user) {
      const ref = doc(firestore, 'identities', user.uid);
      await updateDoc(ref, {
        [`tokens.${token}`]: Timestamp.now(),
      });
    }
  };

  const value = {
    id,
    user,
    roles,
    typesenseKey,
    typesenseKeyUserIds,
    loading,
    error,
    setToken,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
