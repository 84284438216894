import { MessagePayload } from 'firebase/messaging';
import { toast, ToastContent, ToastOptions } from 'react-toastify';
import { updateNotification } from '../db/notifications';

export const handleNotifications = (
  onClick: (data: { [x: string]: any }) => void,
  event: MessagePayload
) => {
  const { type, link, notificationRef } = event.data;
  const { body } = event.notification;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_u, userId, _x, notificationId] = notificationRef.split('/');

  let toastType: (
    content: ToastContent,
    options?: ToastOptions<{}>
  ) => React.ReactText;

  let handleClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault();
    updateNotification(userId, notificationId, { read: true });
    onClick({ type, link, notificationRef });
  };

  switch (type) {
    case 'leadAccepted':
    case 'leadAssigned':
    case 'orderAccepted':
      toastType = toast.success;
      break;
    case 'orderFulfillmentFailed':
      toastType = toast.error;
      break;
    default:
      toastType = toast.info;
  }

  toastType(body, {
    onClick: handleClick,
  });
};
