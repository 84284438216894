import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';
import { Outlet as OutletType } from '../../../../global';
import { useCheckAccess } from '../../lib/auth/use-checkAccess';
import clsx from 'clsx';
import { useMemo } from 'react';

type OutletProps = {
  outlet: OutletType;
};

const Outlet = ({ outlet }: OutletProps) => {
  const navigate = useNavigate();
  const checkAccess = useCheckAccess();

  const outletStatusMessage = useMemo(() => {
    const { status } = outlet;
    const { hasWhatsApp, actionRequired: retailerActionRequired } = outlet.retailer;

    if (checkAccess(['sop', 'fsm'])) {
      if (!['confirmed', 'changesRejected'].includes(status) || retailerActionRequired) {
        return 'Action required!';
      }
    }

    if (checkAccess(['fsa'])) {
      if (!['confirmed', 'changesRejected'].includes(status)) {
        return 'Waiting for approval!';
      }
      if(status == 'changesRejected') {
        return 'Action required!'
      }
    }

    if (!hasWhatsApp) {
      return 'No WhatsApp!';
    }

    return null;
  }, [outlet]); // eslint-disable-line react-hooks/exhaustive-deps

  const outletStatusColor = clsx([
    'flex justify-between mx-2 mb-2 h-18 list-none shadow-md rounded-lg pb-4 px-2 pt-2 cursor-pointer bg-white',
    outletStatusMessage !== null && 'border-2',
    outletStatusMessage === 'Action required!' && 'border-yellow-500',
    outletStatusMessage === 'Waiting for approval!' && 'border-yellow-500',
    outletStatusMessage === 'No WhatsApp!' && 'border-red-500',
  ]);

  return (
    <li
      className={outletStatusColor}
      onClick={() => {
        navigate(`/retailers/${outlet.retailer.id}?outletId=${outlet.id}`);
      }}
    >
      <div className="grid grid-cols-12 w-full">
        <div className="col-span-6">
          <p className="text-lh-head-black align-middle col-span-4 font-bold">
            <FontAwesomeIcon
              icon={faUserCircle}
              className="text-lh-head-black"
            />
            {` ${outlet.name}`}
          </p>
          <p className="mt-1 text-lh-head-black">{` ${outlet.retailer.phone}`}</p>
        </div>
        <p className="col-span-5 text-right self-center mr-2 text-lh-head-black font-semibold">
          {outletStatusMessage}
        </p>
        <p className="col-span-1 text-right self-center  text-lh-head-black font-bold mr-4">
          <FontAwesomeIcon
            icon={faAngleRight}
            className="text-lh-head-black font-bold align-middle"
          />
        </p>
      </div>
    </li>
  );
};

export default Outlet;
