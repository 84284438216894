import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faComment } from '@fortawesome/free-regular-svg-icons';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';
import { Lead as LeadType } from '../../../../global';

type LeadProps = {
  lead: LeadType;
};

const Lead = ({ lead }: LeadProps) => {
  const navigate = useNavigate();
  return (
    <li
      className={
        'flex justify-between mx-2 mb-2 h-18 list-none shadow-md rounded-lg pb-4 px-2 pt-2 cursor-pointer  bg-white ' +
        ((lead.status === 'pending' && lead.color === 'red') ||
        (lead.status === 'accepted' && lead.color === 'red')
          ? 'blead-lh-red blead-2'
          : lead.status === 'canceled'
          ? 'blead-lh-red blead-2'
          : lead.status === 'rejected'
          ? 'blead-yellow-500 blead-2'
          : lead.status === 'fulfilled'
          ? 'blead-green-500 blead-2'
          : 'blead-0')
      }
      onClick={() => {
        navigate(`/leads/${lead.id}`);
      }}
    >
      <div className="grid grid-cols-10 w-full">
        <div className="col-span-6">
          <p className="text-lh-head-black align-middle col-span-4 font-medium">
            <FontAwesomeIcon
              icon={lead.createdOn === 'chatbot' ? faComment : faUserCircle}
              className="text-lh-head-black"
            />{' '}
            {lead.site.name}
          </p>
          <p className="text-sm text-lh-text-black">
            {dayjs(lead.createdAt).format('DD.MM.YYYY HH:mm')}
          </p>
        </div>
        <p className="col-span-3 text-right self-center mt-1 text-lh-head-black font-semibold">
          {(lead.status === 'accepted' && lead.totalDelivered > 0) ||
          lead.status === 'fulfilled'
            ? lead.totalDelivered + ' / ' + lead.totalOrdered
            : lead.totalOrdered}{' '}
          <span className="text-gray-500 font-light">bags</span>
        </p>
        <p className="col-span-1 text-right self-center mt-1 text-lh-head-black font-bold mr-4">
          <FontAwesomeIcon
            icon={faAngleRight}
            className="text-lh-head-black font-bold align-middle"
          />
        </p>
      </div>
    </li>
  );
};

export default Lead;
