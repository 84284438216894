import {
  collection,
  count,
  getAggregateFromServer,
  query,
  where,
} from 'firebase/firestore';
import { firestore } from '../firebase';
import dayjs from 'dayjs';

export function calculateCementApp(data: any) {
  let volumeTotal = 0;

  data.map((item: any) => {
    const volume =
    (item.volumeFulfilled.app.classic ?? 0) +
    (item.volumeFulfilled.app.supaset ?? 0) +
    (item.volumeFulfilled.app.watershield ?? 0);

    volumeTotal += volume;
  });

  console.log('app', volumeTotal);

  return volumeTotal * 0.05;
}

export function calculateCementChatbot(data: any) {
  let volumeTotal = 0;

  data.map((item: any) => {
    const volume =
    (item.volumeFulfilled.chatbot.classic ?? 0) +
    (item.volumeFulfilled.chatbot.supaset ?? 0) +
    (item.volumeFulfilled.chatbot.watershield ?? 0);

    volumeTotal += volume;
  });

  console.log('chatbot', volumeTotal);

  return volumeTotal * 0.05;
}

export function calculateMortarApp(data: any) {
  let volumeTotal = 0;

  data.map((item: any) => {
    const volume =
    (item.volumeFulfilled.app.supafixc0 ?? 0) +
    (item.volumeFulfilled.app.supafixc1 ?? 0);

    volumeTotal += volume;
  });

  return volumeTotal * 0.02;
}

export function calculateMortarChatbot(data: any) {
  let volumeTotal = 0;

  data.map((item: any) => {
    const volume =
    (item.volumeFulfilled.chatbot.supafixc0 ?? 0) +
    (item.volumeFulfilled.chatbot.supafixc1 ?? 0);

    volumeTotal += volume;
  });

  return volumeTotal * 0.02;
}

export function calculateDirectSalesCement(data: any) {
  let volumeTotal = 0;

  data.map((item: any) => {
    if (item.directSalesFulfilled.classic) {
      volumeTotal += item.directSalesFulfilled.classic;
    }

    if (item.directSalesFulfilled.supaset) {
      volumeTotal += item.directSalesFulfilled.supaset;
    }

    if (item.directSalesFulfilled.watershield) {
      volumeTotal += item.directSalesFulfilled.watershield;
    }
  });

  return volumeTotal * 0.05;
}

export function calculateDirectSalesMortar(data: any) {
  let volumeTotal = 0;

  data.map((item: any) => {
    if (item.directSalesFulfilled.supafixc0) {
      volumeTotal += item.directSalesFulfilled.supafixc0;
    }

    if (item.directSalesFulfilled.supafixc1) {
      volumeTotal += item.directSalesFulfilled.supafixc1;
    }
  });

  return volumeTotal * 0.02;
}

export function calculateVisits(data: any) {
  let visitsTotal = 0;

  data.map((item: any) => {
    visitsTotal += item.numberOfVisits ?? 0;
  });

  return visitsTotal;
}

export function calculateLeadAmount(data: any) {
  let leadsTotal = 0;

  data.map((item: any) => {
    leadsTotal += item.numberOfLeads ?? 0;
  });

  return leadsTotal;
}

export function calculateLeadVolume(data: any) {
  let leadsTotal = 0;

  data.map((item: any) => {
    leadsTotal +=
    (
      (item.leadsFulfilled.classic ?? 0) +
      (item.leadsFulfilled.supaset ?? 0) +
      (item.leadsFulfilled.watershield ?? 0)
    ) * 0.05;

    leadsTotal +=
      (
        (item.leadsFulfilled.supafixc0 ?? 0) +
        (item.leadsFulfilled.supafixc1 ?? 0) 
      ) * 0.02;
  });

  return leadsTotal;
}

export function calculateSiteVisits(data: any) {
  let siteVisitsTotal = 0;

  data.map((item: any) => {
    siteVisitsTotal += item.numberOfSiteVisits;
  });

  return siteVisitsTotal;
}

export const getRealtimeOrderData = async (
  user: any,
  setPending: any,
  setAcceptedOrders: any,
  setFulfilled: any,
  setRejectedOrders: any
) => {
  const coll = collection(firestore, 'orders');

  const q0 = query(
    coll,
    where('userIds', 'array-contains', user.id),
    where('status', '==', 'pending')
  );

  const snapshot0 = await getAggregateFromServer(q0, {
    totalOrdersPending: count(),
  });

  setPending(snapshot0.data().totalOrdersPending);

  const q = query(
    coll,
    where('userIds', 'array-contains', user.id),
    where('status', '==', 'accepted')
  );
  const snapshot = await getAggregateFromServer(q, {
    totalOrdersAccepted: count(),
  });

  setAcceptedOrders(snapshot.data().totalOrdersAccepted);

  const q2 = query(
    coll,
    where('userIds', 'array-contains', user.id),
    where('status', '==', 'fulfilled'),
    where('statusHistory.fulfilled', '>=', dayjs().startOf('month').toDate()),
    where('statusHistory.fulfilled', '<=', dayjs().endOf('month').toDate())
  );

  const snapshot2 = await getAggregateFromServer(q2, {
    totalOrdersFulfilled: count(),
  });

  setFulfilled(snapshot2.data().totalOrdersFulfilled);

  const q3 = query(
    coll,
    where('userIds', 'array-contains', user.id),
    where('status', '==', 'rejected'),
    where('statusHistory.rejected', '>=', dayjs().startOf('month').toDate()),
    where('statusHistory.rejected', '<=', dayjs().endOf('month').toDate())
  );

  const snapshot3 = await getAggregateFromServer(q3, {
    totalOrdersRejected: count(),
  });

  setRejectedOrders(snapshot3.data().totalOrdersRejected);
};

export const getRealtimeLeadsData = async (
  user: any,
  setPending: any,
  setFulfilled: any
) => {
  const coll = collection(firestore, 'leads');

  const q0 = query(
    coll,
    where('userIds', 'array-contains', user.id),
    where('status', '==', 'pending')
  );

  const snapshot1 = await getAggregateFromServer(q0, {
    totalOrdersPending: count(),
  });

  setPending(snapshot1.data().totalOrdersPending);

  const q2 = query(
    coll,
    where('userIds', 'array-contains', user.id),
    where('status', '==', 'fulfilled'),
    where('statusHistory.fulfilled', '>=', dayjs().startOf('month').toDate()),
    where('statusHistory.fulfilled', '<=', dayjs().endOf('month').toDate())
  );

  const snapshot2 = await getAggregateFromServer(q2, {
    totalLeadsFulfilled: count(),
  });

  setFulfilled(snapshot2.data().totalLeadsFulfilled);
};
