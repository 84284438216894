import { collection, doc, writeBatch, updateDoc } from 'firebase/firestore';
import { firestore } from '../utils/firebase';
import { UpdateTargetData } from '../../../../global';

export const updateTarget = async (
  id: string,
  data: UpdateTargetData
): Promise<void> => {
  const ref = doc(collection(firestore, 'targets'), id);
  await updateDoc(ref, data);
};

export const lockTargets = async (targetIds: string[]): Promise<void> => {
  const batch = writeBatch(firestore);
  for (const targetId of targetIds) {
    const targetRef = doc(firestore, `targets`, targetId);
    batch.update(targetRef, { locked: true });
  }
  await batch.commit();
};

export const lockTargetSOP = async (
  targetId: string,
  targetType: string
): Promise<void> => {
  const ref = doc(firestore, 'targets', targetId);

  if (targetType === 'fsa') {
    await updateDoc(ref, {
      lockedFSA: true,
    });
  } else {
    await updateDoc(ref, {
      lockedDistributor: true,
    });
  }
};
