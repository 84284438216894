import Loader from '../../components/ui/Loader';
import React, { useEffect, useMemo, useState } from 'react';
import useStore from '../../lib/hooks/use-store';
import Content from '../../components/layout/Content';
import TopBar from '../../components/layout/TopBar';
import ButtonBottomRight from '../../components/ui/ButtonBottomRight';
import List from '../../components/ui/list/List';
import Alert from '../../components/ui/Alert';
import { useCheckAccess } from '../../lib/auth/use-checkAccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faSync,
} from '@fortawesome/free-solid-svg-icons';
import { useSales } from '../../lib/hooks/use-sales';
import Sale from '../../components/sales/Sale';
import New from '../../components/ui/New';
import clsx from 'clsx';
import { some } from '../../lib/utils/helpers';

const TOP_BAR_ITEM =
  'flex justify-center content-center flex-col text-center flex-grow cursor-pointer text-lh-dark-blue text-xl font-medium border-b-2 border-lh-dark-blue h-full';
const TOP_BAR_ITEM_ACTIVE = 'border-b-4 border-lh-dark-blue font-semibold';

const Sales = React.memo(() => {
  const checkAccess = useCheckAccess();
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [state, dispatch] = useStore();
  const { error, sales, loading, load, hasMore, reset } = useSales({
    status: state.saleStatus,
    direction,
  });
  const [showAlert, setShowAlert] = useState(false);

  const pendingCSS = useMemo(
    () =>
      clsx(
        TOP_BAR_ITEM,
        some(state.saleStatus, ['pending', 'accepted']) && TOP_BAR_ITEM_ACTIVE
      ),
    [state.saleStatus]
  );

  const archiveCSS = useMemo(
    () =>
      clsx(
        TOP_BAR_ITEM,
        some(state.saleStatus, ['fulfilled', 'rejected']) && TOP_BAR_ITEM_ACTIVE
      ),
    [state.saleStatus]
  );

  useEffect(() => {
    if (error) {
      setShowAlert(true);
    } else if (showAlert) {
      setShowAlert(false);
    }
    return () => {
      setShowAlert(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (
      state.saleStatus.includes('fulfilled') ||
      state.saleStatus.includes('rejected')
    ) {
      setDirection('desc');
    } else {
      setDirection('asc');
    }
  }, [state.saleStatus]);

  const handleDirectionChange = () => {
    if (direction === 'asc') {
      setDirection('desc');
    } else {
      setDirection('asc');
    }
  };

  const handleStatusChange = (newStatus: string[]) => {
    dispatch('SET_SALE_STATUS', newStatus);
  };

  return (
    <Content topBar>
      <ButtonBottomRight
        onClick={reset}
        blocked={loading}
        arialabel="reload"
        icon={faSync}
      />
      <button
        onClick={handleDirectionChange}
        className="rounded-full flex justify-center items-center h-8 w-8 fixed top-5 right-20 text-xl z-30"
      >
        <FontAwesomeIcon
          icon={direction === 'asc' ? faAngleDoubleUp : faAngleDoubleDown}
        />
      </button>
      <Loader show={loading && sales.length === 0} />
      <Alert
        message={error && error.message}
        open={showAlert}
        setOpen={(open) => setShowAlert(open)}
        title="Error"
      />
      <TopBar>
        <div
          className={pendingCSS}
          onClick={() => handleStatusChange(['pending', 'accepted'])}
        >
          Open
        </div>
        <div
          className={archiveCSS}
          onClick={() => handleStatusChange(['fulfilled', 'rejected'])}
        >
          Archive
        </div>
      </TopBar>
      <List load={load} hasMore={hasMore} loading={loading}>
        {sales && sales.length > 0 ? (
          sales.map((sale) => <Sale sale={sale} key={sale.id} />)
        ) : (
          <p className="justify-between mx-2 mb-2 h-18 list-none shadow-md rounded-lg pb-4 px-2 pt-2 bg-white text-center">
            No direct sales yet.
          </p>
        )}
      </List>
      {checkAccess(['sop', 'distributor']) && <New to="new" />}
    </Content>
  );
});

export default Sales;
