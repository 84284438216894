import { useEffect, useRef } from 'react';
import { useMap } from '../../lib/hooks/use-map';
import { Location } from '../../../../global';
import { transform } from 'ol/proj';

interface MapProps {
  children: React.ReactNode;
  center?: Location;
  zoom?: number;
  className?: string;
}

const Map = ({ children, center, zoom = 16, className }: MapProps) => {
  const mapRef = useRef();
  const { map } = useMap();

  useEffect(() => {
    if (!map) return;

    map.setTarget(mapRef.current);

    return () => {
      map.setTarget(undefined);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!map || !center) return;

    const centerCoords = transform(
      [center.lng, center.lat],
      'EPSG:4326',
      'EPSG:3857'
    );

    map.getView().setCenter(centerCoords);
  }, [center]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!map) return;
    map.getView().setZoom(zoom);
  }, [zoom]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div ref={mapRef} className={className}>
      {children}
    </div>
  );
};

export default Map;
