import { forwardRef, useEffect, useImperativeHandle } from 'react';
import MutliSelect from 'react-select';
import { RegisterOptions, useForm, useWatch } from 'react-hook-form';
import Input from '../../../components/forms/Input';
import { useState } from 'react';
import Select from '../../../components/forms/Select';

const CEMENT_TYPES = [
  {
    key: 'comp2_42n',
    label: 'Comp 2 (42.5N)',
    value: 'comp2_42n',
  },
  {
    key: 'comp1_32r',
    label: 'Comp 1 (32.5R)',
    value: 'comp1_32r',
  },
  {
    key: 'comp1_42n',
    label: 'Comp 1 (42.5N)',
    value: 'comp1_42n',
  },
  {
    key: 'comp1_42r',
    label: 'Comp 1 (42.5R)',
    value: 'comp1_42r',
  },
  {
    key: 'comp3_32n',
    label: 'Comp 3 (32.5N)',
    value: 'comp3_32n',
  },
  {
    key: 'elephant_classic_32n',
    label: 'Elephant Classic (32.5N)',
    value: 'elephant_classic_32n',
  },
  {
    key: 'ecoplanet_32r',
    label: 'ECOPlanet UNICEM  (32.5R)',
    value: 'ecoplanet_32r',
  },
  {
    key: 'ashcem_32n',
    label: 'ASHCEM (32.5N)',
    value: 'ashcem_32n',
  },
  {
    key: 'supaset_42n',
    label: 'Supaset 42.5',
    value: 'supaset_42n',
  },
  {
    key: 'powermax_42n',
    label: 'Powermax 42.5N',
    value: 'powermax_42n',
  },
  {
    key: 'watershield_42n',
    label: 'Watershield 42.5N',
    value: 'watershield_42n',
  },
  {
    key: 'others',
    label: 'Others (specify)',
    value: 'others',
  },
  {
    key: 'project_not_started',
    label: 'Project had not gotten to the stage',
    value: 'project_not_started',
  },
];

const REASON_FOR_NOT_USING = [
  {
    key: 'unavailability',
    label: 'Unavailability',
    value: 'unavailability',
  },
  {
    key: 'proximity_of_purchase',
    label: 'Proximity of purchase',
    value: 'proximity_of_purchase',
  },
  {
    key: 'price',
    label: 'Price',
    value: 'price',
  },
  {
    key: 'brand_name',
    label: 'Brand name',
    value: 'brand_name',
  },
  {
    key: 'credit_facility',
    label: 'Credit facility',
    value: 'credit_facility',
  },
  {
    key: 'colour',
    label: 'Colour',
    value: 'colour',
  },
  {
    key: 'others',
    label: 'Others (specify)',
    value: 'others',
  },
];

type Props = {
  editable: boolean;
  setProductTypes: any;
  setProductTypesIsDirty: any;
  setProductTypesIsValid: any;
  productTypes: {
    sandcreteCementTypesSelected: string;
    cementTypeSandcreteOther: string;
    concreteCementTypesSelected: string;
    cementTypeConcreteOther: string;
    plasterCementTypesSelected: string;
    cementTypePlasteringOther: string;
    reasonWhyNotUsed: string;
    reasonWhyNotUsedOther: string;
    tileAdhesiveIntent: string;
    nextVisitDate: string;
    feedback: string;
  };
};

const SiteVisitProductTypes = forwardRef((props: Props, ref) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: { ...props.productTypes },
    mode: 'all',
  });

  // transform multi select values to object
  const sandcreteCementTypesSelectedData = props.productTypes && props.productTypes.sandcreteCementTypesSelected?.split(',').map((key: string) => {
    return CEMENT_TYPES.find((obj) => obj.key === key);
  });

  const concreteCementTypesSelectedData = props.productTypes && props.productTypes.concreteCementTypesSelected?.split(',').map((key: string) => {
    return CEMENT_TYPES.find((obj) => obj.key === key);
  });

  const plasterCementTypesSelectedData = props.productTypes && props.productTypes.plasterCementTypesSelected?.split(',').map((key: string) => {
    return CEMENT_TYPES.find((obj) => obj.key === key);
  });

  const [sandcreteCementTypesSelected, setSandcreteCementTypesSelected] = useState<any>(sandcreteCementTypesSelectedData ?? null);
  const cementTypeSandcreteOther = useWatch({
    control,
    name: 'cementTypeSandcreteOther',
  });

  const [concreteCementTypesSelected, setConcreteCementTypesSelected] = useState<any>(concreteCementTypesSelectedData ?? null);
  const cementTypeConcreteOther = useWatch({
    control,
    name: 'cementTypeConcreteOther',
  });

  const [plasterCementTypesSelected, setPlasterCementTypesSelected] = useState<any>(plasterCementTypesSelectedData ?? null);
  const cementTypePlasteringOther = useWatch({
    control,
    name: 'cementTypePlasteringOther',
  });

  const reasonWhyNotUsed = useWatch({
    control,
    name: 'reasonWhyNotUsed',
  });
  const reasonWhyNotUsedOther = useWatch({
    control,
    name: 'reasonWhyNotUsedOther',
  });

  const tileAdhesiveIntent = useWatch({
    control,
    name: 'tileAdhesiveIntent',
  });

  const nextVisitDate = useWatch({
    control,
    name: 'nextVisitDate',
  });

  const feedback = useWatch({
    control,
    name: 'feedback',
  });

  const cementTypeSandcreteOptions: RegisterOptions = {
    required: 'Please select a type!',
  };
  const cementTypeSandcreteOtherOptions: RegisterOptions = {
    required: 'Please specify!',
  };

  const cementTypeConcreteOptions: RegisterOptions = {
    required: 'Please select a type!',
  };
  const cementTypeConcreteOtherOptions: RegisterOptions = {
    required: 'Please specify!',
  };

  const cementTypePlasteringOptions: RegisterOptions = {
    required: 'Please select a type!',
  };
  const cementTypePlasteringOtherOptions: RegisterOptions = {
    required: 'Please specify!',
  };  

  const reasonWhyNotUsedOptions: RegisterOptions = {
    required: 'Please select a reason!',
  };
  const reasonWhyNotUsedOtherOptions: RegisterOptions = {
    required: 'Please specify!',
  };

  const tileAdhesiveIntentOptions: RegisterOptions = {
    required: 'Please select an intent!',
  };

  const nextVisitDateOptions: RegisterOptions = {
    required: 'Please select a date!',
  };

  const feedbackOptions: RegisterOptions = {
    required: 'Please provide a feedback!',
  };

  useEffect(() => {
    props.setProductTypesIsDirty(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  useEffect(() => {
   // Set the product types is valid if all the required fields are filled and make sure if the other option is selected, the other field is filled
   if(
    ((sandcreteCementTypesSelected && sandcreteCementTypesSelected.length > 0 && !(sandcreteCementTypesSelected.some(obj => obj.key === 'others'))) ||
      (sandcreteCementTypesSelected && sandcreteCementTypesSelected.length > 0 && sandcreteCementTypesSelected.some(obj => obj.key === 'others') && cementTypeSandcreteOther)) &&
      ((concreteCementTypesSelected && concreteCementTypesSelected.length > 0 && !(concreteCementTypesSelected.some(obj => obj.key === 'others'))) ||
      (concreteCementTypesSelected && concreteCementTypesSelected.length > 0 && concreteCementTypesSelected.some(obj => obj.key === 'others') && cementTypeConcreteOther)) &&
      ((plasterCementTypesSelected && plasterCementTypesSelected.length > 0 && !(plasterCementTypesSelected.some(obj => obj.key === 'others'))) ||
      (plasterCementTypesSelected && plasterCementTypesSelected.length > 0 && plasterCementTypesSelected.some(obj => obj.key === 'others') && cementTypePlasteringOther)) && (
      ((reasonWhyNotUsed && reasonWhyNotUsed !== 'placeholder' && reasonWhyNotUsed !== 'others') ||
      (reasonWhyNotUsed === 'others' && reasonWhyNotUsedOther)) &&
      tileAdhesiveIntent &&
      nextVisitDate &&
      feedback
      )
   ) {
    props.setProductTypesIsValid(true);
   } else {
    props.setProductTypesIsValid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sandcreteCementTypesSelected, concreteCementTypesSelected, plasterCementTypesSelected, reasonWhyNotUsed, tileAdhesiveIntent, nextVisitDate, feedback, cementTypeSandcreteOther, cementTypeConcreteOther, cementTypePlasteringOther, reasonWhyNotUsedOther]);

  useImperativeHandle(ref, () => ({
    saveProductTypes() {
      dosSaveProductTypes();
    },
  }));

  const dosSaveProductTypes = () => {
    // get values from form and state
    const values = {
      sandcreteCementTypesSelected: sandcreteCementTypesSelected ? sandcreteCementTypesSelected.map((obj: any) => obj.key).join(',') : null, // transform multi select values to string with delimiter
      cementTypeSandcreteOther: cementTypeSandcreteOther ?? null,
      concreteCementTypesSelected: concreteCementTypesSelected ? concreteCementTypesSelected.map((obj: any) => obj.key).join(',') : null, // transform multi select values to string with delimiter
      cementTypeConcreteOther: cementTypeConcreteOther ?? null,
      plasterCementTypesSelected: plasterCementTypesSelected ? plasterCementTypesSelected.map((obj: any) => obj.key).join(',') : null, // transform multi select values to string with delimiter
      cementTypePlasteringOther: cementTypePlasteringOther ?? null,
      reasonWhyNotUsed,
      reasonWhyNotUsedOther: reasonWhyNotUsedOther ?? null,
      tileAdhesiveIntent,
      nextVisitDate,
      feedback,
    };

    // set the product types
    props.setProductTypes(values);
    
    // handleSubmit(props.setProductTypes)();
  };

  return (
    <>
      <div className="grid grid-cols-9 col-span-9 ">
        <div className="grid grid-cols-12 col-span-9 content-center ">
          <label
            htmlFor="cementTypeSandcrete"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            Cement type used for Sandcrete Block
          </label>
          <MutliSelect
            options={ CEMENT_TYPES }
            placeholder={'Select cement types...'}
            isMulti={true}
            onChange={setSandcreteCementTypesSelected}
            value={sandcreteCementTypesSelected}
            className="col-span-12 mb-2"
          />
          {sandcreteCementTypesSelected && sandcreteCementTypesSelected.some(obj => obj.key === 'others') && (
            <Input
              register={register}
              name="cementTypeSandcreteOther"
              error={errors.cementTypeSandcreteOther}
              options={cementTypeSandcreteOtherOptions}
              placeholder="Please specify..."
              className="col-span-12"
            />
          )}
          <label
            htmlFor="cementTypeConcrete"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            Cement type used for Concrete Works
          </label>
          <MutliSelect
            options={ CEMENT_TYPES }
            placeholder={'Select cement types...'}
            isMulti={true}
            onChange={setConcreteCementTypesSelected}
            value={concreteCementTypesSelected}
            className="col-span-12 mb-2"
          />
          {concreteCementTypesSelected && concreteCementTypesSelected.some(obj => obj.key === 'others') && (
            <Input
              register={register}
              name={'cementTypeConcreteOther'}
              error={errors.cementTypeConcreteOther}
              options={cementTypeConcreteOtherOptions}
              placeholder="Please specify..."
              className="col-span-12"
            />
          )}
          <label
            htmlFor="cementTypePlastering"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            Cement type used for Plaster / Rendering
          </label>
          <MutliSelect
            options={ CEMENT_TYPES }
            placeholder={'Select cement types...'}
            isMulti={true}
            onChange={setPlasterCementTypesSelected}
            value={plasterCementTypesSelected}
            className="col-span-12 mb-2"
          />
           {plasterCementTypesSelected && plasterCementTypesSelected.some(obj => obj.key === 'others') && (
            <Input
              register={register}
              name="cementTypePlasteringOther"
              error={errors.cementTypePlasteringOther}
              options={cementTypePlasteringOtherOptions}
              placeholder="Please specify..."
              className="col-span-12"
            />
          )}
          <label
            htmlFor="reasonWhyNotUsed"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            Major reason why Lafarge product in not been used?
          </label>
          <Select
            register={register}
            name="reasonWhyNotUsed"
            items={REASON_FOR_NOT_USING}
            placeholder="Select reason..."
            error={errors.reasonWhyNotUsed}
            options={reasonWhyNotUsedOptions}
            className="col-span-12"
          />
          {reasonWhyNotUsed === 'others' && (
            <Input
              register={register}
              name="reasonWhyNotUsedOther"
              error={errors.reasonWhyNotUsedOther}
              options={reasonWhyNotUsedOtherOptions}
              placeholder="Please specify..."
              className="col-span-12"
            />
          )}
          <label
            htmlFor="tileAdhesiveIntent"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
           What Tile Adhesive do they intend to use or had used?
          </label>
          <Input
            register={register}
            name="tileAdhesiveIntent"
            error={errors.tileAdhesiveIntent}
            options={tileAdhesiveIntentOptions}
            placeholder="Please specify..."
            className="col-span-12"
          />
          <label
            htmlFor="nextVisitDate"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            What was the agreed date of next visit to site?
          </label>
          <Input
            register={register}
            name="nextVisitDate"
            error={errors.nextVisitDate}
            options={nextVisitDateOptions}
            placeholder="Please specify..."
            className="col-span-12"
            type='date'
          />
          <label
            htmlFor="feedback"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            What was the Site Representative feedback about your visit?
          </label>
          <Input
            register={register}
            name="feedback"
            error={errors.feedback}
            options={feedbackOptions}
            placeholder="Please specify..."
            className="col-span-12"
          />
        </div>
      </div>
    </>
  );
});

export default SiteVisitProductTypes;
