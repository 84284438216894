import { initStore } from '../hooks/use-store';

type RetailerStatus = 'numberWrong' | 'noWhatsApp' | 'enabledWhatsApp' | 'all';

const configureRetailerStatusStore = () => {
  const actions = {
    SET_RETAILER_STATUS: (curRetailerState, retailerStatus: RetailerStatus) => {
      return { retailerStatus };
    },
  };
  initStore(actions, { retailerStatus: 'all' });
};

export default configureRetailerStatusStore;
