type CalendarDayProps = {
  dayObject: {
    routePlan: {
      route: Object;
    };
  };
  day: number;
  month: number;
  year: number;
  style: string;
  setSelectedDay: Function;
  changeMonthAndSelect: Function;
  selectedDay: {
    day: number;
    month: number;
    year: number;
    routePlan: Object;
  };
  routeName: string;
};

const CalendarDay = ({
  dayObject,
  day,
  month,
  year,
  style,
  setSelectedDay,
  changeMonthAndSelect,
  selectedDay,
  routeName,
}: CalendarDayProps) => {
  return (
    <div
      className={
        'col-span-1 text-center pb-3 cursor-pointer ' +
        (style === 'month'
          ? ''
          : style === 'month_past'
          ? 'bg-gray-300 '
          : 'bg-gray-200 ') +
        (selectedDay &&
        day === selectedDay.day &&
        month === selectedDay.month &&
        year === selectedDay.year
          ? 'border-2 rounded-full border-blue-600 text-center '
          : ' text-center')
      }
      onClick={() =>
        style === 'month'
          ? setSelectedDay(dayObject)
          : style === 'month_past'
          ? setSelectedDay(dayObject)
          : style === 'before'
          ? changeMonthAndSelect('substract', dayObject)
          : changeMonthAndSelect('add', dayObject)
      }
    >
      <p
        className={
          dayObject.routePlan && dayObject.routePlan.route
            ? ' before:-translate-x-1/2 before:bg-blue-600 before:w-2 before:h-2 before:content-[""] before:relative before:left-1/2  before:top-[30px] before:block md:before:hidden before:rounded-full '
            : ''
        }
      >
        {day}
      </p>
      <p className="hidden md:block">{routeName}</p>
    </div>
  );
};

export default CalendarDay;
