import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Layout from './components/layout/Layout';
import Protected from './components/routing/Protected';
import { AuthProvider } from './lib/context/auth.context';
import Home from './pages/home';
import Enter from './pages/enter';
import GoogleSignIn from './pages/enter/google';
import PhoneSignIn from './pages/enter/phone';
import Orders from './pages/orders';
import NewOrder from './pages/orders/new';
import Order from './pages/orders/[orderId]';
import EditOrder from './pages/orders/[orderId]/edit';
import Retailers from './pages/retailers';
import Retailer from './pages/retailers/[retailerId]';
import Targets from './pages/targets';
import Target from './pages/targets/[targetId]';
import ErrorBoundary from './components/error/ErrorBoundary';
import Profile from './pages/profile';
import Denied from './pages/denied';
import Inventories from './pages/inventory';
import RoutePage from './pages/routes/[routeId]';
import RoutesPage from './pages/routes';
import Visits from './pages/visits';
import Visit from './pages/visits/[visitsId]';
import RoutesPlanningPage from './pages/route_planning';
import NewRoute from './pages/routes/new';
import Users from './pages/users';
import { User } from './pages/users/[userId]';
import NewRetailer from './pages/retailers/new';
import Content from './components/layout/Content';
import Card from './components/ui/Card';
import EditRetailer from './pages/retailers/[retailerId]/edit';
import Sales from './pages/sales';
import Sale from './pages/sales/[saleId]';
import EditSale from './pages/sales/[saleId]/edit';
import NewSale from './pages/sales/new';
import { EditOutlet } from './pages/outlets/[outletId]/edit';
import { ToastContainer } from 'react-toastify';
import Notifications from './pages/notifications';
import EditRoutePage from './pages/routes/[routeId]/edit';
import Deliveries from './pages/deliveries';
import Delivery from './pages/deliveries/[delivery]';
import Sites from './pages/sites';
import NewSite from './pages/sites/new';
import SiteVisit from './pages/siteVisits/[visitsId]';
import SiteVisits from './pages/siteVisits';
import Leads from './pages/leads';
import Lead from './pages/leads/[leadId]';
import EditSiteOwner from './pages/siteOwners/[siteOwnerId]/edit';
import { EditSite } from './pages/sites/[siteId]/edit';
import Inventory from './pages/inventory/[inventoryId]';
import { MapProvider } from './lib/context/map.context';
import 'react-toastify/dist/ReactToastify.css';
import Site from './pages/sites/[siteId]';
import CoPilot from './pages/copilot';

const App = () => {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <AuthProvider>
          <MapProvider>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Navigate to="home" />} />
                {/* Enter */}
                <Route path="enter" element={<Enter />} />
                <Route path="enter/google" element={<GoogleSignIn />} />
                <Route path="enter/phone" element={<PhoneSignIn />} />
                {/* Home */}
                <Route
                  path="home"
                  element={
                    <Protected>
                      <Home />
                    </Protected>
                  }
                />
                {/* Orders */}
                <Route
                  path="orders"
                  element={
                    <Protected roles={['sop', 'fsa', 'distributor', 'fsm']}>
                      <Orders />
                    </Protected>
                  }
                />
                <Route
                  path="orders/:id"
                  element={
                    <Protected roles={['sop', 'fsa', 'distributor', 'fsm']}>
                      <Order />
                    </Protected>
                  }
                />
                <Route
                  path="orders/:id/edit"
                  element={
                    <Protected roles={['sop', 'fsa', 'fsm']}>
                      <EditOrder />
                    </Protected>
                  }
                />
                <Route
                  path="orders/new"
                  element={
                    <Protected roles={['fsa', 'sop']}>
                      <NewOrder />
                    </Protected>
                  }
                />
                {/* Sales */}
                <Route
                  path="sales"
                  element={
                    <Protected roles={['distributor', 'sop']}>
                      <Sales />
                    </Protected>
                  }
                />
                <Route
                  path="sales/:id"
                  element={
                    <Protected roles={['distributor', 'sop']}>
                      <Sale />
                    </Protected>
                  }
                />
                <Route
                  path="sales/:id/edit"
                  element={
                    <Protected roles={['distributor', 'sop']}>
                      <EditSale />
                    </Protected>
                  }
                />
                <Route
                  path="sales/new"
                  element={
                    <Protected roles={['distributor', 'sop']}>
                      <NewSale />
                    </Protected>
                  }
                />
                {/* Retailers */}
                <Route
                  path="retailers"
                  element={
                    <Protected roles={['sop', 'fsa', 'distributor', 'fsm']}>
                      <Retailers />
                    </Protected>
                  }
                />
                <Route
                  path="retailers/new"
                  element={
                    <Protected roles={['sop', 'fsa', 'fsm']}>
                      <NewRetailer />
                    </Protected>
                  }
                />
                <Route
                  path="retailers/:id"
                  element={
                    <Protected roles={['sop', 'fsa', 'distributor', 'fsm']}>
                      <Retailer />
                    </Protected>
                  }
                />
                <Route
                  path="retailers/:id/edit"
                  element={
                    <Protected roles={['sop', 'fsa', 'fsm']}>
                      <EditRetailer />
                    </Protected>
                  }
                />
                <Route
                  path="outlets/:id/edit"
                  element={
                    <Protected roles={['sop', 'fsa', 'fsm']}>
                      <EditOutlet />
                    </Protected>
                  }
                />
                {/* Visits */}
                <Route
                  path="visits"
                  element={
                    <Protected roles={['fsa']}>
                      <Visits />
                    </Protected>
                  }
                />
                <Route
                  path="visits/:id"
                  element={
                    <Protected roles={['fsa']}>
                      <Visit />
                    </Protected>
                  }
                />
                {/* Routes */}
                <Route
                  path="routes"
                  element={
                    <Protected roles={['sop', 'fsa', 'fsm']}>
                      <RoutesPage />
                    </Protected>
                  }
                />
                <Route
                  path="routes/:id"
                  element={
                    <Protected roles={['sop', 'fsa', 'fsm']}>
                      <RoutePage />
                    </Protected>
                  }
                />
                <Route
                  path="routes/:id/edit"
                  element={
                    <Protected roles={['sop', 'fsa', 'fsm']}>
                      <EditRoutePage />
                    </Protected>
                  }
                />
                <Route
                  path="routes/new/:id"
                  element={
                    <Protected roles={['sop', 'fsa', 'fsm']}>
                      <NewRoute />
                    </Protected>
                  }
                />
                {/* Routes planning */}
                <Route
                  path="routes-planning"
                  element={
                    <Protected roles={['sop', 'fsm']}>
                      <RoutesPlanningPage />
                    </Protected>
                  }
                />
                {/* Users */}
                <Route
                  path="users"
                  element={
                    <Protected roles={['sop', 'fsa', 'fsm']}>
                      <Users />
                    </Protected>
                  }
                />
                <Route
                  path="users/:id"
                  element={
                    <Protected roles={['sop', 'fsa', 'fsm']}>
                      <User />
                    </Protected>
                  }
                />
                {/* Targets */}
                <Route
                  path="targets"
                  element={
                    <Protected roles={['sop', 'fsm']}>
                      <Targets />
                    </Protected>
                  }
                />
                <Route
                  path="targets/:id"
                  element={
                    <Protected roles={['sop', 'fsm']}>
                      <Target />
                    </Protected>
                  }
                />
                {/* Inventories */}
                <Route
                  path="inventories"
                  element={
                    <Protected roles={['sop', 'distributor', 'fsm']}>
                      <Inventories />
                    </Protected>
                  }
                />
                <Route
                  path="inventories/:id"
                  element={
                    <Protected roles={['sop', 'distributor', 'fsm']}>
                      <Inventory />
                    </Protected>
                  }
                />
                {/* Routes */}
                <Route
                  path="routes"
                  element={
                    <Protected roles={['sop', 'fsa', 'fsm']}>
                      <RoutesPage />
                    </Protected>
                  }
                />
                <Route
                  path="routes/:id"
                  element={
                    <Protected roles={['sop', 'fsa', 'fsm']}>
                      <RoutePage />
                    </Protected>
                  }
                />

                {/* Visits */}
                <Route
                  path="visits"
                  element={
                    <Protected roles={['fsa']}>
                      <Visits />
                    </Protected>
                  }
                />
                <Route
                  path="visits/:id"
                  element={
                    <Protected roles={['fsa']}>
                      <Visit />
                    </Protected>
                  }
                />

                {/* Deliveries */}
                <Route
                  path="deliveries"
                  element={
                    <Protected roles={['sop', 'distributor', 'fsm']}>
                      <Deliveries />
                    </Protected>
                  }
                />
                <Route
                  path="deliveries/:id"
                  element={
                    <Protected roles={['sop', 'distributor', 'fsm']}>
                      <Delivery />
                    </Protected>
                  }
                />
                {/* Profile */}
                <Route
                  path="profile"
                  element={
                    <Protected
                      roles={['sop', 'fsa', 'distributor', 'tae', 'fsm']}
                    >
                      <Profile />
                    </Protected>
                  }
                />
                {/* Notifications */}
                <Route
                  path="notifications"
                  element={
                    <Protected>
                      <Notifications />
                    </Protected>
                  }
                />
                {/* Sites */}
                <Route
                  path="sites"
                  element={
                    <Protected roles={['tae', 'sop', 'fsm']}>
                      <Sites />
                    </Protected>
                  }
                />
                <Route
                  path="sites/new"
                  element={
                    <Protected roles={['tae', 'sop', 'fsm']}>
                      <NewSite />
                    </Protected>
                  }
                />
                <Route
                  path="sites/:id"
                  element={
                    <Protected roles={['tae', 'sop', 'fsm']}>
                      <Site />
                    </Protected>
                  }
                />
                <Route
                  path="siteOwners/:id/edit"
                  element={
                    <Protected roles={['tae', 'sop', 'fsm']}>
                      <EditSiteOwner />
                    </Protected>
                  }
                />
                <Route
                  path="sites/:id/edit"
                  element={
                    <Protected roles={['tae', 'sop', 'fsm']}>
                      <EditSite />
                    </Protected>
                  }
                />
                {/* Site Visits */}
                <Route
                  path="siteVisits"
                  element={
                    <Protected roles={['tae']}>
                      <SiteVisits />
                    </Protected>
                  }
                />
                <Route
                  path="siteVisits/:id"
                  element={
                    <Protected roles={['tae']}>
                      <SiteVisit />
                    </Protected>
                  }
                />
                {/* Leads */}
                <Route
                  path="leads"
                  element={
                    <Protected
                      roles={['sop', 'fsa', 'distributor', 'tae', 'fsm']}
                    >
                      <Leads />
                    </Protected>
                  }
                />
                <Route
                  path="leads/:id"
                  element={
                    <Protected
                      roles={['sop', 'fsa', 'distributor', 'tae', 'fsm']}
                    >
                      <Lead />
                    </Protected>
                  }
                />
                <Route
                  path="copilot"
                  element={
                    <Protected
                      roles={['sop', 'fsa', 'distributor', 'tae', 'fsm']}
                    >
                      <CoPilot />
                    </Protected>
                  }
                />

                {/* Fallback */}
                <Route path="/denied" element={<Denied />} />
                <Route
                  path="*"
                  element={
                    <Content>
                      <Card className="flex justify-center">
                        Page not found!
                      </Card>
                    </Content>
                  }
                />
              </Route>
            </Routes>
            <ToastContainer position="top-center" limit={3} />
          </MapProvider>
        </AuthProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
};

export default App;
