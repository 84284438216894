import { useRef } from 'react';
import ReactDOM from 'react-dom';
import { useOnClickOutside } from '../../lib/hooks/use-onclickoutside';
import Button from '../ui/Button';

type ConfirmTonsProps = {
  open: boolean;
  title?: string;
  confirmText?: string;
  setOpen: (boolean) => void;
  onConfirm: () => void;
};

export const ConfirmTons = ({
  open,
  title = 'Are you sure?',
  confirmText = 'Confirm',
  setOpen,
  onConfirm,
}: ConfirmTonsProps) => {
  const node = useRef<HTMLDivElement>(null);

  useOnClickOutside(node, () => setOpen(false));

  return open
    ? ReactDOM.createPortal(
        <div
          className="fixed z-50 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div
              ref={node}
              className="inline-block max-w-xs bg-white rounded-3xl px-6 py-4 text-left overflow-hidden shadow-xl transform transition-all"
            >
              <h1 className="mb-4 text-lh-head-black text-lg">{title}</h1>
              <div className="flex justify-between">
                <Button
                  onClick={onConfirm}
                  text={confirmText}
                  color="green"
                  className="flex-grow mr-1"
                />
              </div>
            </div>
          </div>
        </div>,
        document.body
      )
    : null;
};
