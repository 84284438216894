type TopBarProps = {
  children?: React.ReactNode;
  className?: string;
};

const TopBar = ({ children, className }: TopBarProps) => {
  return (
    <div
      key="topBar"
      className={
        'flex justify-center fixed w-full top-20 h-20 z-20 bg-white ' +
        className
      }
    >
      {children}
    </div>
  );
};

export default TopBar;
