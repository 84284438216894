import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { To, useNavigate } from 'react-router-dom';

type EditProps = {
  className?: string;
  to?: To;
};

const Edit = ({ className, to }: EditProps) => {
  const navigate = useNavigate();
  const toString = to ? `${to}/edit` : 'edit';
  return (
    <div className={`flex my-3 mr-2 ${className}`}>
      <button onClick={() => navigate(toString)} className="pl-4 pr-3 py-2 ">
        <FontAwesomeIcon icon={faEdit} className="text-xl text-lh-dark-blue" />
      </button>
    </div>
  );
};

export default Edit;
