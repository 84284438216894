import { initStore } from '../hooks/use-store';

type Status = 'pending' | 'accepted' | 'rejected' | 'fulfilled';

const configureStatusStore = () => {
  const actions = {
    SET_STATUS: (curState, status: Status[]) => {
      return { ...curState, status };
    },
  };
  initStore(actions, { status: ['pending'] });
};

export default configureStatusStore;
