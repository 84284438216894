import clsx from 'clsx';
import React from 'react';

type ListItemProps = {
  onClick?: () => void;
  children: React.ReactNode | React.ReactNode[];
  className?: string;
};

const ListItem = ({ onClick, children, className }: ListItemProps) => {
  const style = clsx(
    'grid grid-cols-10 mx-2 mb-2 list-none shadow-md rounded-lg pb-4 px-2 pt-2 cursor-pointer',
    className
  );

  return (
    <li className={style} onClick={onClick}>
      {children}
    </li>
  );
};

export default ListItem;
