import { useState } from 'react';
import { CoPilot } from '../../../../global';
import HandleAction from './handleAction';
import dayjs from 'dayjs';
import { useCheckAccess } from '../../lib/auth/use-checkAccess';

export const CoPilotActionCard = ({
  id,
  createdAt,
  message,
  title,
  action,
  fsa,
}: CoPilot) => {
  const checkAccess = useCheckAccess();

  const isSupervisor = checkAccess(['sop', 'fsm']);
  const isFSA = checkAccess(['fsa']);

  const [showActionModal, setShowActionModal] = useState(false);
  const [actionType, setActionType] = useState('');

  const handleOnReject = () => {
    setShowActionModal(false);
  };

  return (
    <li className="flex flex-col mx-2 mb-2 h-18 list-none shadow-md rounded-lg pb-4 px-2 pt-2 bg-white border-lh-dark-blue border-2">
      {isSupervisor && (
        <div>
          Responsible FSA:
          <span className="font-semibold"> {fsa.name}</span>
        </div>
      )}
      <div className="flex justify-between">
        <h2 className="font-semibold">{title}</h2>
        <p className="font-light">
          {dayjs(createdAt).format('DD.MM.YYYY HH:mm:ss')}
        </p>
      </div>
      <div className="flex justify-between">
        <p className="text-sm">{message}</p>
      </div>
      <div>
        {!action && isFSA && (
          <div className="text-sm">
            <button
              type="button"
              className="col-span-full mt-2 text-center border rounded-md text-white cursor-pointer font-bold py-2 px-6 bg-lh-dark-blue hover:bg-blue-600"
              onClick={() => {
                setActionType('Called');
                setShowActionModal(true);
              }}
            >
              Called
            </button>
            <button
              type="button"
              className="col-span-full mt-2 text-center border rounded-md text-white cursor-pointer font-bold py-2 px-6 bg-lh-dark-blue hover:bg-blue-600"
              onClick={() => {
                setActionType('Messaged');
                setShowActionModal(true);
              }}
            >
              Messaged
            </button>
            <button
              type="button"
              className="col-span-full mt-2 text-center border rounded-md text-white cursor-pointer font-bold py-2 px-6 bg-lh-dark-blue hover:bg-blue-600"
              onClick={() => {
                setActionType('Visited');
                setShowActionModal(true);
              }}
            >
              Visited
            </button>
            <HandleAction
              open={showActionModal}
              setOpen={setShowActionModal}
              onReject={handleOnReject}
              actionType={actionType}
              id={id}
            />
          </div>
        )}
        {action && (
          <>
            <div className="mt-4 font-semibold">Follow up</div>
            <div className="border">
              <div className="text-sm font-semibold mt-2">
                {action.type} (
                {dayjs(action.created.toString()).format('DD.MM.YYYY HH:mm:ss')}
                )
              </div>
              <div className="text-sm font-semibold">
                <div>
                  Order placed:
                  <span
                    className={`${
                      action.orderPlaced ? 'text-green-500' : 'text-red-500'
                    } `}
                  >
                    {action.orderPlaced ? ' Yes' : ' No'}
                  </span>
                </div>
              </div>
              <div className="text-sm">
                <b>Note: </b>
                {action.note}
              </div>
            </div>
          </>
        )}
      </div>
    </li>
  );
};
