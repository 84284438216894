import { doc, updateDoc } from 'firebase/firestore';
import { Associate, User } from '../../../../global';
import { firestore } from '../utils/firebase';

export const confirmDelivery = (id: string) => {
  const ref = doc(firestore, `deliveries/${id}`);
  return updateDoc(ref, { status: 'confirmed' });
};

export const rejectDelivery = (id: string, comment: string) => {
  const ref = doc(firestore, `deliveries/${id}`);
  return updateDoc(ref, { status: 'rejected', rejectionComment: comment });
};

export const claimDelivery = (id: string, newDistributor: User) => {
  const ref = doc(firestore, `deliveries/${id}`);
  return updateDoc(ref, {
    status: 'claimed',
    distributor: {
      id: newDistributor.id,
      name: newDistributor.name,
      phone: newDistributor.phone,
      sapId: newDistributor.sapId,
    },
    sop: newDistributor.sop,
  });
};

export const reassignDelivery = (
  id: string,
  newDistributor: Associate,
  sop: User
) => {
  const ref = doc(firestore, `deliveries/${id}`);
  return updateDoc(ref, {
    status: 'reassigned',
    distributor: {
      id: newDistributor.id,
      name: newDistributor.name,
      phone: newDistributor.phone,
      sapId: newDistributor.sapId,
    },
    sop: {
      active: sop.active,
      appTOSAccepted: sop.appTOSAccepted,
      email: sop.email,
      id: sop.id,
      name: sop.name,
      phone: sop.phone,
    },
  });
};
