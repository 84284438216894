import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import Typesense from 'typesense';
import { SearchResponse } from 'typesense/lib/Typesense/Documents';

export type SearchQuery = {
  q: string;
  query_by: string;
  filter_by: string;
  page?: number;
};

type ReducerState = {
  error?: string;
  loading: boolean;
  values: any[];
  hasMore: boolean;
  lastDoc?: number;
  query?: SearchQuery;
};

type ErrorAction = { type: 'error'; error: string };
type ResetAction = { type: 'reset' };
type ValuesAction = {
  type: 'first' | 'more';
  values: any[];
  hasMore: boolean;
  lastDoc: number;
};
type LoadingAction = { type: 'loading'; loading: boolean };
type QueryAction = {
  type: 'query';
  query: SearchQuery | undefined;
};

type ReducerAction =
  | ErrorAction
  | ResetAction
  | ValuesAction
  | LoadingAction
  | QueryAction;

type PaginationHook = {
  error: string;
  hasMore: boolean;
  load: () => void;
  loading: boolean;
  reset: () => void;
  values: any[];
};

const defaultState = () => {
  return { loading: true, hasMore: false, values: [] };
};

const reducer =
  () =>
  (state: ReducerState, action: ReducerAction): ReducerState => {
    switch (action.type) {
      case 'error':
        return {
          ...state,
          error: action.error,
          loading: false,
          values: undefined,
          hasMore: false,
          lastDoc: undefined,
        };
      case 'reset':
        return defaultState();
      case 'first': {
        return {
          ...state,
          error: undefined,
          hasMore: action.hasMore,
          loading: false,
          values: [...action.values],
          lastDoc: action.lastDoc,
        };
      }
      case 'more': {
        return {
          ...state,
          error: undefined,
          hasMore: action.hasMore,
          loading: false,
          values: [...state.values, ...action.values],
          lastDoc: action.lastDoc,
        };
      }
      case 'loading': {
        return {
          ...state,
          error: undefined,
          loading: action.loading,
        };
      }
      case 'query': {
        return {
          ...state,
          query: action.query,
        };
      }
      default:
        return state;
    }
  };

export const usePaginationTypesense = (
  q: SearchQuery,
  pageSize: number,
  collection: string,
  typesenseKey: string
): PaginationHook => {
  const [state, dispatch] = useReducer(reducer(), defaultState());
  const [client, setClient] = useState(null);

  useEffect(() => {
    if (typesenseKey) {
      setClient(
        new Typesense.Client({
          nodes: [
            {
              host: 'ts.mcomtech.ch',
              port: 443,
              protocol: 'https',
            },
          ],
          apiKey: typesenseKey,
          connectionTimeoutSeconds: 60,
        })
      );
    }
  }, [typesenseKey]);

  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q, pageSize]);

  useEffect(() => {
    if (!state.query) {
      return;
    }
    client
      .collections(collection)
      .documents()
      .search(state.query)
      .then((result) => setFirst(result, pageSize))
      .catch(setError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.query, pageSize, collection]);

  const reset = () => {
    dispatch({ type: 'reset' });
    if (!q) {
      return;
    }
    let newQuery = q;
    if (pageSize > 0) {
      newQuery = {
        q: q.q,
        query_by: q.query_by,
        filter_by: q.filter_by,
        page: 1,
      };
    }
    dispatch({ type: 'query', query: newQuery });
  };

  const setMore = (result: SearchResponse<{}>, pageSize: number): void => {
    dispatch({
      type: 'more',
      values: result.hits,
      lastDoc: pageSize * (result.page - 1) + result.hits.length,
      hasMore:
        result.found - pageSize * (result.page - 1) + result.hits.length <= 0
          ? false
          : true,
    });
  };

  const setFirst = (result: SearchResponse<{}>, pageSize: number): void => {
    dispatch({
      type: 'first',
      values: result.hits,
      lastDoc: pageSize * (result.page - 1) + result.hits.length,
      hasMore:
        result.found - pageSize * (result.page - 1) + result.hits.length <= 0
          ? false
          : true,
    });
  };

  const setError = (error: string): void => {
    console.log('er', error);
    dispatch({ type: 'error', error });
  };

  const load = useCallback(() => {
    dispatch({ type: 'loading', loading: true });
    if (!state.query || !state.lastDoc || !state.hasMore) {
      dispatch({ type: 'loading', loading: false });
      return;
    }

    client
      .collections(collection)
      .documents()
      .search({
        ...state.query,
        page: state.query?.page ? state.query.page + 1 : 2,
      })
      .then((result) => setMore(result, pageSize))
      .catch(setError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.hasMore, state.lastDoc, pageSize, state.query, collection]);

  return useMemo(
    () => ({
      error: state.error,
      hasMore: state.hasMore,
      load,
      loading: state.loading,
      reset,
      values: state.values,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.error, state.hasMore, state.loading, state.values, load]
  );
};
