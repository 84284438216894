import { FirebaseError } from 'firebase/app';
import { collection, orderBy, query, Query, where } from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { docToJSON, firestore } from '../utils/firebase';
import { usePagination } from '../utils/usePagination';
import { useAuth } from './use-auth';
import { CoPilot } from '../../../../global';

type CopilotHook = {
  error: FirebaseError;
  hasMore: boolean;
  load: () => void;
  loading: boolean;
  reset: () => void;
  documents: CoPilot[];
};

type CopilotHookOptions = {
  direction?: 'asc' | 'desc';
  pageSize?: number;
  loadData?: boolean;
  actionTaken?: boolean;
};

export const useCopilot = ({
  direction = 'asc',
  pageSize = 10,
  actionTaken,
}: CopilotHookOptions): CopilotHook => {
  const { id } = useAuth();
  const [copilotQuery, setCopilotQuery] = useState<Query>();
  const { error, hasMore, load, loading, reset, values } = usePagination(
    copilotQuery,
    pageSize
  );

  useEffect(() => {
    if (!id) return;

    let newQuery = query(
      collection(firestore, `copilot`),
      orderBy('createdAt', direction),
      where('userIds', 'array-contains', id)
    );

    if (actionTaken !== undefined) {
      newQuery = query(newQuery, where('actionTaken', '==', actionTaken));
    }
    setCopilotQuery(newQuery);
  }, [id, direction, actionTaken]);

  return useMemo(
    () => ({
      error,
      hasMore,
      load,
      loading,
      reset,
      documents: values ? values.map((v) => docToJSON<CoPilot>(v)) : values,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [error, hasMore, loading, values]
  );
};
