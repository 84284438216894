import {
  addDoc,
  collection,
  doc,
  updateDoc,
} from 'firebase/firestore';
import { Route } from '../../../../global';
import { firestore } from '../utils/firebase';

type RoutePlanUpdateData = {
  fsaId: string;
  name: string;
  day: number;
  month: number;
  year: number;
  route: Route;
};

export const createRoutePlanning = async (
  data: RoutePlanUpdateData
): Promise<void> => {
  const ref = collection(firestore, 'routePlans');
  await addDoc(ref, data);
};

export const updateRoutePlan = async (id: string, data: any): Promise<void> => {
  const ref = doc(firestore, 'routePlans', id);
  await updateDoc(ref, data);
};

/*
export const setOutlet = async (id: string, data: RouteOutlet) => {
  const ref = doc(firestore, `routes/${id}/routeOutlets`, data.id);
  await setDoc(ref, data);
};
*/
export const deleteRoutePlanning = async (routePlanId: string) => {
  const ref = doc(firestore, `routePlans`, routePlanId);
  await updateDoc(ref, { route: null });
};

/*
export const updateOutlet = async (id: string, outletId: string, data: any) => {
  const ref = doc(firestore, `routes/${id}/routeOutlets`, outletId);
  await updateDoc(ref, data);
};
*/
