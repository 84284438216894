import { FirebaseError } from 'firebase/app';
import {
  collection,
  doc,
  FirestoreError,
  onSnapshot,
  orderBy,
  query,
  Query,
  Timestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { Route, RoutePlan } from '../../../../global';
import {
  createRoutePlanning,
  deleteRoutePlanning,
  updateRoutePlan,
} from '../db/routesPlanning';
import { docToJSON, firestore } from '../utils/firebase';
import { usePagination } from '../utils/usePagination';
import { useAuth } from './use-auth';

type RoutePlansHook = {
  error: FirebaseError;
  hasMore: boolean;
  load: () => void;
  loading: boolean;
  reset: () => void;
  routePlans: RoutePlan[];
};

type RoutePlansHookOptions = {
  fsaId: string;
  month: number;
  year: number;
  day?: number;
};

export const useRoutePlans = ({
  fsaId,
  month,
  year,
  day,
}: RoutePlansHookOptions): RoutePlansHook => {
  const { id } = useAuth();
  const [routePlansQuery, setRoutePlansQuery] = useState<Query>();
  const { error, hasMore, load, loading, reset, values } = usePagination(
    routePlansQuery,
    0
  );

  useEffect(() => {
    if (id) {
      if (day) {
        setRoutePlansQuery(
          query(
            collection(firestore, 'routePlans'),
            where('userIds', 'array-contains', id),
            where('fsa.id', '==', fsaId),
            where('day', '==', day),
            where('month', '==', month),
            where('year', '==', year),
            where('active', '==', true),
            orderBy('date')
          )
        );
      } else {
        setRoutePlansQuery(
          query(
            collection(firestore, 'routePlans'),
            where('userIds', 'array-contains', id),
            where('fsa.id', '==', fsaId),
            where('month', '==', month),
            where('year', '==', year),
            where('active', '==', true),
            orderBy('date')
          )
        );
      }
    } else if (routePlansQuery) {
      setRoutePlansQuery(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, fsaId, month, year, day]);

  useEffect(() => {
    console.log(error);
  }, [error]);

  return useMemo(
    () => ({
      error,
      hasMore,
      load,
      loading,
      reset,
      routePlans: values
        ? values.map((v) => docToJSON(v) as RoutePlan)
        : values,
    }),
    [error, hasMore, load, loading, reset, values]
  );
};

export const useRoutePlan = (id?: string) => {
  const [loading, setLoading] = useState(false);
  const [routePlan, setRoutePlan] = useState<RoutePlan | undefined>();
  const [error, setError] = useState<FirestoreError>();

  useEffect(() => {
    let unsubscribe;
    if (id) {
      setLoading(true);
      const ref = doc(firestore, 'routePlans', id);
      unsubscribe = onSnapshot(
        ref,
        (doc) => {
          if (doc.exists()) {
            setRoutePlan(docToJSON(doc) as RoutePlan);
          } else {
            setRoutePlan(undefined);
          }
          setLoading(false);
        },
        (error: FirestoreError) => {
          setLoading(false);
          setError(error);
        }
      );
    }
    return unsubscribe;
  }, [id]);

  type RoutePlanUpdateData = {
    fsaId: string;
    name: string;
    day: number;
    month: number;
    year: number;
    routePlanDate: Timestamp;
    route: Route;
  };

  const create = async (data: RoutePlanUpdateData) => {
    try {
      setLoading(true);
      await createRoutePlanning(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(error);
    }
  };

  const update = async (id: string, data: any) => {
    try {
      setLoading(true);
      await updateRoutePlan(id, data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(error);
    }
  };

  const setRoute = async (data: RoutePlanUpdateData) => {
    if (id) {
      try {
        setLoading(true);
        const ref = doc(firestore, 'routePlans', id);
        await updateDoc(ref, { route: data });
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setError(err);
      }
    }
  };

  const removeRoutePlan = async (routePlanId: string) => {
    if (routePlanId) {
      try {
        setLoading(true);
        await deleteRoutePlanning(routePlanId);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setError(err);
      }
    }
  };

  useEffect(() => {
    console.log(error);
  }, [error]);

  return {
    routePlan,
    create,
    update,
    error,
    loading,
    setRoute,
    removeRoutePlan,
  };
};
