import { forwardRef, useEffect, useImperativeHandle } from 'react';
import Input from '../../../components/forms/Input';
import { useForm, useWatch } from 'react-hook-form';

type Props = {
  editable: boolean;
  setTechnicalAssistance: any;
  setStep2IsValid: any;
  technicalAssistance: {
    trialMix: boolean;
    boq: string;
    siteInspection: string;
    designAdvisoryService: string;
    healthSafetyTraining: string;
    specifyHealthSafetyTraining: string;
  };
};

const SiteVisitStep2 = forwardRef((props: Props, ref) => {
  const { control, register, handleSubmit } = useForm({
    defaultValues: { ...props.technicalAssistance },
  });

  const trialMix = useWatch({
    control,
    name: 'trialMix',
  });

  const boq = useWatch({
    control,
    name: 'boq',
  });

  const siteInspection = useWatch({
    control,
    name: 'siteInspection',
  });

  const designAdvisoryService = useWatch({
    control,
    name: 'designAdvisoryService',
  });

  const healthSafetyTraining = useWatch({
    control,
    name: 'healthSafetyTraining',
  });

  const specifyHealthSafetyTraining = useWatch({
    control,
    name: 'specifyHealthSafetyTraining',
  });

  useEffect(() => {
    if (
      trialMix !== undefined &&
      trialMix !== null &&
      boq !== undefined &&
      boq !== null &&
      siteInspection !== undefined &&
      siteInspection !== null &&
      designAdvisoryService !== undefined &&
      designAdvisoryService !== null &&
      ((healthSafetyTraining === 'true' &&
        specifyHealthSafetyTraining &&
        specifyHealthSafetyTraining.length >= 1) ||
        healthSafetyTraining === 'false')
    ) {
      props.setStep2IsValid(true);
    } else {
      props.setStep2IsValid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    trialMix,
    boq,
    siteInspection,
    designAdvisoryService,
    healthSafetyTraining,
    specifyHealthSafetyTraining,
  ]);

  useImperativeHandle(ref, () => ({
    saveTechAssistance() {
      doSaveTechAssistance();
    },
  }));

  const doSaveTechAssistance = () => {
    handleSubmit(props.setTechnicalAssistance)();
  };

  return (
    <>
      <div className="grid grid-cols-9 col-span-9 ">
        <div className="grid grid-cols-12 col-span-9 content-center ">
          <p className={'col-span-12 text-center mt-4 font-semibold pl-6'}>
            Technical assistance
          </p>
          <p
            className={
              'col-span-12 col-start-1 text-left mb-4 mt-4 font-semibold'
            }
          >
            Trial mix
          </p>
          <Input
            register={register}
            type="radio"
            name="trialMix"
            value="cementTrialMix"
            placeholder="0"
            disabled={props.editable}
            className={'col-span-1 text-left align-top'}
          />
          <p className="col-span-11 text-left font-normal align-top">
            Cement trail mix
          </p>
          <Input
            register={register}
            type="radio"
            name="trialMix"
            value="mortarTrialMix"
            placeholder="0"
            disabled={props.editable}
            className={'col-span-1 text-left align-top'}
          />
          <p className="col-span-11 text-left font-normal align-top">
            Mortar trial mix
          </p>
          <Input
            register={register}
            type="radio"
            name="trialMix"
            value="cementMortarTrialMix"
            placeholder="0"
            disabled={props.editable}
            className={'col-span-1 text-left align-top'}
          />
          <p className="col-span-11 text-left font-normal align-top">
            Cement and mortar trial mix
          </p>
          <Input
            register={register}
            type="radio"
            name="trialMix"
            value="noTrialMix"
            placeholder="0"
            disabled={props.editable}
            className={'col-span-1 text-left align-top'}
          />
          <p className="col-span-11 text-left font-normal align-top">
            No trial mix
          </p>
          <hr className="col-span-12" />
          <p className={'col-span-12 text-left mt-2 mb-2 font-semibold'}>BOQ</p>
          <Input
            register={register}
            type="radio"
            name="boq"
            value="true"
            placeholder="0"
            disabled={props.editable}
            className={'col-span-1 text-left align-top'}
          />
          <p className="col-span-3 text-left font-normal align-top">Yes</p>
          <Input
            register={register}
            type="radio"
            name="boq"
            value="false"
            placeholder="0"
            disabled={props.editable}
            className={'col-span-1 text-left align-top'}
          />
          <p className="col-span-7 text-left font-normal align-top">No</p>
          <p className={'col-span-12 text-left mb-2 font-semibold'}>
            Site inspection
          </p>
          <Input
            register={register}
            type="radio"
            name="siteInspection"
            value="true"
            placeholder="0"
            disabled={props.editable}
            className={'col-span-1 text-left align-top'}
          />
          <p className="col-span-3 text-left font-normal align-top">Yes</p>
          <Input
            register={register}
            type="radio"
            name="siteInspection"
            value="false"
            placeholder="0"
            disabled={props.editable}
            className={'col-span-1 text-left align-top'}
          />
          <p className="col-span-7 text-left font-normal align-top">No</p>
          <p className={'col-span-12 text-left mb-2 font-semibold'}>
            Design advisory service
          </p>
          <Input
            register={register}
            type="radio"
            name="designAdvisoryService"
            value="true"
            placeholder="0"
            disabled={props.editable}
            className={'col-span-1 text-left align-top'}
          />
          <p className="col-span-3 text-left font-normal align-top">Yes</p>
          <Input
            register={register}
            type="radio"
            name="designAdvisoryService"
            value="false"
            placeholder="0"
            disabled={props.editable}
            className={'col-span-1 text-left align-top'}
          />
          <p className="col-span-7 text-left font-normal align-top">No</p>
          <p className={'col-span-12 text-left mb-2 font-semibold'}>
            Health and safety training
          </p>
          <Input
            register={register}
            type="radio"
            name="healthSafetyTraining"
            value="true"
            placeholder="0"
            disabled={props.editable}
            className={'col-span-1 text-left align-top'}
          />
          <p className="col-span-3 text-left font-normal align-top">Yes</p>
          <Input
            register={register}
            type="radio"
            name="healthSafetyTraining"
            value="false"
            placeholder="0"
            disabled={props.editable}
            className={'col-span-1 text-left align-top'}
          />
          <p className="col-span-7 text-left font-normal align-top">No</p>
          {healthSafetyTraining === 'true' && (
            <>
              <p className={'col-span-12 text-left mb-2 font-semibold'}>
                {'Specify the H&S Training conducted'}
              </p>
              <Input
                register={register}
                type="text"
                name="specifyHealthSafetyTraining"
                disabled={props.editable}
                className={'col-span-12 text-left align-top'}
              />
            </>
          )}
        </div>
        <p></p>
      </div>
    </>
  );
});
export default SiteVisitStep2;
