import { distanceBetween } from 'geofire-common';
import { Feature } from 'ol';
import { Circle, Point } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Icon, Stroke, Style } from 'ol/style';
import { useEffect } from 'react';
import { useMap } from '../../../lib/hooks/use-map';
import { transform } from 'ol/proj';
import { Location } from '../../../../../global';

import userImage from '../../../assets/icons/user-solid-white.svg';

interface VisitLayerProps {
  visitLocation: Location;
  userLocation: Location;
  icon: string;
  name: string;
}

const VisitLayer = ({
  visitLocation,
  userLocation,
  icon,
  name,
}: VisitLayerProps) => {
  const { map } = useMap();

  useEffect(() => {
    if (!map) return;

    const visitCoords = transform(
      [visitLocation.lng, visitLocation.lat],
      'EPSG:4326',
      'EPSG:3857'
    );
    const userCoords = transform(
      [userLocation.lng, userLocation.lat],
      'EPSG:4326',
      'EPSG:3857'
    );

    const visit = new Feature({
      geometry: new Point(visitCoords),
      name,
    });

    visit.setStyle(
      new Style({
        image: new Icon({
          color: '#000000',
          crossOrigin: 'anonymous',
          // For Internet Explorer 11
          src: icon,
        }),
      })
    );

    const distance = distanceBetween(
      [visitLocation.lat, visitLocation.lng],
      [userLocation.lat, userLocation.lng]
    );

    const userColor = distance > 200 ? '#DA3851' : '#94c12e';

    const user = new Feature({
      geometry: new Point(userCoords),
    });

    user.setStyle(
      new Style({
        image: new Icon({
          color: userColor,
          crossOrigin: 'anonymous',
          // For Internet Explorer 11
          src: userImage,
        }),
      })
    );

    const circle = new Feature({
      geometry: new Circle(visitCoords, 200),
    });

    circle.setStyle(
      new Style({
        stroke: new Stroke({
          color: distance > 200 ? '#DA3851' : '#94c12e',
          width: 2,
        }),
        fill: new Fill({
          color:
            distance > 200
              ? 'rgba(218, 56, 81, 0.2)'
              : 'rgba(148, 193, 46, 0.2)',
        }),
      })
    );

    const layer = new VectorLayer({
      source: new VectorSource({
        features: [visit, user, circle],
      }),
    });

    map.addLayer(layer);

    const bounds = layer.getSource().getExtent();
    map.getView().fit(bounds);
    const zoom = 20 - map.getView().getZoom();
    map
      .getView()
      .fit([
        bounds[0],
        bounds[1] - Math.pow(zoom, 4),
        bounds[2],
        bounds[3] + Math.pow(zoom, 4),
      ]);

    return () => {
      if (map) {
        map.removeLayer(layer);
      }
    };
  }, [userLocation, visitLocation, icon, name]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default VisitLayer;
