import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import { CreateSaleData, UpdateOrderData } from '../../../../global';
import { firestore } from '../utils/firebase';

export const createSale = async (data: CreateSaleData): Promise<void> => {
  const ref = collection(firestore, 'sales');
  await addDoc(ref, data);
};

export const updateSale = async (
  id: string,
  data: UpdateOrderData
): Promise<void> => {
  const ref = doc(firestore, 'sales', id);
  await updateDoc(ref, data);
};
