import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { RegisterOptions, useForm, useWatch } from 'react-hook-form';
import Select from '../../../components/forms/Select';
import Input from '../../../components/forms/Input';

const VISIT_PURPOSE = [
  {
    key: 'cement_engagement',
    label: 'Cement engagement',
    value: 'cement_engagement',
  },
  {
    key: 'mortar_engagement',
    label: 'Mortar engagement',
    value: 'mortar_engagement',
  },
  {
    key: 'cement_mortar_engagement',
    label: 'Cement & Mortar engagement',
    value: 'cement_mortar_engagement',
  },
  {
    key: 'follow_up',
    label: 'Follow Up',
    value: 'follow_up',
  },
];

const ACTIVITY_LEVEL = [
  {
    key: 'foundation',
    label: 'Foundation',
    value: 'foundation',
  },
  {
    key: 'casting',
    label: 'Casting',
    value: 'casting',
  },
  {
    key: 'plastering',
    label: 'Plastering',
    value: 'plastering',
  },
  {
    key: 'block_setting',
    label: 'Block setting',
    value: 'block_setting',
  },
  {
    key: 'tiling_stage',
    label: 'Tiling stage',
    value: 'tiling_stage',
  },
];

const REASON_ACTIVITY_LEVEL = [
  {
    key: 'budget_constraint',
    label: 'Budget constraint',
    value: 'budget_constraint',
  },
  {
    key: 'holiday',
    label: 'Holiday',
    value: 'holiday',
  },
  {
    key: 'high_cost_of_cement',
    label: 'High cost of cement',
    value: 'high_cost_of_cement',
  },
  {
    key: 'high_cost_of_building_materials',
    label: 'High cost of building materials',
    value: 'high_cost_of_building_materials',
  },
  {
    key: 'government_restriction',
    label: 'Government restrictions',
    value: 'government_restriction',
  },
  {
    key: 'shortage_of_labour',
    label: 'Shortage of labour',
    value: 'shortage_of_labour',
  },
  {
    key: 'project_finalized',
    label: 'Project finalized',
    value: 'project_finalized',
  },
  {
    key: 'other',
    label: 'Other',
    value: 'other',
  },
];

const SITE_TYPE = [
  {
    key: 'sandcrete_block_making',
    label: 'Sandcrete Block Making',
    value: 'sandcrete_block_making',
  },
  {
    key: 'residential_ihb',
    label: 'Residential (IHB)',
    value: 'residential_ihb',
  },
  {
    key: 'residential_estate',
    label: 'Residential (Estate)',
    value: 'residential_estate',
  },
  {
    key: 'commercial_building',
    label: 'Commercial Building (Schools, offices &Hospital)',
    value: 'commercial_building',
  },
  {
    key: 'shopping_mall',
    label: 'Shopping Mall',
    value: 'shopping_mall',
  },
  {
    key: 'infrastructural_project',
    label: 'Infrastructural Project',
    value: 'infrastructural_project',
  },
  {
    key: 'others',
    label: 'Others (specify)',
    value: 'others',
  },
];

const PROJECT_STAGE = [
  {
    key: 'clearing_of_site',
    label: 'Clearing of Site',
    value: 'clearing_of_site',
  },
  {
    key: 'setting_out',
    label: 'Setting out',
    value: 'setting_out',
  },
  {
    key: 'excavation',
    label: 'Excavation',
    value: 'excavation',
  },
  {
    key: 'foundation',
    label: 'Foundation (Substructure)',
    value: 'foundation',
  },
  {
    key: 'block_settings',
    label: 'Block Settings (Superstructure)',
    value: 'block_settings',
  },
  {
    key: 'ground_floor_column_beam_slab',
    label: 'Ground Floor Column, Beam & Slab (Superstructure)',
    value: 'ground_floor_column_beam_slab',
  },
  {
    key: 'first_floor_column_beam_slab',
    label: 'First Floor Column, Beam & Slab (Superstructure)',
    value: 'first_floor_column_beam_slab',
  },
  {
    key: 'second_floor_column_beam_slab',
    label: 'Second Floor Column, Beam & Slab (Superstructure)',
    value: 'second_floor_column_beam_slab',
  },
  {
    key: 'third_floor_column_beam_slab',
    label: 'Third Floor Column, Beam & Slab (Superstructure)',
    value: 'third_floor_column_beam_slab',
  },
  {
    key: 'roofing',
    label: 'Roofing',
    value: 'roofing',
  },
  {
    key: 'plastering',
    label: 'Plastering',
    value: 'plastering',
  },
  {
    key: 'tiling',
    label: 'Tiling',
    value: 'tiling',
  },
  {
    key: 'others',
    label: 'Others (specify)',
    value: 'others',
  },
]

type Props = {
  editable: boolean;
  setStep1: any;
  setStep1IsDirty: any;
  setStep1IsValid: any;
  step1: {
    visitPurpose: string;
    activeSite: string;
    reasonActivityLevel: string;
    activityLevel: string;
    siteAddress: string;
    nameOfRepresentative: string;
    contactNumber: string;
    siteType: string;
    siteTypeOther: string;
    topic: string;
    technicalSupport: string;
    projectStage: string;
    projectStageOther: string;
  };
};

const SiteVisitStep1 = forwardRef((props: Props, ref) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: { ...props.step1 },
    mode: 'all',
  });
  const visitPurpose = useWatch({
    control,
    name: 'visitPurpose',
  });
  const activeSite = useWatch({
    control,
    name: 'activeSite',
  });
  const reasonActivityLevel = useWatch({
    control,
    name: 'reasonActivityLevel',
  });
  const activityLevel = useWatch({
    control,
    name: 'activityLevel',
  });
  const siteAddress = useWatch({
    control,
    name: 'siteAddress',
  });
  const nameOfRepresentative = useWatch({
    control,
    name: 'nameOfRepresentative',
  });
  const contactNumber = useWatch({
    control,
    name: 'contactNumber',
  });
  const siteType = useWatch({
    control,
    name: 'siteType',
  });
  const siteTypeOther = useWatch({
    control,
    name: 'siteTypeOther',
  });
  const topic = useWatch({
    control,
    name: 'topic',
  });
  const technicalSupport = useWatch({
    control,
    name: 'technicalSupport',
  });
  const projectStage = useWatch({
    control,
    name: 'projectStage',
  });
  const projectStageOther = useWatch({
    control,
    name: 'projectStageOther',
  });

  const visitPurposeOptions: RegisterOptions = {
    required: 'Please select a purpose type!',
  };

  const activeSiteOptions: RegisterOptions = {
    required: 'Please select if this site is still active!',
  };

  const reasonActivityLevelOptions: RegisterOptions = {
    required: 'Please select why this site is inactive!',
  };

  const activityLevelOptions: RegisterOptions = {
    required: 'Please select the activity level of this site!',
  };

  const siteAddressOptions: RegisterOptions = {
    required: 'Please enter the site address!',
  };

  const nameOfRepresentativeOptions: RegisterOptions = {
    required: 'Please enter the name of the representative!',
  };

  const contactNumberOptions: RegisterOptions = {
    required: 'Please enter the contact number!',
  };

  const siteTypeOptions: RegisterOptions = {
    required: 'Please select the site type!',
  };

  const siteTypeOtherOptions: RegisterOptions = {
    required: 'Please specify!',
  };

  const topicOptions: RegisterOptions = {
    required: 'Please enter the topic!',
  };  

  const technicalSupportOptions: RegisterOptions = {
    required: 'Please enter the technical support!',
  };

  const projectStageOptions: RegisterOptions = {
    required: 'Please specify!',
  };

  useEffect(() => {
    props.setStep1IsDirty(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  useEffect(() => {
    if (
      visitPurpose &&
      activeSite &&
      (activeSite === 'active' ||
        (activeSite === 'notActive' && reasonActivityLevel)) &&
      activityLevel &&
      siteAddress &&
      nameOfRepresentative &&
      contactNumber &&
      siteType &&
      (siteType !== 'others' || (siteType === 'others' && siteTypeOther)) &&
      topic &&
      technicalSupport &&
      projectStage &&
      (projectStage !== 'others' || (projectStage === 'others' && projectStageOther))
    ) {
      props.setStep1IsValid(true);
    } else {
      props.setStep1IsValid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitPurpose, activeSite, reasonActivityLevel, activityLevel, siteAddress, nameOfRepresentative, contactNumber, siteType, siteTypeOther, topic, technicalSupport, projectStage, projectStageOther]);

  useImperativeHandle(ref, () => ({
    saveStep1() {
      doSaveStep1();
    },
  }));

  const doSaveStep1 = () => {
    handleSubmit(props.setStep1)();
  };

  return (
    <>
      <div className="grid grid-cols-9 col-span-9 ">
        <div className="grid grid-cols-12 col-span-9 content-center ">
          <label
            htmlFor="visitPurpose"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            Visit Purpose
          </label>
          <Select
            register={register}
            name="visitPurpose"
            items={VISIT_PURPOSE}
            placeholder="Select visit purpose..."
            error={errors.visitPurpose}
            options={visitPurposeOptions}
            className="col-span-12"
          />
          <label
            htmlFor="activeSite"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            Active Site
          </label>
          <Select
            register={register}
            name="activeSite"
            items={[
              {
                key: 'active',
                label: 'Active',
                value: 'active',
              },
              {
                key: 'notActive',
                label: 'Not Active',
                value: 'notActive',
              },
            ]}
            placeholder="Is this site still active?"
            error={errors.activeSite}
            options={activeSiteOptions}
            className="col-span-12"
          />
          {activeSite === 'notActive' && (
            <>
              <label
                htmlFor="reasonActivityLevel"
                className="font-bold col-span-12 mb-2 text-lh-head-black"
              >
                Reason for Site inactive
              </label>
              <Select
                register={register}
                name="reasonActivityLevel"
                items={REASON_ACTIVITY_LEVEL}
                placeholder="Select reason for site inactive..."
                error={errors.reasonActivityLevel}
                options={reasonActivityLevelOptions}
                className="col-span-12"
              />
            </>
          )}
          <label
            htmlFor="activityLevel"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            Activity level
          </label>
          <Select
            register={register}
            name="activityLevel"
            items={ACTIVITY_LEVEL}
            placeholder="Select activity level..."
            error={errors.activityLevel}
            options={activityLevelOptions}
            className="col-span-12"
          />
          <label
            htmlFor="siteAddress"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            Site Address
          </label>
          <Input
            register={register}
            name="siteAddress"
            placeholder="Enter site address..."
            error={errors.siteAddress}
            options={siteAddressOptions}
            className="col-span-12"
          />
          <label
            htmlFor="nameOfRepresentative"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            Name of Site Representative during Visit
          </label>
          <Input
            register={register}
            name="nameOfRepresentative"
            placeholder="Enter name of representative..."
            error={errors.nameOfRepresentative}
            options={nameOfRepresentativeOptions}
            className="col-span-12"
          />
          <label
            htmlFor="contactNumber"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            Contact Number of Site Rep during Visit
          </label>
          <Input
            register={register}
            name="contactNumber"
            placeholder="Enter contact number..."
            error={errors.contactNumber}
            options={contactNumberOptions}
            className="col-span-12"
          />
          <label
            htmlFor="siteType"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            Site Type
          </label>
          <Select
            register={register}
            name="siteType"
            items={SITE_TYPE}
            placeholder="Select site type..."
            error={errors.siteType}
            options={siteTypeOptions}
            className="col-span-12"
          />
          {siteType === 'others' && (
            <>
              <label
                htmlFor="siteTypeOther"
                className="font-bold col-span-12 mb-2 text-lh-head-black"
              >
                Specify Site Type
              </label>
              <Input
                register={register}
                name="siteTypeOther"
                placeholder="Specify..."
                error={errors.siteTypeOther}
                options={siteTypeOtherOptions}
                className="col-span-12"
              />
            </>
          )}
          <label
            htmlFor="topic"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            Major Topic of discussion during Visit
          </label>
          <Input
            register={register}
            name="topic"
            placeholder="Enter topic..."
            error={errors.topic}
            options={topicOptions}
            className="col-span-12"
          />
          <label
            htmlFor="technicalSupport"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            Technical Support Required
          </label>
          <Input
            register={register}
            name="technicalSupport"
            placeholder="Enter technical support..."
            error={errors.technicalSupport}
            options={technicalSupportOptions}
            className="col-span-12"
          />
          <label
            htmlFor="projectStage"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            Project Stage
          </label>
          <Select
            register={register}
            name="projectStage"
            items={PROJECT_STAGE}
            placeholder="Select project stage..."
            error={errors.projectStage}
            options={projectStageOptions}
            className="col-span-12"
          />
          {projectStage === 'others' && (
            <>
              <label
                htmlFor="projectStageOther"
                className="font-bold col-span-12 mb-2 text-lh-head-black"
              >
                Specify Project Stage
              </label>
              <Input
                register={register}
                name="projectStageOther"
                placeholder="Specify..."
                error={errors.projectStageOther}
                options={projectStageOptions}
                className="col-span-12"
              />
            </>
          )}
        </div>
      </div>
    </>
  );
});

export default SiteVisitStep1;
