import {
  doc,
  updateDoc,
} from 'firebase/firestore';
import {
  UserChanges,
} from '../../../../global';
import { firestore } from '../utils/firebase';

export const updateUser = async (
  userId: string,
  data: UserChanges
) => {
  const ref = doc(firestore, 'users', userId);
  await updateDoc(ref, data);
};
