import {
  collection,
  count,
  getAggregateFromServer,
  query,
  sum,
  where,
} from 'firebase/firestore';
import { firestore } from '../firebase';
import dayjs from 'dayjs';

export const getRealtimeOutletPerformance = async (
  outletId: string,
  id: string,
  setFSAVolume: any,
  setWhatsAppVolume: any,
  setFSAOrders: any,
  setWhatsAppOrders: any
) => {
  const coll = collection(firestore, 'orders');

  const q0 = query(
    coll,
    where('outlet.id', '==', outletId),
    where('status', '==', 'fulfilled'),
    where('createdOn', '==', 'app'),
    where('statusHistory.fulfilled', '>=', dayjs().startOf('month').toDate()),
    where('statusHistory.fulfilled', '<=', dayjs().endOf('month').toDate()),
    where('userIds', 'array-contains', id)
  );

  const snapshot0 = await getAggregateFromServer(q0, {
    totalFSAOrders: count(),
  });

  const snapshot01 = await getAggregateFromServer(q0, {
    totalFSAVolumeClassic: sum('productsDelivered.classic'),
    totalFSAVolumeSupaset: sum('productsDelivered.supaset'),
    totalFSAVolumeSupafixC0: sum('productsDelivered.supafixc0'),
    totalFSAVolumeSupafixC1: sum('productsDelivered.supafixc1'),
  });

  setFSAOrders(snapshot0.data().totalFSAOrders);

  // Sum up the total volume
  const totalVolumeClassic = snapshot01.data().totalFSAVolumeClassic;
  const totalVolumeSupaset = snapshot01.data().totalFSAVolumeSupaset;
  const totalVolumeSupafixC0 = snapshot01.data().totalFSAVolumeSupafixC0;
  const totalVolumeSupafixC1 = snapshot01.data().totalFSAVolumeSupafixC1;

  setFSAVolume(
    totalVolumeClassic * 0.05 + totalVolumeSupaset * 0.05 + totalVolumeSupafixC0 * 0.02 + totalVolumeSupafixC1 * 0.02
  );

  const q1 = query(
    coll,
    where('outlet.id', '==', outletId),
    where('status', '==', 'fulfilled'),
    where('createdOn', '==', 'chatbot'),
    where('statusHistory.fulfilled', '>=', dayjs().startOf('month').toDate()),
    where('statusHistory.fulfilled', '<=', dayjs().endOf('month').toDate()),
    where('userIds', 'array-contains', id)
  );

  const snapshot1 = await getAggregateFromServer(q1, {
    totalWhatsAppOrders: count(),
  });

  const snapshot11 = await getAggregateFromServer(q1, {
    totalWhatsAppVolumeClassic: sum('productsDelivered.classic'),
    totalWhatsAppVolumeSupaset: sum('productsDelivered.supaset'),
    totalWhatsAppVolumeSupafixC0: sum('productsDelivered.supafixc0'),
    totalWhatsAppVolumeSupafixC1: sum('productsDelivered.supafixc1'),
  });

  setWhatsAppOrders(snapshot1.data().totalWhatsAppOrders);

  // Sum up the total volume
  const totalWhatsAppVolumeClassic = snapshot11.data().totalWhatsAppVolumeClassic;
  const totalWhatsAppVolumeSupaset = snapshot11.data().totalWhatsAppVolumeSupaset;
  const totalWhatsAppVolumeSupafixC0 = snapshot11.data().totalWhatsAppVolumeSupafixC0;
  const totalWhatsAppVolumeSupafixC1 = snapshot11.data().totalWhatsAppVolumeSupafixC1;

  setWhatsAppVolume(
    totalWhatsAppVolumeClassic * 0.05 +
      totalWhatsAppVolumeSupaset * 0.05 +
      totalWhatsAppVolumeSupafixC0 * 0.02 +
      totalWhatsAppVolumeSupafixC1 * 0.02
  );
};
