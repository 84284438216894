import { auth } from '../utils/firebase';

export const useSignOut = () => {
  const signOut = async () => {
    await auth.signOut();
    localStorage.removeItem('userId');
    localStorage.removeItem('roles');
  };

  return signOut;
};
