import { useMemo, useState } from 'react';
import { Code } from '../forms/Code';
import Button from '../ui/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons';

type VerifyCodeProps = {
  handleVerifyCode: (code: string) => void;
  phone: string;
  back: () => void;
};

export const VerifyCode: React.FC<VerifyCodeProps> = ({
  handleVerifyCode,
  phone,
  back,
}) => {
  const [code, setCode] = useState<string>('');

  const codeIsValid = useMemo(() => code.length === 6, [code]);

  return (
    <>
      <div className="flex justify-between">
        <div className="flex my-3 mr-2">
          <button className="pl-3 pr-4 py-2 flex" onClick={back}>
            <FontAwesomeIcon
              icon={faChevronCircleLeft}
              className="text-xl h-6 w-6 text-lh-dark-blue mr-2"
            />
            <p className="lowercase">Edit Number</p>
          </button>
        </div>
      </div>
      <div className="w-full text-center font-thin my-8">
        <p>
          Sent code to <span className="underline">{phone}</span>.
        </p>
        <p>The code should arrive shortly.</p>
      </div>
      <Code fields={6} onChange={setCode} value={code} />
      <Button
        buttonDisabled={!codeIsValid}
        className=" w-[264px] mt-16 self-center"
        color="gray"
        onClick={() => handleVerifyCode(code)}
      >
        Verify Code
      </Button>
    </>
  );
};
