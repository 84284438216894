import { FirebaseError } from 'firebase/app';
import {
  collection,
  doc,
  DocumentSnapshot,
  onSnapshot,
  orderBy,
  query,
  Query,
  where,
} from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { NotificationType, Notification } from '../../../../global';
import { useLoadingValue } from '../utils';
import { docToJSON, firestore } from '../utils/firebase';
import { usePagination } from '../utils/usePagination';
import { useAuth } from './use-auth';
import { updateNotification } from '../db/notifications';
import dayjs from 'dayjs';

type NotificationsHook = {
  error: FirebaseError;
  hasMore: boolean;
  load: () => void;
  loading: boolean;
  reset: () => void;
  notifications: Notification[];
};

type NotificationsHookOptions = {
  direction?: 'asc' | 'desc';
  pageSize?: number;
  read?: boolean;
  types?: NotificationType[];
};

type NotificationHook = {
  error: FirebaseError;
  loading: boolean;
  notification: Notification;
  markAsRead: () => void;
};

export const useNotifications = ({
  direction = 'asc',
  pageSize = 10,
  read,
  types,
}: NotificationsHookOptions): NotificationsHook => {
  const { id } = useAuth();
  const [notificationsQuery, setNotificationsQuery] = useState<Query>();
  const { error, hasMore, load, loading, reset, values } = usePagination(
    notificationsQuery,
    pageSize
  );

  useEffect(() => {
    if (id) {
      let newQuery = query(
        collection(firestore, `users/${id}/notifications`),
        orderBy('createdAt', direction)
      );
      if (read !== undefined) {
        newQuery = query(newQuery, where('read', '==', read));
      }
      if (types !== undefined && types.length > 0) {
        newQuery = query(newQuery, where('type', 'in', types));
      }
      setNotificationsQuery(newQuery);
    } else if (notificationsQuery) {
      setNotificationsQuery(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, direction, read, types]);

  return useMemo(
    () => ({
      error,
      hasMore,
      load,
      loading,
      reset,
      notifications: values
        ? values.map((v) => prepareNotification(docToJSON<Notification>(v)))
        : values,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [error, hasMore, loading, values]
  );
};

export const useNotification = (id?: string): NotificationHook => {
  const { id: userId } = useAuth();
  const { error, loading, setError, setValue, value, setLoading } =
    useLoadingValue<DocumentSnapshot, FirebaseError>();

  useEffect(() => {
    if (id) {
      setLoading(true);
      const ref = doc(firestore, `users/${userId}/notifications`, id);
      onSnapshot(ref, setValue, setError);
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const markAsRead = async () => {
    if (!id) return;
    try {
      setLoading(true);
      await updateNotification(userId, id, { read: true });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  return useMemo(
    () => ({
      error,
      loading,
      notification: value ? docToJSON<Notification>(value) : null,
      markAsRead,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [error, loading, value]
  );
};

const prepareNotification = (notification: Notification): Notification => {
  return {
    ...notification,
    formattedCreatedAt: dayjs(notification.createdAt).format(
      'DD.MM.YYYY HH:mm'
    ),
  };
};
