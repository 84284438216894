import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Alert from '../../../components/ui/Alert';
import Card from '../../../components/ui/Card';
import Button from '../../../components/ui/Button';
import { useCheckAccess } from '../../../lib/auth/use-checkAccess';
import Loader from '../../../components/ui/Loader';
import Back from '../../../components/ui/Back';
import Content from '../../../components/layout/Content';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { useSites } from '../../../lib/hooks/use-sites';
import { faCircle, faStore } from '@fortawesome/free-solid-svg-icons';
import { Site as SiteType } from '../../../../../global';
import Edit from '../../../components/ui/Edit';
import clsx from 'clsx';
import { RejectOutletForm as RejectSiteForm } from '../../../components/retailer/RejectOutlet';
import { RejectRetailerChangesForm } from '../../../components/retailer/RejectRetailerChanges';
import RejectSite from '../../../components/site/RejectSite';
import {
  RejectOutletChanges as RejectSiteChanges,
  RejectOutletChangesForm as RejectSiteChangesForm,
} from '../../../components/retailer/RejectOutletChanges';
import { useSite } from '../../../lib/hooks/use-sites';
import { useSiteOwner } from '../../../lib/hooks/use-siteOwners';
import Map from '../../../components/map/Map';
import Layers from '../../../components/map/layers/Layers';
import IconUpdateLayer from '../../../components/map/layers/IconUpdateLayer';
import IconLayer from '../../../components/map/layers/IconLayer';
import storeIcon from '../../../assets/icons/store-solid-white.svg';

type SiteDetailsParams = 'id';

const Site = () => {
  const checkAccess = useCheckAccess();
  const { id } = useParams<SiteDetailsParams>();
  const navigate = useNavigate();
  const {
    site,
    confirmChanges: siteConfirmChanges,
    confirmRegistration: siteConfirmRegistration,
    confirmRejection: siteConfirmRejection,
    confirmChangesRejection: siteConfirmChangesRejection,
  } = useSite(id);

  const [selectedSite, setSelectedSite] = useState<SiteType>();
  const [selectedSiteOwner, setSelectedSiteOwner] = useState<string>();

  const {
    siteOwner,
    update: siteOwnerUpdate,
    error: siteOwnerError,
    loading: siteOwnerLoading,
    confirmChanges: siteOwnerConfirmChanges,
    confirmRegistration: siteOwnerConfirmRegistration,
    confirmChangesRejection: siteOwnerConfirmChangesRejection,
  } = useSiteOwner(selectedSiteOwner);

  const {
    sites,
    error: siteError,
    loading: siteLoading,
    reset: siteReset,
  } = useSites('', 'all', selectedSiteOwner, 0);

  const [showSiteAlert, setShowSiteAlert] = useState(false);
  const [showRejectSite, setShowRejectSite] = useState<boolean>(false);
  const [showRejectSiteChanges, setShowRejectSiteChanges] =
    useState<boolean>(false);
  const [showRejectSiteOwnerChange, setShowRejectSiteOwnerChange] =
    useState<boolean>(false);

  const siteLocation = useMemo(() => {
    if (!selectedSite?.location) return undefined;
    return selectedSite.location;
  }, [selectedSite]);

  const newSiteLocation = useMemo(() => {
    if (!selectedSite?.changes?.location) return undefined;
    return selectedSite.changes.location;
  }, [selectedSite]);

  useEffect(() => {
    if (siteOwnerError) {
      setShowSiteAlert(true);
    } else if (showSiteAlert) {
      setShowSiteAlert(false);
    }
    return () => {
      setShowSiteAlert(false);
    };
  }, [siteOwnerError, showSiteAlert]);

  useEffect(() => {
    if (siteError) {
      setShowSiteAlert(true);
    } else if (showSiteAlert) {
      setShowSiteAlert(false);
    }
    return () => {
      setShowSiteAlert(false);
    };
  }, [siteError, showSiteAlert]);

  useEffect(() => {
    if (site) {
      setSelectedSite(site);
    }
  }, [site]);

  useEffect(() => {
    if (site) {
      setSelectedSiteOwner(site.siteOwner.id);
    }
  }, [site]);

  const handleSelectSite = (event) => {
    const site = sites.find((o) => o.id === event.target.value);
    setSelectedSite(site);
  };

  const handleConfirmSiteOwner = async () => {
    await siteOwnerConfirmRegistration(true);
    await siteConfirmRegistration(true);
  };

  const handleConfirmSiteOwnerChanges = async () => {
    await siteOwnerConfirmChanges(true, siteOwner.changes);
    siteReset();
  };

  const handleRejectSiteOwnerChanges = async (
    data: RejectRetailerChangesForm
  ) => {
    await siteOwnerConfirmChanges(false, siteOwner.changes, data.comment);
    siteReset();
    setShowRejectSiteOwnerChange(false);
  };

  const handleConfirmSite = async () => {
    if (
      sites.filter(
        (site) =>
          site.status !== 'confirmed' && site.status !== 'changesRejected'
      ).length === 1
    ) {
      siteOwnerUpdate({ siteChanged: false });
    }
    await siteConfirmRegistration(true);
    siteReset();
  };

  const handleRejectSite = async (data: RejectSiteForm) => {
    if (
      sites.filter(
        (site) =>
          site.status !== 'confirmed' && site.status !== 'changesRejected'
      ).length === 1
    ) {
      siteOwnerUpdate({ siteChanged: false });
    }
    await siteConfirmRegistration(false, data.comment);
    siteReset();
    setShowRejectSite(false);
  };

  const handleConfirmSiteChanges = async () => {
    if (
      sites.filter(
        (site) =>
          site.status !== 'confirmed' &&
          site.status !== 'changesRejected' &&
          site.status !== 'rejected'
      ).length <= 1
    ) {
      siteOwnerUpdate({ siteChanged: false });
    }
    await siteConfirmChanges(true, site.changes);
    siteReset();
  };

  const handleRejectSiteChanges = async (data: RejectSiteChangesForm) => {
    if (
      sites.filter(
        (site) =>
          site.status !== 'confirmed' &&
          site.status !== 'changesRejected' &&
          site.status !== 'rejected'
      ).length <= 1
    ) {
      siteOwnerUpdate({ siteChanged: false });
    }

    await siteConfirmChanges(false, site.changes, data.comment);
    siteReset();
    setShowRejectSiteChanges(false);
  };

  const handleConfirmSiteRejection = async () => {
    await siteConfirmRejection();
    navigate('/sites', { replace: true });
  };

  const handleConfirmSiteOwnerChangesRejection = async () => {
    await siteOwnerConfirmChangesRejection();
  };

  const handleConfirmSiteChangesRejection = async () => {
    await siteConfirmChangesRejection();
  };

  return (
    <Content>
      <Loader show={siteOwnerLoading || siteLoading} />
      <Alert
        message={siteOwnerError && siteOwnerError.message}
        open={showSiteAlert}
        setOpen={(open) => setShowSiteAlert(open)}
        title="Error"
      />
      <Alert
        message={siteError && siteError.message}
        open={showSiteAlert}
        setOpen={(open) => setShowSiteAlert(open)}
        title="Error"
      />
      {checkAccess(['sop']) && (
        <RejectSiteChanges
          open={showRejectSiteOwnerChange}
          onReject={handleRejectSiteOwnerChanges}
          setOpen={(open) => setShowRejectSiteOwnerChange(open)}
        />
      )}
      {checkAccess(['sop']) && (
        <RejectSite
          open={showRejectSite}
          onReject={handleRejectSite}
          setOpen={(open) => setShowRejectSite(open)}
        />
      )}
      {checkAccess(['sop']) && (
        <RejectSiteChanges
          open={showRejectSiteChanges}
          onReject={handleRejectSiteChanges}
          setOpen={(open) => setShowRejectSiteChanges(open)}
        />
      )}
      {siteOwnerLoading || siteOwner ? (
        <Card>
          <div className="grid grid-cols-12">
            <Back to="/sites" className=" col-span-3" />
            <div className="col-span-6 flex justify-center items-center text-center text-xl font-bold text-lh-head-black">
              {siteOwner && (
                <FontAwesomeIcon
                  icon={faUserCircle}
                  className="mr-2 text-lh-head-black"
                />
              )}
              Site
              {siteOwner && (
                <FontAwesomeIcon
                  icon={faCircle}
                  className={clsx(
                    (siteOwner.status === 'unconfirmed' ||
                      siteOwner.status === 'changes') &&
                      'ml-2 text-yellow-500',
                    ((siteOwner.status === 'confirmed' &&
                      !siteOwner.siteChanged &&
                      siteOwner.phoneCorrect) ||
                      (siteOwner.status === 'changesRejected' &&
                        !siteOwner.siteChanged &&
                        siteOwner.phoneCorrect)) &&
                      'ml-2 text-green-600',
                    siteOwner.status === 'rejected' ||
                      ('phoneCorrect' in siteOwner &&
                        !siteOwner.phoneCorrect &&
                        'ml-2 text-red-600'),
                    siteOwner.siteChanged && 'ml-2 text-yellow-500'
                  )}
                />
              )}
            </div>
            <Edit
              to={`/siteOwners/${selectedSite?.id}`}
              className="col-span-3 flex-row-reverse"
            />
            {site?.deletionRequested && (
              <div className="col-span-full text-center p-2 text-red-500 font-thin text-sm">
                <p>Deletion request pending...</p>
              </div>
            )}
            <p className="text-lh-head-black text-lg col-span-3 col-start-2">
              Site Name:
            </p>
            <p className="text-lh-head-black text-lg col-span-8 text-left">
              {siteOwner && siteOwner.name}
            </p>
            <p className="text-lh-head-black text-lg col-span-3 col-start-2">
              First Name:
            </p>
            <p className="text-lh-head-black text-lg col-span-8 text-left">
              {siteOwner && siteOwner.firstName}
            </p>
            <p className="text-lh-head-black text-lg col-span-3 col-start-2">
              Last Name:
            </p>
            <p className="text-lh-head-black text-lg col-span-8 text-left">
              {siteOwner && siteOwner.lastName}
            </p>
            <p className="text-lh-head-black text-lg col-span-3 col-start-2">
              Phone:
            </p>
            <p
              className={clsx(
                'text-lg col-span-8 text-left text-lh-head-black',
                siteOwner && !siteOwner.phoneCorrect && 'text-red-600'
              )}
            >
              {siteOwner && siteOwner.phone}
            </p>
            {siteOwner &&
              (siteOwner.status === 'confirmed' ||
                siteOwner.status === 'changesRejected') && (
                <>
                  <p className="text-lh-head-black text-lg col-span-3 col-start-2">
                    Last order:
                  </p>
                  <p className="text-lh-head-black text-lg col-span-8 text-left">
                    {siteOwner && siteOwner.lastOrderPlacedAt
                      ? dayjs(siteOwner.lastOrderPlacedAt).format(
                          'DD.MM.YYYY HH:mm'
                        )
                      : 'No orders placed yet'}
                  </p>
                </>
              )}
            {siteOwner && siteOwner.status === 'changes' && (
              <>
                {siteOwner && siteOwner.changes && siteOwner.changes.name && (
                  <>
                    <p className="text-lh-head-black text-lg col-span-3 col-start-2 font-bold">
                      New name:{' '}
                    </p>
                    <p className="text-lh-head-black text-lg col-span-8 font-bold">
                      {siteOwner.changes && siteOwner.changes.name}
                    </p>
                  </>
                )}
                {siteOwner && siteOwner.changes && siteOwner.changes.phone && (
                  <>
                    <p className="text-lh-head-black text-lg col-span-3 col-start-2 font-bold">
                      New phone:{' '}
                    </p>
                    <p className="text-lh-head-black text-lg col-span-8 font-bold">
                      {siteOwner.changes && siteOwner.changes.phone}
                    </p>
                  </>
                )}
                {checkAccess(['sop']) && (
                  <div className="col-span-full flex justify-between">
                    <Button
                      onClick={handleConfirmSiteOwnerChanges}
                      color="green"
                      className="flex-grow"
                    >
                      Confirm
                    </Button>
                    <Button
                      onClick={() => setShowRejectSiteOwnerChange(true)}
                      color="red"
                      className="flex-grow"
                    >
                      Reject
                    </Button>
                  </div>
                )}
              </>
            )}
            <hr className="border-black mt-2 col-span-full" />
            {sites && (
              <>
                {sites.length > 1 ? (
                  <>
                    <div className="col-span-12 grid grid-cols-12 justify-center items-center text-center text-xl font-bold text-lh-head-black">
                      <h1 className="col-span-11 font-bold mt-2 text-center text-xl">
                        <FontAwesomeIcon
                          icon={faStore}
                          className="mr-2 text-lh-head-black"
                        />
                        {selectedSite && selectedSite.type
                          ? selectedSite.type
                          : 'Non'}
                      </h1>
                      <Edit
                        to={`/sites/${selectedSite?.id}`}
                        className="col-span-1 flex-row-reverse"
                      />
                    </div>
                    <select
                      className={
                        'col-span-full text-center cursor-pointer border-2 bg-gray-200 rounded-lg mt-2'
                      }
                      onChange={handleSelectSite}
                      value={selectedSite?.id}
                    >
                      {sites &&
                        sites.map((site) => {
                          return (
                            <option key={site.id} value={site.id}>
                              {site.name}
                            </option>
                          );
                        })}
                    </select>
                  </>
                ) : (
                  <div className="col-span-12 grid grid-cols-12 items-center text-center text-xl font-bold text-lh-head-black">
                    <h1 className="col-span-11 font-bold mt-2 text-center text-xl">
                      <FontAwesomeIcon
                        icon={faStore}
                        className="mr-2 text-lh-head-black"
                      />
                      {selectedSite && selectedSite.type
                        ? selectedSite.type
                        : 'non'}
                    </h1>
                    <Edit
                      to={`/sites/${selectedSite?.id}`}
                      className="col-span-1 flex-row-reverse"
                    />
                  </div>
                )}
                <div className="col-span-full grid grid-cols-12 justify-center mt-2">
                  <p className="text-lh-head-black text-lg col-span-3 col-start-2">
                    Name:
                  </p>
                  <p className="text-lh-head-black text-lg col-span-8 text-left">
                    {selectedSite && selectedSite.name}
                  </p>
                  <p className="text-lh-head-black text-lg col-span-3 col-start-2">
                    TAE:
                  </p>
                  <p className="text-lh-head-black text-lg col-span-8 text-left">
                    {selectedSite && selectedSite.primaryTAE.name}
                  </p>
                </div>
                <hr className="border-black mt-2 col-span-full" />
                <h1 className="col-span-12 font-bold mt-2 text-center text-xl">
                  Metrics
                </h1>
                <div className="col-span-full grid grid-cols-12 justify-center mt-2">
                  <p className="text-lh-head-black text-lg col-span-11 col-start-2 font-bold">
                    Leads
                  </p>
                  <p className="text-lh-head-black text-lg col-span-3 col-start-2">
                    Volume:
                  </p>
                  <p className="text-lh-head-black text-lg col-span-8 text-left">
                    {selectedSite && selectedSite.fulfilledVolume}
                  </p>
                  <p className="text-lh-head-black text-lg col-span-3 col-start-2">
                    Amount:
                  </p>
                  <p className="text-lh-head-black text-lg col-span-8 text-left">
                    {selectedSite && selectedSite.leadsAmountFulfilled}
                  </p>
                </div>
                {selectedSite && selectedSite.status === 'changes' && (
                  <>
                    <hr className="border-black mt-2 col-span-full" />
                    <h1 className="col-span-12 font-bold mt-2 text-center text-xl">
                      Changes
                    </h1>
                    {selectedSite.changes?.name && (
                      <>
                        <p className="col-span-5 col-start-2 font-bold mt-2">
                          New name:
                        </p>
                        <p className="col-span-6 font-bold mt-2">
                          {selectedSite.changes?.name}
                        </p>
                      </>
                    )}
                    {selectedSite.changes?.type && (
                      <>
                        <p className="col-span-5 col-start-2 font-bold mt-2">
                          New Type:
                        </p>
                        <p className="col-span-6 font-bold mt-2">
                          {selectedSite.changes?.type}
                        </p>
                      </>
                    )}
                  </>
                )}
                <Map className="col-span-full h-56 mt-4">
                  <Layers>
                    {newSiteLocation ? (
                      <IconUpdateLayer
                        newLocation={newSiteLocation}
                        oldLocation={siteLocation}
                        icon={storeIcon}
                        name={'Location'}
                      />
                    ) : (
                      <IconLayer
                        location={siteLocation}
                        icon={storeIcon}
                        name={'Location'}
                      />
                    )}
                  </Layers>
                </Map>
                {checkAccess(['sop']) &&
                  selectedSite &&
                  selectedSite.status === 'unconfirmed' &&
                  ['confirmed', 'changes'].includes(siteOwner.status) && (
                    <div className="col-span-full flex justify-between">
                      <Button
                        onClick={handleConfirmSite}
                        color="green"
                        className="flex-grow"
                      >
                        Confirm
                      </Button>
                      <Button
                        onClick={() => setShowRejectSite(true)}
                        color="red"
                        className="flex-grow"
                      >
                        Reject
                      </Button>
                    </div>
                  )}
                {checkAccess(['sop']) &&
                  selectedSite &&
                  selectedSite.status === 'changes' &&
                  ['confirmed', 'changes', 'changesRejected'].includes(
                    siteOwner.status
                  ) && (
                    <div className="col-span-full flex justify-between">
                      <Button
                        onClick={handleConfirmSiteChanges}
                        color="green"
                        className="flex-grow"
                      >
                        Confirm
                      </Button>
                      <Button
                        onClick={() => setShowRejectSiteChanges(true)}
                        color="red"
                        className="flex-grow"
                      >
                        Reject
                      </Button>
                    </div>
                  )}

                {checkAccess(['tae']) &&
                  selectedSite &&
                  selectedSite.status === 'rejected' && (
                    <div className="col-span-full flex flex-col">
                      <h2>
                        The Site has been rejected with the following reason:{' '}
                      </h2>
                      <p>{selectedSite && selectedSite.rejectionComment}</p>
                      <p>
                        Contact your SOP if you have further questions about
                        this.
                      </p>
                      <Button onClick={handleConfirmSiteRejection} color="red">
                        Confirm Rejection
                      </Button>
                    </div>
                  )}
                {checkAccess(['tae']) &&
                  selectedSite &&
                  selectedSite.status === 'changesRejected' &&
                  siteOwner.status !== 'changesRejected' && (
                    <div className="col-span-full flex flex-col">
                      <h2>
                        The changes to the Site have been rejected with the
                        following reason:{' '}
                      </h2>
                      <p>{selectedSite && selectedSite.rejectionComment}</p>
                      <p>
                        Contact your SOP if you have further questions about
                        this.
                      </p>
                      <Button
                        onClick={handleConfirmSiteChangesRejection}
                        color="red"
                      >
                        Confirm Rejection
                      </Button>
                    </div>
                  )}
              </>
            )}
            {siteOwner &&
              siteOwner.status === 'unconfirmed' &&
              selectedSite &&
              selectedSite.status === 'unconfirmed' &&
              checkAccess(['sop']) && (
                <div className="col-span-full flex justify-between mt-6">
                  <Button
                    onClick={handleConfirmSiteOwner}
                    color="green"
                    className="flex-grow"
                  >
                    Confirm
                  </Button>
                  <Button
                    onClick={() => setShowRejectSite(true)}
                    color="red"
                    className="flex-grow"
                  >
                    Reject
                  </Button>
                </div>
              )}
            {checkAccess(['tae']) &&
              siteOwner &&
              siteOwner.status === 'changesRejected' && (
                <div className="col-span-full flex flex-col mt-6">
                  <h2>
                    The changes to the Site Owner have been rejected with the
                    following reason:{' '}
                  </h2>
                  <p>{siteOwner && siteOwner.rejectionComment}</p>
                  <p>
                    Contact your SOP if you have further questions about this.
                  </p>
                  <Button
                    onClick={handleConfirmSiteOwnerChangesRejection}
                    color="red"
                  >
                    Confirm Rejection
                  </Button>
                </div>
              )}
            {checkAccess(['tae']) &&
              siteOwner &&
              siteOwner.status === 'rejected' && (
                <div className="col-span-full flex flex-col mt-6">
                  <h2>
                    The Site Owner has been rejected with the following reason:{' '}
                  </h2>
                  <p>{siteOwner && siteOwner.rejectionComment}</p>
                  <p>
                    Contact your SOP if you have further questions about this.
                  </p>
                  <Button onClick={handleConfirmSiteRejection} color="red">
                    Confirm Rejection
                  </Button>
                </div>
              )}
            {checkAccess(['tae']) &&
              selectedSite &&
              (selectedSite.status === 'confirmed' ||
                selectedSite.status === 'changesRejected') && (
                <div className="col-span-full flex flex-col mt-6">
                  <Button
                    onClick={() => navigate('/siteVisits/' + selectedSite.id)}
                    color="blue"
                  >
                    Start Visit
                  </Button>
                </div>
              )}
          </div>
        </Card>
      ) : (
        <Card>Site not found!</Card>
      )}
    </Content>
  );
};

export default Site;
