import { addDoc, collection } from 'firebase/firestore';
import { CreateSiteVisitData } from '../../../../global';
import { firestore } from '../utils/firebase';

export const addSiteVisit = async (
  data: CreateSiteVisitData
): Promise<void> => {
  const ref = collection(firestore, 'siteVisits');
  await addDoc(ref, data);
};
