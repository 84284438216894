import { useEffect, useState } from 'react';

let globalState = {};
let listeners = [];
let actions = {};

export type GlobalStore = [
  globalState: any,
  dispatch: (actionIdentifier: string, payload: any) => void
];

type GlobalStoreOptions = {
  shouldListen?: boolean;
};

const useStore = (
  options: GlobalStoreOptions = { shouldListen: true }
): GlobalStore => {
  const setState = useState(globalState)[1];

  const dispatch = (actionIdentifier, payload) => {
    const newState = actions[actionIdentifier](globalState, payload);
    globalState = { ...globalState, ...newState };

    for (const listener of listeners) {
      listener(globalState);
    }
  };

  useEffect(() => {
    if (options.shouldListen) {
      listeners.push(setState);
    }

    return () => {
      if (options.shouldListen) {
        listeners = listeners.filter((li) => li !== setState);
      }
    };
  }, [setState, options]);

  return [globalState, dispatch];
};

export default useStore;

export const initStore = (userActions, initialState) => {
  if (initialState) {
    globalState = { ...globalState, ...initialState };
  }
  actions = { ...actions, ...userActions };
};
