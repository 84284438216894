import { useMemo } from 'react';
import { Role } from '../../../../global';
import { useAuth } from '../hooks/use-auth';

type CheckAccessActionHook = (requiredRoles: Role[]) => boolean;

export const useCheckAccess = (): CheckAccessActionHook => {
  const { roles } = useAuth();

  const checkAccess = (requiredRoles) => {
    if (!roles) return false;
    return roles.some((role) => requiredRoles.includes(role));
  };

  const resArray: CheckAccessActionHook = checkAccess;
  return useMemo<CheckAccessActionHook>(() => resArray, [resArray]);
};