import { FirebaseError } from 'firebase/app';
import {
  collection,
  doc,
  FirestoreError,
  onSnapshot,
  query,
  Query,
  Unsubscribe,
  updateDoc,
  where,
} from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { docToJSON, firestore } from '../utils/firebase';
import { usePagination } from '../utils/usePagination';
import { useAuth } from './use-auth';
import { InventoryType, ProductsType } from '../../../../global';
import { setInitialStock } from '../db/inventories';

type InventoriesHook = {
  error: FirebaseError;
  hasMore: boolean;
  load: () => void;
  loading: boolean;
  reset: () => void;
  inventories: InventoryType[];
};

type InventoriesHookOptions = {
  day: number;
  month: number;
  year: number;
  pageSize?: number;
};

export const useInventories = ({
  day,
  month,
  year,
  pageSize = 10,
}: InventoriesHookOptions): InventoriesHook => {
  const { id } = useAuth();
  const [inventoriesQuery, setInventoriesQuery] = useState<Query>();
  const { error, hasMore, load, loading, reset, values } = usePagination(
    inventoriesQuery,
    pageSize
  );

  useEffect(() => {
    if (id && month && year) {
      setInventoriesQuery(
        query(
          collection(firestore, 'inventories'),
          where('userIds', 'array-contains', id),
          where('year', '==', year),
          where('month', '==', month),
          where('day', '==', day)
        )
      );
    } else if (inventoriesQuery) {
      setInventoriesQuery(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, year, month, day]);

  useEffect(() => {
    console.log(error);
  }, [error]);

  return useMemo(
    () => ({
      error,
      hasMore,
      load,
      loading,
      reset,
      inventories: values
        ? values.map((v) => docToJSON(v) as InventoryType)
        : values,
    }),
    [error, hasMore, load, loading, reset, values]
  );
};

export const useInventory = (id?: string) => {
  const [loading, setLoading] = useState(false);
  const [inventory, setInventory] = useState<InventoryType | undefined>();
  const [error, setError] = useState<FirestoreError>();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (id) {
      setLoading(true);
      const ref = doc(firestore, 'inventories', id);
      unsubscribe = onSnapshot(
        ref,
        (doc) => {
          if (doc.exists()) {
            setInventory(docToJSON(doc) as InventoryType);
          } else {
            setInventory(undefined);
          }
          setLoading(false);
        },
        (error: FirestoreError) => {
          setLoading(false);
          setError(error);
        }
      );
    }
    return unsubscribe;
  }, [id]);

  const update = async (data: any) => {
    if (id) {
      try {
        setLoading(true);
        const ref = doc(firestore, 'inventories', id);
        await updateDoc(ref, data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);
        setError(err);
      }
    }
  };

  const setInitial = async (products: ProductsType) => {
    if (id) {
      try {
        setLoading(true);
        setInitialStock(id, products);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);
        setError(err);
      }
    }
  };

  useEffect(() => {
    console.log(error);
  }, [error]);

  return {
    inventory,
    error,
    loading,
    update,
    setInitial,
  };
};
