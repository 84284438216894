import { Navigate, useLocation } from 'react-router-dom';
import { Role } from '../../../../global';
import { useCheckAccess } from '../../lib/auth/use-checkAccess';
import { useAuth } from '../../lib/hooks/use-auth';

type ProtectedRouteProps = {
  roles?: Role[];
  children?: JSX.Element;
};

const Protected = ({ roles, children }: ProtectedRouteProps) => {
  const { user, loading, roles: userRoles } = useAuth();
  const location = useLocation();
  const checkAccess = useCheckAccess();
  if (!loading && user === null) {
    return <Navigate to="/enter" state={{ from: location }} />;
  }
  if (
    !loading &&
    userRoles &&
    userRoles.length > 0 &&
    roles &&
    roles.length &&
    !checkAccess(roles)
  ) {
    return <Navigate to="/home" state={{ from: location }} />;
  }
  return children;
};

export default Protected;
