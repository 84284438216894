import { FirebaseOptions, initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, PhoneAuthProvider } from 'firebase/auth';
import {
  DocumentSnapshot,
  QueryDocumentSnapshot,
  serverTimestamp,
  Timestamp,
  persistentLocalCache,
  FirestoreSettings,
  initializeFirestore,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAnalytics } from 'firebase/analytics';
import { getMessaging, isSupported, Messaging } from 'firebase/messaging';
import { getStorage } from 'firebase/storage';

const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const functions = getFunctions(app, 'europe-west6');
const storage = getStorage(app);


// Initialize Firestore with Persistent Local Cache
const firestoreSettings: FirestoreSettings = {
  localCache: persistentLocalCache()
};

const firestore = initializeFirestore(app, firestoreSettings);

/*
if (window.location.hostname === 'localhost') {
  console.log(
    'testing locally -- hitting local functions and firestore emulators'
  );
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectFirestoreEmulator(firestore, 'localhost', 8080);
  connectAuthEmulator(auth, 'http://localhost:9099');
}
*/

// Firestore exports
const timestamp = serverTimestamp;
const fromMillis = Timestamp.fromMillis;

// Google Analytics
const analytics = getAnalytics(app);

// Firebase cloud messaging
let messaging: Messaging | undefined;
let messagingIsSupported: boolean | undefined;

isSupported().then((supported) => {
  messagingIsSupported = supported;
  if (!supported) {
    return;
  }
  messaging = getMessaging(app);
});

// Functions exports

const updatePhone = httpsCallable(functions, 'updatePhone');
const updateRetailerPhone = httpsCallable(functions, 'updateRetailerPhone');
const getScopedTypesenseKey = httpsCallable(functions, 'getScopedTypesenseKey');
const getScopedTypesenseKeyUserIds = httpsCallable(
  functions,
  'getScopedTypesenseKeyUserIds'
);

const googleAuthProvider = new GoogleAuthProvider();
const phoneAuthProvider = new PhoneAuthProvider(auth);

/**
 * Converts a firestore document to JSON
 * @param  {DocumentSnapshot} doc
 */
function docToJSON<T>(doc: DocumentSnapshot | QueryDocumentSnapshot) {
  const data = doc.data();
  if (!data) return null;
  Object.keys(data).forEach((key) => {
    if (data[key] instanceof Timestamp) {
      data[key] = data[key].toMillis();
    }
  });
  return {
    id: doc.id,
    ...data,
  } as any as T;
}

export {
  auth,
  firestore,
  updatePhone,
  updateRetailerPhone,
  getScopedTypesenseKey,
  getScopedTypesenseKeyUserIds,
  timestamp,
  fromMillis,
  analytics,
  docToJSON,
  googleAuthProvider,
  phoneAuthProvider,
  messaging,
  messagingIsSupported,
  storage,
};
