import dayjs from 'dayjs';
import { initStore } from '../hooks/use-store';

const configureVisitsDateStore = () => {
  const actions = {
    SET_DATE_VISITS: (curState, dateVisits: dayjs.Dayjs) => {
      return { dateVisits };
    },
    ADD_DAY_VISITS: (curState) => {
      return { dateVisits: curState.dateVisits.add(1, 'day') };
    },
    SUBTRACT_DAY_VISITS: (curState) => {
      return { dateVisits: curState.dateVisits.subtract(1, 'day') };
    },
  };
  initStore(actions, { dateVisits: dayjs() });
};

export default configureVisitsDateStore;
