import dayjs from 'dayjs';
import { FirebaseError } from 'firebase/app';
import {
  collection,
  doc,
  FirestoreError,
  onSnapshot,
  orderBy,
  Query,
  query,
  Unsubscribe,
  where,
} from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import {
  Lead,
  LeadStatus,
  CreateLeadData,
  UpdateLeadData,
  ArchiveStatus,
} from '../../../../global';
import { createLead, updateLead } from '../db/leads';
import { firestore, docToJSON } from '../utils/firebase';
import { usePagination } from '../utils/usePagination';
import { useAuth } from './use-auth';

type LeadsHook = {
  error: FirebaseError;
  hasMore: boolean;
  load: () => void;
  loading: boolean;
  reset: () => void;
  leads: Lead[];
};

type LeadsHookOptions = {
  status: LeadStatus;
  pageSize?: number;
  direction?: 'asc' | 'desc';
  archiveStatus?: ArchiveStatus;
};

export const useLeads = ({
  status,
  pageSize = 10,
  direction = 'asc',
  archiveStatus,
}: LeadsHookOptions): LeadsHook => {
  const { id } = useAuth();
  const [leadsQuery, setLeadsQuery] = useState<Query>();
  const { error, hasMore, load, loading, reset, values } = usePagination(
    leadsQuery,
    pageSize
  );

  useEffect(() => {
    if (id) {
      if (archiveStatus === 'distributor') {
        setLeadsQuery(
          query(
            collection(firestore, 'leads'),
            where('userIds', 'array-contains', id),
            where('status', 'in', status),
            where('distributor', '!=', null),
            orderBy('distributor', direction),
            orderBy('createdAt', direction)
          )
        );
      } else if (archiveStatus === 'outlet') {
        setLeadsQuery(
          query(
            collection(firestore, 'leads'),
            where('userIds', 'array-contains', id),
            where('status', 'in', status),
            where('distributor', '==', null),
            orderBy('createdAt', direction)
          )
        );
      } else {
        setLeadsQuery(
          query(
            collection(firestore, 'leads'),
            where('userIds', 'array-contains', id),
            where('status', 'in', status),
            orderBy('createdAt', direction)
          )
        );
      }
    } else if (leadsQuery) {
      setLeadsQuery(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, status, archiveStatus, direction]);

  useEffect(() => {
    console.log(error);
  }, [error]);

  return useMemo(
    () => ({
      error,
      hasMore,
      load,
      loading,
      reset,
      leads: values
        ? values.map((v) => prepareLead(docToJSON(v) as Lead))
        : values,
    }),
    [error, hasMore, load, loading, reset, values]
  );
};

export const useLead = (id?: string) => {
  const [loading, setLoading] = useState(false);
  const [lead, setLead] = useState<Lead | undefined>();
  const [error, setError] = useState<FirestoreError>();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (id) {
      setLoading(true);
      const ref = doc(firestore, 'leads', id);
      unsubscribe = onSnapshot(
        ref,
        (doc) => {
          if (doc.exists()) {
            setLead(docToJSON(doc) as Lead);
          } else {
            setLead(undefined);
          }
          setLoading(false);
        },
        (error: FirestoreError) => {
          setLoading(false);
          setError(error);
        }
      );
    }
    return unsubscribe;
  }, [id]);

  const create = async (data: CreateLeadData) => {
    try {
      setLoading(true);
      await createLead(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
      setError(err);
    }
  };

  const update = async (data: UpdateLeadData) => {
    if (!id) throw new Error('No lead selected');
    try {
      setLoading(true);
      await updateLead(id, data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
      setError(err);
    }
  };

  useEffect(() => {
    console.log(error);
  }, [error]);

  return {
    lead,
    error,
    loading,
    create,
    update,
  };
};

const prepareLead = (lead: Lead): Lead => {
  let totalOrdered = 0;
  Object.keys(lead.products).forEach(
    (key) =>
      (totalOrdered += !isNaN(+lead.products[key]) ? +lead.products[key] : 0)
  );
  let totalDelivered = 0;
  if (lead.productsDelivered)
    Object.keys(lead.productsDelivered).forEach(
      (key) =>
        (totalDelivered += !isNaN(+lead.productsDelivered[key])
          ? +lead.productsDelivered[key]
          : 0)
    );
  let color = '';
  if (lead.status === 'pending' || lead.status === 'accepted') {
    const timeDiff = dayjs().diff(lead.createdAt, 'hours');
    if (lead.status === 'pending' && timeDiff > 12) {
      color = 'red';
    } else if (lead.status === 'accepted' && timeDiff > 48) {
      color = 'red';
    }
  }
  const siteName = lead.site.name;
  return {
    ...lead,
    totalOrdered,
    totalDelivered,
    color,
    siteName,
  };
};
