import dayjs from 'dayjs';
import { initStore } from '../hooks/use-store';

const configureDateInventoryStore = () => {
  const actions = {
    SET_DATE_INVENTORY: (curState, dateInventory: dayjs.Dayjs) => {
      return { dateInventory };
    },
    ADD_DAY_INVENTORY: (curState) => {
      return { dateInventory: curState.dateInventory.add(1, 'day') };
    },
    SUBTRACT_DAY_INVENTORY: (curState) => {
      return { dateInventory: curState.dateInventory.subtract(1, 'day') };
    },
  };
  initStore(actions, { dateInventory: dayjs() });
};

export default configureDateInventoryStore;
