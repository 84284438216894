import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Confirm } from '../forms/Confirm';
import Textarea from '../forms/Textarea';
import Button from '../ui/Button';
import Modal from '../ui/Modal';

export type RejectOutletForm = {
  comment: string;
};

type RejectOutletProps = {
  open: boolean;
  onReject: SubmitHandler<RejectOutletForm>;
  setOpen: (boolean) => void;
};

export const RejectOutlet = ({
  open,
  onReject,
  setOpen,
}: RejectOutletProps) => {
  const { register, handleSubmit } = useForm<RejectOutletForm>();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [block, setBlock] = useState(false);

  const handleOnConfirm = () => {
    setBlock(false);
    setShowConfirmation(false);
    handleSubmit(onReject)();
  };

  const handleOnCancel = () => {
    setBlock(false);
    setShowConfirmation(false);
  };

  return (
    <Modal open={open} setOpen={setOpen} block={block}>
      <Confirm
        title="Are you sure you want to reject the Outlet?"
        open={showConfirmation}
        onConfirm={handleOnConfirm}
        onCancel={handleOnCancel}
        setOpen={(open) => {
          setShowConfirmation(open);
          setBlock(open);
        }}
      />
      <form name="rejectOutletForm" className="flex flex-col">
        <label className="font-bold mb-2">Comment: </label>
        <Textarea
          name="comment"
          register={register}
          options={{ required: true }}
          cols={30}
          rows={5}
          className="mb-2"
        />
      </form>
      <Button
        text="Reject"
        color="red"
        className="w-full"
        onClick={() => {
          setBlock(true);
          setShowConfirmation(true);
        }}
      />
    </Modal>
  );
};
