import React, { useEffect, useMemo, useState } from 'react';
import useInterval from '../../lib/utils/useInterval';

type CountdownProps = {
  seconds: number;
  countdownRunning: boolean;
  setCountdownRunning: (value: boolean) => void;
};

export const Countdown: React.FC<CountdownProps> = ({
  seconds = 0,
  countdownRunning,
  setCountdownRunning,
}) => {
  const [countdown, setCountdown] = useState<number>(seconds);

  useEffect(() => {
    setCountdown(seconds);
  }, [seconds]);

  useEffect(() => {
    if (countdown <= 0) {
      setCountdownRunning(false);
    }
  }, [countdown, setCountdownRunning]);

  useEffect(() => {
    if (countdownRunning) {
      setCountdown(seconds);
    }
  }, [countdownRunning, seconds]);

  const countdownDelay = useMemo(
    () => (countdown <= 0 ? null : 1000),
    [countdown]
  );

  useInterval(() => {
    setCountdown(countdown - 1);
  }, countdownDelay);

  return <span>{countdown}</span>;
};
