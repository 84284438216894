import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { Icon, Style } from 'ol/style';
import { useEffect } from 'react';
import { useMap } from '../../../lib/hooks/use-map';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Location } from '../../../../../global';
import { transform } from 'ol/proj';

interface IconLayerProps {
  location: Location;
  icon: string;
  name: string;
}

const IconLayer = ({ location, icon, name }: IconLayerProps) => {
  const { map } = useMap();

  useEffect(() => {
    if (!map || !location) return;

    const coords = transform(
      [location.lng, location.lat],
      'EPSG:4326',
      'EPSG:3857'
    );

    const feature = new Feature({
      geometry: new Point(coords),
      name,
    });

    feature.setStyle(
      new Style({
        image: new Icon({
          color: '#000000',
          crossOrigin: 'anonymous',
          // For Internet Explorer 11
          src: icon,
        }),
      })
    );

    const layer = new VectorLayer({
      source: new VectorSource({
        features: [feature],
      }),
    });
    map.addLayer(layer);

    map.getView().setCenter(coords);

    return () => {
      if (map) {
        map.removeLayer(layer);
      }
    };
  }, [location, icon, name]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default IconLayer;
