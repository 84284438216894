import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../lib/hooks/use-auth';
import { useEffect } from 'react';
import Button from '../../components/ui/Button';
import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';

const Enter = () => {
  const { user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const from = location.state?.from?.pathname || '/';

  useEffect(() => {
    if (user !== null) navigate(from, { replace: true });
    serviceWorkerRegistration.forceSWupdate();
  }, [user, navigate, from]);

  return (
    <div className="flex justify-center pt-20">
      <div className="flex flex-col space-y-2 pt-10">
        <Button onClick={() => navigate('phone')}>Login with Phone</Button>
        <Button onClick={() => navigate('google')}>Login with Google</Button>
      </div>
    </div>
  );
};

export default Enter;
