import {
  faCheckCircle,
  faClock,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DivertDelivery from '../../../components/deliveries/DivertDelivery';
import RejectDelivery from '../../../components/deliveries/RejectDelivery';
import { Confirm } from '../../../components/forms/Confirm';
import Content from '../../../components/layout/Content';
import Alert from '../../../components/ui/Alert';
import Back from '../../../components/ui/Back';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import Loader from '../../../components/ui/Loader';
import { useCheckAccess } from '../../../lib/auth/use-checkAccess';
import { useDelivery } from '../../../lib/hooks/use-deliveries';

type DeliveryDetailsParams = 'id';

const Delivery = () => {
  const checkAccess = useCheckAccess();
  const { id } = useParams<DeliveryDetailsParams>();
  const navigate = useNavigate();
  const { confirm, delivery, error, loading, reject } = useDelivery(id);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [showReject, setShowReject] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showDivert, setShowDivert] = useState<boolean>(false);

  const isClaimed = useMemo(
    () =>
      delivery?.distributor?.id !== delivery?.originalDistributor?.id &&
      delivery.status === 'claimed',
    [delivery]
  );

  const isReassigned = useMemo(
    () =>
      delivery?.distributor?.id !== delivery?.originalDistributor?.id &&
      delivery.status === 'reassigned',
    [delivery]
  );

  useEffect(() => {
    if (error) {
      setShowAlert(true);
    }
  }, [error]);

  const handleConfirm = () => {
    confirm();
    navigate('/deliveries', { replace: true });
  };

  const handleReject = (data) => {
    reject(data.comment);
    navigate('/deliveries', { replace: true });
  };

  const handleDiversion = (data) => {
    reject(data.comment);
    setShowDivert(false);
  };

  return (
    <Content>
      <Alert
        message={error && error.message}
        open={showAlert}
        setOpen={(open) => setShowAlert(open)}
        title="Error"
      />
      {checkAccess(['sop']) && (
        <Confirm
          title="Are you sure you want to confirm the delivery?"
          open={showConfirm}
          onConfirm={handleConfirm}
          onCancel={() => setShowConfirm(false)}
          setOpen={(open) => {
            setShowConfirm(open);
          }}
        />
      )}
      {checkAccess(['sop']) && (
        <>
          <RejectDelivery
            open={showReject}
            onReject={handleReject}
            setOpen={(open) => setShowConfirm(open)}
          />
          <DivertDelivery
            open={showDivert}
            onReject={handleDiversion}
            setOpen={(open) => setShowDivert(open)}
          />
        </>
      )}
      <Card>
        <Back to="/deliveries" />
        <Loader show={loading} />
        {delivery ? (
          <>
            <div className="flex justify-between">
              <div>
                <h2 className="font-bold text-lh-head-black">
                  {delivery?.distributor?.name}
                </h2>
                <p>
                  {dayjs(delivery?.deliveryDate.toString()).format(
                    'DD.MM.YYYY HH:mm'
                  )}
                </p>
              </div>
              <div className="col-span-2 flex flex-col items-center font-bold text-lg text-lh-head-black">
                <p>Status</p>
                {['open', 'claimed'].includes(delivery.status) && (
                  <FontAwesomeIcon icon={faClock} className="text-2xl mt-0" />
                )}
                {delivery.status === 'confirmed' && (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-2xl mt-0 text-green-600"
                  />
                )}
                {delivery.status === 'rejected' && (
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    className="text-2xl mt-0 text-red-600"
                  />
                )}
              </div>
            </div>
            <hr className="mt-2 mb-2" />
            <div className="flex justify-between mb-2">
              <h2 className="font-bold text-lh-head-black">Products</h2>
              <h2 className="font-bold text-lh-head-black">Quantity</h2>
            </div>
            <div className="flex justify-between">
              <h3 className="text-lh-text-black">
                {delivery.productNameInternal}
              </h3>
              <p>
                {delivery.quantity}{' '}
                <span className="text-gray-500 font-thin">tons</span>
              </p>
            </div>
            <hr className="mb-2 mt-2" />
            <div className="flex justify-between mb-2">
              <h3 className="text-lh-head-black font-bold">
                LOGON Order Number
              </h3>
              <p className="text-lh-text-black">{delivery.LOGONOrderNumber}</p>
            </div>
            <div className="flex justify-between mb-2">
              <h3 className="text-lh-head-black font-bold">
                Truck Plate Number
              </h3>
              <p className="text-lh-text-black">{delivery.truckPlateNumber}</p>
            </div>
            <h2 className="font-bold mb-2 text-lh-head-black">Driver Name</h2>
            <p className="mb-2 text-lh-text-black">{delivery.driver?.name}</p>
            <h2 className="font-bold mb-2 text-lh-head-black">Driver Phone</h2>
            <p className="mb-2 text-lh-text-black">{delivery.driver?.phone}</p>
            {isClaimed && (
              <details className="mt-5 duration-300 text-lh-text-black">
                <summary className="bg-inherit px-5 py-3 cursor-pointer text-sm text-yellow-500 list-none">
                  This delivery has been manually claimed!
                </summary>
                <div className="bg-white px-5 py-3 border-gray-300 text-sm font-light rounded-3xl flex justify-between">
                  <h2>Original recipient:</h2>
                  <p>{delivery.originalDistributor?.name}</p>
                </div>
              </details>
            )}
            {isReassigned && (
              <details className="mt-5 duration-300 text-lh-text-black">
                <summary className="bg-inherit px-5 py-3 cursor-pointer text-sm text-yellow-500 list-none">
                  This delivery has been manually reassigned!
                </summary>
                <div className="bg-white px-5 py-3 border-gray-300 text-sm font-light rounded-3xl flex justify-between">
                  <h2>Original recipient:</h2>
                  <p>{delivery.originalDistributor?.name}</p>
                </div>
              </details>
            )}
            {['open', 'claimed', 'reassigned'].includes(delivery.status) &&
              checkAccess(['sop']) && (
                <>
                  <Button
                    onClick={() => setShowConfirm(true)}
                    className="w-full mt-2 py-2"
                    color="green"
                    type="button"
                  >
                    Confirm Delivery
                  </Button>
                  <Button
                    onClick={() => setShowReject(true)}
                    className="w-full mt-2 py-2"
                    color="red"
                    type="button"
                  >
                    Reject Delivery
                  </Button>
                </>
              )}
            {delivery.status === 'confirmed' && checkAccess(['sop']) && (
              <>
                <Button
                  onClick={() => setShowDivert(true)}
                  className="w-full mt-2 py-2"
                  color="red"
                  type="button"
                >
                  Delivery diverted
                </Button>
              </>
            )}
          </>
        ) : (
          <div className="flex justify-center">
            <p className="text-lh-text-black">No Delivery Found</p>
          </div>
        )}
      </Card>
    </Content>
  );
};

export default Delivery;
