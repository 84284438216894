interface InputProps {
  className?: string;
  value: string;
  color?: 'green' | 'red' | 'blue' | 'gray';
  disabled?: boolean;
}

const Submit = ({
  className,
  value,
  color = 'gray',
  disabled = false,
}: InputProps) => (
  <input
    type="submit"
    name="submit"
    value={value}
    disabled={disabled}
    className={`${className} mt-2 py-2 text-center border rounded-md text-white cursor-pointer font-bold disabled:opacity-50 bg-${color}-600 hover:bg-${color}-400`}
  />
);

export default Submit;
