import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Timestamp } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import {
  DefaultValues,
  RegisterOptions,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useNavigate } from 'react-router';
import { OutletChannel } from '../../../../global';
import { Confirm } from '../../components/forms/Confirm';
import Input from '../../components/forms/Input';
import SearchableSelect from '../../components/forms/SearchableSelect';
import Select from '../../components/forms/Select';
import Content from '../../components/layout/Content';
import Alert from '../../components/ui/Alert';
import Back from '../../components/ui/Back';
import Button from '../../components/ui/Button';
import Card from '../../components/ui/Card';
import Loader from '../../components/ui/Loader';
import { useOutlets } from '../../lib/hooks/use-outlets';
import { useSale } from '../../lib/hooks/use-sales';
import { useUser } from '../../lib/hooks/use-user';

type NewOrderFormValues = {
  classic: number;
  supaset: number;
  supafixc0: number;
  supafixc1: number;
  watershield: number;
  pop: number;
  //tempProduct2 tempProduct2: number;
  //tempProduct3 tempProduct3: number;
  //tempProduct4 tempProduct4: number;
  address: string;
  buyerPhone: string;
  buyerName: string;
  channel: OutletChannel;
  outlet: any;
};

const defaultValues: DefaultValues<NewOrderFormValues> = {
  buyerPhone: '',
  buyerName: '',
};

const CHANNEL_TYPES = [
  {
    key: 'blockmaker',
    label: 'Blockmaker',
    value: 'blockmaker',
  },
  {
    key: 'blockmaker_retailer',
    label: 'Blockmaker / Retailer',
    value: 'blockmaker_retailer',
  },
  {
    key: 'container',
    label: 'Container',
    value: 'container',
  },
  {
    key: 'neighborhood_shop',
    label: 'Neighborhood Shop',
    value: 'neighborhood_shop',
  },
  {
    key: 'palleter',
    label: 'Palleter',
    value: 'palleter',
  },
  {
    key: 'tiel_seller',
    label: 'Tile Seller',
    value: 'tile_seller',
  },
  {
    key: 'end_user',
    label: 'End User',
    value: 'end_user',
  },
];

const NewSale = () => {
  const navigate = useNavigate();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { user } = useUser();
  const { error: outletsError, outlets } = useOutlets({
    pageSize: 0,
    active: true,
  });
  const { create, loading } = useSale();
  const {
    register,
    handleSubmit,
    formState: { isSubmitSuccessful, errors, isValid },
    getValues,
    control,
  } = useForm({ defaultValues, mode: 'all', reValidateMode: 'onChange' });
  const [showOutletsAlert, setShowOutletsAlert] = useState(false);
  const [newBuyer, setNewBuyer] = useState(null);

  const moreThanZero = (): boolean => {
    const classic = getValues('classic');
    const supafixc0 = getValues('supafixc0');
    const supafixc1 = getValues('supafixc1');
    const supaset = getValues('supaset');
    const watershield = getValues('watershield');
    const pop = getValues('pop');
    //tempProduct2 const tempProduct2 = getValues('tempProduct2');
    //tempProduct3 const tempProduct3 = getValues('tempProduct3');
    //tempProduct4 const tempProduct4 = getValues('tempProduct4');
    return (
      (isNaN(classic) ? 0 : classic) +
        (isNaN(supafixc0) ? 0 : supafixc0) +
        (isNaN(supafixc1) ? 0 : supafixc1) +
        (isNaN(supaset) ? 0 : supaset) +
        (isNaN(watershield) ? 0 : watershield) +
      (isNaN(pop) ? 0 : pop) >
      //tempProduct2 (isNaN(tempProduct2) ? 0 : tempProduct2) +
      //tempProduct3 (isNaN(tempProduct3) ? 0 : tempProduct3) +
      //tempProduct4 (isNaN(tempProduct4) ? 0 : tempProduct4)
      0
    );
  };

  const productOptions: RegisterOptions = {
    valueAsNumber: true,
    min: {
      value: 0,
      message: 'Product cannot be negative!',
    },
    max: {
      value: 10000,
      message: "You can only order 10'000 bags at a time!",
    },
    validate: {
      moreThanZero,
    },
  };
  const addressOptions: RegisterOptions = {
    required: 'Please enter an address...',
    maxLength: {
      value: 100,
      message: 'Address cannot be more than 100 characters!',
    },
  };

  const buyerNameOptions: RegisterOptions = {
    required: 'Please enter an name...',
    maxLength: {
      value: 100,
      message: 'Name cannot be more than 100 characters!',
    },
  };

  const buyerPhoneOptions: RegisterOptions = {
    required: 'Please enter an phone number...',
    maxLength: {
      value: 100,
      message: 'Phone number cannot be more than 100 characters!',
    },
    //validate: () => getValues('buyerPhone').search(/^\+234[0-9]{11}$/) === 0,
  };

  useEffect(() => {
    if (outletsError) {
      setShowOutletsAlert(true);
    } else if (showOutletsAlert) {
      setShowOutletsAlert(false);
    }
    return () => {
      setShowOutletsAlert(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outletsError]);

  useEffect(() => {
    if (isSubmitSuccessful && !loading) {
      navigate('/sales', { replace: true });
    }
  }, [isSubmitSuccessful, loading, navigate]);

  const handleOnConfirm = () => {
    setShowConfirmation(false);
    handleSubmit(onSubmit)();
  };

  const handleOnCancel = () => {
    setShowConfirmation(false);
  };

  const onSubmit: SubmitHandler<NewOrderFormValues> = (data) => {
    if (!isValid) return;
    const distributor = { id: user.id, name: user.name, phone: user.phone };

    if (newBuyer === true) {
      create({
        source: 'app',
        channel: data.channel,
        address: data.address,
        cluster: user.cluster ?? user.clusters[0] ?? null,
        createdAt: Timestamp.now(),
        createdBy: {
          id: user.id,
          name: user.name,
          phone: user.phone,
          roles: user.roles,
        },
        distributor,
        products: {
          classic: isNaN(data.classic) ? 0 : data.classic,
          supaset: isNaN(data.supaset) ? 0 : data.supaset,
          supafixc0: isNaN(data.supafixc0) ? 0 : data.supafixc0,
          supafixc1: isNaN(data.supafixc1) ? 0 : data.supafixc1,
          watershield: isNaN(data.watershield) ? 0 : data.watershield,
          pop: isNaN(data.pop) ? 0 : data.pop,
          //tempProduct2 tempProduct2: isNaN(data.tempProduct2) ? 0 : data.tempProduct2,
          //tempProduct3 tempProduct3: isNaN(data.tempProduct3) ? 0 : data.tempProduct3,
          //tempProduct4 tempProduct4: isNaN(data.tempProduct4) ? 0 : data.tempProduct4,
        },
        productsDelivered: {
          classic: isNaN(data.classic) ? 0 : data.classic,
          supaset: isNaN(data.supaset) ? 0 : data.supaset,
          supafixc0: isNaN(data.supafixc0) ? 0 : data.supafixc0,
          supafixc1: isNaN(data.supafixc1) ? 0 : data.supafixc1,
          watershield: isNaN(data.watershield) ? 0 : data.watershield,
          pop: isNaN(data.pop) ? 0 : data.pop,
          //tempProduct2 tempProduct2: isNaN(data.tempProduct2) ? 0 : data.tempProduct2,
          //tempProduct3 tempProduct3: isNaN(data.tempProduct3) ? 0 : data.tempProduct3,
          //tempProduct4 tempProduct4: isNaN(data.tempProduct4) ? 0 : data.tempProduct4,
        },
        buyer: {
          name: data.buyerName,
          phone: data.buyerPhone,
        },
        status: 'fulfilled',
        statusHistory: {
          fulfilled: Timestamp.now(),
        },
        userIds: [user.id],
        testIds: [],
      });
    } else if (newBuyer === false) {
      let outlet = outlets.find((d) => d.id === data.outlet.value);
      create({
        channel: outlet.channel ? outlet.channel : null,
        source: 'app',
        address: data.address,
        cluster: outlet.cluster,
        createdAt: Timestamp.now(),
        createdBy: {
          id: user.id,
          name: user.name,
          phone: user.phone,
          roles: user.roles,
        },
        distributor,
        products: {
          classic: isNaN(data.classic) ? 0 : data.classic,
          supaset: isNaN(data.supaset) ? 0 : data.supaset,
          supafixc0: isNaN(data.supafixc0) ? 0 : data.supafixc0,
          supafixc1: isNaN(data.supafixc1) ? 0 : data.supafixc1,
          watershield: isNaN(data.watershield) ? 0 : data.watershield,
          pop: isNaN(data.pop) ? 0 : data.pop,
          //tempProduct2 tempProduct2: isNaN(data.tempProduct2) ? 0 : data.tempProduct2,
          //tempProduct3 tempProduct3: isNaN(data.tempProduct3) ? 0 : data.tempProduct3,
          //tempProduct4 tempProduct4: isNaN(data.tempProduct4) ? 0 : data.tempProduct4,
        },
        productsDelivered: {
          classic: isNaN(data.classic) ? 0 : data.classic,
          supaset: isNaN(data.supaset) ? 0 : data.supaset,
          supafixc0: isNaN(data.supafixc0) ? 0 : data.supafixc0,
          supafixc1: isNaN(data.supafixc1) ? 0 : data.supafixc1,
          watershield: isNaN(data.watershield) ? 0 : data.watershield,
          pop: isNaN(data.pop) ? 0 : data.pop,
          //tempProduct2 tempProduct2: isNaN(data.tempProduct2) ? 0 : data.tempProduct2,
          //tempProduct3 tempProduct3: isNaN(data.tempProduct3) ? 0 : data.tempProduct3,
          //tempProduct4 tempProduct4: isNaN(data.tempProduct4) ? 0 : data.tempProduct4,
        },
        outlet: {
          id: outlet.id,
          name: outlet.name,
        },
        retailer: {
          id: outlet.retailer.id,
          name: outlet.retailer.id,
        },
        status: 'fulfilled',
        statusHistory: {
          fulfilled: Timestamp.now(),
        },
        userIds: [user.id],
        testIds: [],
      });
    }
  };

  return (
    <>
      <Alert
        message={outletsError && outletsError.message}
        open={showOutletsAlert}
        setOpen={(open) => setShowOutletsAlert(open)}
        title="Error"
      />
      <Loader show={loading} />
      <Confirm
        title="Are you sure you want to create this new order?"
        open={showConfirmation}
        onConfirm={handleOnConfirm}
        onCancel={handleOnCancel}
        setOpen={(open) => {
          setShowConfirmation(open);
        }}
      />
      <Content>
        <Card className="grid grid-cols-12">
          <Back to="/sales" className="col-span-3" />
          <div className="col-span-6 flex justify-center items-center text-center text-xl font-bold text-lh-head-black">
            <FontAwesomeIcon
              icon={faPlusCircle}
              className="mr-2 text-lh-head-black"
            />
            New direct sale
          </div>
          <form
            name="newOrderForm"
            onSubmit={handleSubmit(onSubmit)}
            className="grid grid-cols-12 col-span-full mt-2"
          >
            {newBuyer === null && (
              <div className="col-span-12 text-center mb-4">
                <Button
                  text="Create for new buyer"
                  onClick={() => setNewBuyer(true)}
                />
                <Button
                  text="Create existing customer"
                  onClick={() => setNewBuyer(false)}
                />
              </div>
            )}
            {newBuyer === false && (
              <SearchableSelect
                control={control}
                name="outlet"
                rules={{ required: true }}
                label="Outlet"
                placeholder="Select Outlet..."
                values={
                  outlets
                    ? outlets.map((outlet) => ({
                        label: outlet.name,
                        value: outlet.id,
                      }))
                    : []
                }
                className="col-span-12 text-lh-text-black"
              />
            )}
            {newBuyer === true && (
              <>
                <h2 className="font-bold col-span-12 mt-2 mb-2 text-lh-head-black">
                  Buyer info
                </h2>
                <label
                  htmlFor="classic"
                  className="col-span-4 pt-1 text-lh-text-black"
                >
                  Name
                </label>
                <Input
                  name="buyerName"
                  register={register}
                  options={buyerNameOptions}
                  error={errors.buyerName}
                  type="text"
                  placeholder="Name"
                  className="col-span-5 text-left col-start-8 text-lh-text-black"
                />
                <label
                  htmlFor="classic"
                  className="col-span-4 pt-1 text-lh-text-black"
                >
                  Phone
                </label>
                <Input
                  name="buyerPhone"
                  register={register}
                  options={buyerPhoneOptions}
                  error={errors.buyerPhone}
                  type="text"
                  placeholder="+234xxxxxxxxx"
                  className="col-span-5 text-left col-start-8 text-lh-text-black"
                />
                <label
                  htmlFor="businessName"
                  className="font-bold col-span-12 mb-2 text-lh-head-black"
                >
                  Channel
                </label>
                <Select
                  register={register}
                  name="channel"
                  items={CHANNEL_TYPES}
                  placeholder="Select channel..."
                  className="col-span-full text-left text-lh-text-black"
                />
              </>
            )}
            <h2 className="font-bold col-span-12 mt-2 mb-2 text-lh-head-black">
              Products
            </h2>
            <label
              htmlFor="classic"
              className="col-span-4 pt-1 text-lh-text-black"
            >
              Classic
            </label>
            <Input
              name="classic"
              register={register}
              options={productOptions}
              error={errors.classic}
              type="number"
              placeholder="0"
              className="col-span-4 text-right col-start-8 text-lh-text-black"
            />
            <label
              htmlFor="classic"
              className="col-span-1 ml-2 pt-1 text-lh-text-black"
            >
              bags
            </label>
            <label
              htmlFor="supaset"
              className="col-span-4 pt-1 text-lh-text-black"
            >
              Supaset
            </label>
            <Input
              name="supaset"
              register={register}
              options={productOptions}
              type="number"
              placeholder="0"
              className="col-span-4 text-right col-start-8 text-lh-text-black"
            />
            <label
              htmlFor="supaset"
              className="col-span-1 ml-2 pt-1 text-lh-text-black"
            >
              bags
            </label>
            <label
              htmlFor="supafixc0"
              className="col-span-4 pt-1 text-lh-text-black"
            >
              SupafixC0
            </label>
            <Input
              name="supafixc0"
              register={register}
              options={productOptions}
              type="number"
              placeholder="0"
              className="col-span-4 text-right col-start-8 text-lh-text-black"
            />
            <label
              htmlFor="supafixc0"
              className="col-span-1 ml-2 pt-1 text-lh-text-black"
            >
              bags
            </label>
            <label
              htmlFor="supafixc1"
              className="col-span-4 pt-1 text-lh-text-black"
            >
              SupafixC1
            </label>
            <Input
              name="supafixc1"
              register={register}
              options={productOptions}
              type="number"
              placeholder="0"
              className="col-span-4 text-right col-start-8 text-lh-text-black"
            />
            <label
              htmlFor="supafixc1"
              className="col-span-1 ml-2 pt-1 text-lh-text-black"
            >
              bags
            </label>
            <label
              htmlFor="watershield"
              className="col-span-4 pt-1 text-lh-text-black"
            >
              Watershield
            </label>
            <Input
              name="watershield"
              register={register}
              options={productOptions}
              type="number"
              placeholder="0"
              className="col-span-4 text-right col-start-8 text-lh-text-black"
            />
            <label
              htmlFor="watershield"
              className="col-span-1 ml-2 pt-1 text-lh-text-black"
            >
              bags
            </label>
            {/*pop
            <label htmlFor="pop" className="col-span-4 pt-1 text-lh-text-black">
              POP
            </label>
            <Input
              name="pop"
              register={register}
              options={productOptions}
              type="number"
              placeholder="0"
              className="col-span-4 text-right col-start-8 text-lh-text-black"
            />
            <label htmlFor="pop" className="col-span-1 ml-2 pt-1 text-lh-text-black">
              bags
            </label>
            
            {/*tempProduct2
            <label htmlFor="tempProduct2" className="col-span-4 pt-1 text-lh-text-black">
              TempProduct2
            </label>
            <Input
              name="tempProduct2"
              register={register}
              options={productOptions}
              type="number"
              placeholder="0"
              className="col-span-4 text-right col-start-8 text-lh-text-black"
            />
            <label htmlFor="tempProduct2" className="col-span-1 ml-2 pt-1 text-lh-text-black">
              bags
            </label>
            tempProduct2 */}
            {/*tempProduct3
            <label htmlFor="tempProduct3" className="col-span-4 pt-1 text-lh-text-black">
              TempProduct3
            </label>
            <Input
              name="tempProduct3"
              register={register}
              options={productOptions}
              type="number"
              placeholder="0"
              className="col-span-4 text-right col-start-8 text-lh-text-black"
            />
            <label htmlFor="tempProduct3" className="col-span-1 ml-2 pt-1 text-lh-text-black">
              bags
            </label>
            tempProduct3 */}
            {/*tempProduct4
            <label htmlFor="tempProduct4" className="col-span-4 pt-1 text-lh-text-black">
              TempProduct4
            </label>
            <Input
              name="tempProduct4"
              register={register}
              options={productOptions}
              type="number"
              placeholder="0"
              className="col-span-4 text-right col-start-8 text-lh-text-black"
            />
            <label htmlFor="tempProduct4" className="col-span-1 ml-2 pt-1 text-lh-text-black">
              bags
            </label>
            tempProduct4 */}
            <label
              htmlFor="address"
              className="col-span-full font-bold text-lh-head-black"
            >
              Delivery Address
            </label>
            <Input
              name="address"
              register={register}
              options={addressOptions}
              error={errors.address}
              type="text"
              className="col-span-full text-lh-text-black"
            />
          </form>
          <Button
            buttonDisabled={!isValid}
            text="Place direct sale"
            color="blue"
            className="col-span-full"
            onClick={() => {
              setShowConfirmation(true);
            }}
          />
        </Card>
      </Content>
    </>
  );
};

export default NewSale;
