import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { RetailerVisit } from '../../../../global';

type VisitProps = {
  outletId: string;
  retailerId: string;
  retailerName: string;
  done?: boolean;
  clickable?: boolean;
  visit?: RetailerVisit;
};

const Visit = (props: VisitProps) => {
  const navigate = useNavigate();
  return (
    <li
      className={
        'flex justify-between mx-2 mb-2 h-18 list-none shadow-md rounded-lg pb-4 px-2 pt-2 cursor-pointer retailer-lh-red bg-white' +
        (props.done ? ' border-2 border-green-500' : '')
      }
      onClick={() => {
        if (!props.done && props.clickable) {
          navigate(`/visits/${props.retailerId}_${props.outletId}`);
        } else if (props.visit) {
          navigate(`/visits/${props.visit.id}`);
        }
      }}
    >
      <div className="grid grid-cols-12 w-full">
        <p className="col-span-6 text-sm">
          <FontAwesomeIcon icon={faUserCircle} />
          {` ${props.retailerName}`}
        </p>
      </div>
      <p className="self-center text-sm w-32 text-center">
        {``}
        <FontAwesomeIcon icon={faAngleRight} />
      </p>
    </li>
  );
};

export default Visit;
