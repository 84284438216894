import { ProductsType } from '../../../../global';

type ProductsProps = {
  title: string;
  products: ProductsType;
  className?: string;
};

const Products = ({ title, products, className }: ProductsProps) => {
  return (
    <div className={`grid grid-cols-12 ${className}`}>
      <h2 className="col-span-full font-bold text-lh-head-black">{title}</h2>
      <hr className="col-span-full" />
      <p className="col-span-6 text-left pt-3 truncate overflow-hidden">
        Classic
      </p>
      <p className="col-span-6 text-lh-text-black text-right pt-3 truncate overflow-hidden">
        {products.classic}
        <span className="text-gray-500 font-light ml-1">tons</span>
      </p>
      <p className="col-span-6 text-left pt-3 truncate overflow-hidden">
        Supaset
      </p>
      <p className="col-span-6 text-lh-text-black text-right pt-3 truncate overflow-hidden">
        {products.supaset}
        <span className="text-gray-500 font-light ml-1">tons</span>
      </p>
      <p className="col-span-6 text-left pt-3 truncate overflow-hidden">
        Supafix C0
      </p>
      <p className="col-span-6 text-lh-text-black text-right pt-3 truncate overflow-hidden">
        {products.supafixc0}
        <span className="text-gray-500 font-light ml-1">tons</span>
      </p>
      <p className="col-span-6 text-left pt-3 truncate overflow-hidden">
        Supafix C1
      </p>
      <p className="col-span-6 text-lh-text-black text-right pt-3 truncate overflow-hidden">
        {products.supafixc1}
        <span className="text-gray-500 font-light ml-1">tons</span>
      </p>
      <p className="col-span-6 text-left pt-3 truncate overflow-hidden">
        Watershield
      </p>
      <p className="col-span-6 text-lh-text-black text-right pt-3 truncate overflow-hidden">
        {products.watershield}
        <span className="text-gray-500 font-light ml-1">tons</span>
      </p>
       
      <p className="col-span-6 text-left pt-3 truncate overflow-hidden">
        POP
      </p>
      <p className="col-span-6 text-lh-text-black text-right pt-3 truncate overflow-hidden">
        {products.pop}
        <span className="text-gray-500 font-light ml-1">tons</span>
      </p>
      
      {/* TempProduct2 
      <p className="col-span-6 text-left pt-3 truncate overflow-hidden">
        TempProduct2
      </p>
      <p className="col-span-6 text-lh-text-black text-right pt-3 truncate overflow-hidden">
        {products.tempProduct2}
        <span className="text-gray-500 font-light ml-1">tons</span>
      </p>
      TempProduct2 */}
      {/* TempProduct3
      <p className="col-span-6 text-left pt-3 truncate overflow-hidden">
        TempProduct3
      </p>
      <p className="col-span-6 text-lh-text-black text-right pt-3 truncate overflow-hidden">
        {products.tempProduct3}
        <span className="text-gray-500 font-light ml-1">tons</span>
      </p>
      TempProduct3 */}
      {/* TempProduct4
      <p className="col-span-6 text-left pt-3 truncate overflow-hidden">
        TempProduct4
      </p>
      <p className="col-span-6 text-lh-text-black text-right pt-3 truncate overflow-hidden">
        {products.tempProduct4}
        <span className="text-gray-500 font-light ml-1">tons</span>
      </p>
      TempProduct4 */}
    </div>
  );
};

export default Products;
