import { addDoc, collection, Timestamp } from 'firebase/firestore';
import { ProductsType } from '../../../../global';
import { firestore } from '../utils/firebase';

/**
 * Function to set the initial stock level of an inventory.
 * @param id Id of the inventory
 * @param products Products which should be set as initial stock level of
 * inventory
 */
export const setInitialStock = async (id: string, products: ProductsType) => {
  await addDoc(collection(firestore, `inventories/${id}/transactions`), {
    createdAt: Timestamp.now(),
    products,
    type: 'initial',
    status: 'pending',
  });
};
