import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import {
  CreateOrderData,
  ProductsType,
  UpdateOrderData,
  User,
} from '../../../../global';
import { firestore } from '../utils/firebase';

interface CreateOrder {
  (data: CreateOrderData): Promise<any>;
}
interface UpdateOrder {
  (id: string, data: UpdateOrderData): Promise<void>;
}

interface AcceptOrder {
  (id: string, comment: string, user: User): Promise<void>;
}

interface RejectOrder {
  (id: string, comment: string, user: User): Promise<void>;
}

interface CancelOrder {
  (id: string, comment: string, user: User): Promise<void>;
}

interface FulfillOrder {
  (id: string, productsDelivered: ProductsType, deliveredToConstructionSite: boolean, user: User): Promise<void>;
}

interface PartialFulfillOrder {
  (id: string, productsDelivered: ProductsType, deliveredToConstructionSite: boolean, user: User): Promise<void>;
}

interface Tier2FulfillOrder {
  (id: string, productsDelivered: ProductsType, user: User): Promise<void>;
}

export const createOrder: CreateOrder = async (data) => {
  const ref = collection(firestore, 'orders');
  await addDoc(ref, data);
};

export const updateOrder: UpdateOrder = async (id, data) => {
  const ref = doc(firestore, 'orders', id);
  await updateDoc(ref, data);
};

export const acceptOrder: AcceptOrder = async (id, comment, user) => {
  const ref = doc(firestore, 'orders', id);
  await updateDoc(ref, { status: 'accepted', comment, acceptedBy: {
    id: user.id,
    name: user.name,
    phone: user.phone,
    roles: user.roles,
  }});
};

export const rejectOrder: RejectOrder = async (id, comment, user) => {
  const ref = doc(firestore, 'orders', id);
  await updateDoc(ref, { status: 'rejected', comment, rejectedBy: {
    id: user.id,
    name: user.name,
    phone: user.phone,
    roles: user.roles,
  } });
};

export const cancelOrder: CancelOrder = async (id, comment, user) => {
  const ref = doc(firestore, 'orders', id);
  await updateDoc(ref, { status: 'cancelled', comment, cancelledBy: {
    id: user.id,
    name: user.name,
    phone: user.phone,
    roles: user.roles,
  }});
};

export const fulfillOrder: FulfillOrder = async (id, productsDelivered, deliveredToConstructionSite, user) => {
  const ref = doc(firestore, 'orders', id);
  await updateDoc(ref, {
    deliveredToConstructionSite,
    status: 'fulfilled',
    fulfillmentStatus: 'pendingFulfillment',
    productsDelivered,
    fulfilledBy: {
      id: user.id,
      name: user.name,
      phone: user.phone,
      roles: user.roles,
    }
  });
};

export const partialFulfillOrder: PartialFulfillOrder = async (
  id,
  productsDelivered,
  deliveredToConstructionSite,
  user
) => {
  const ref = doc(firestore, 'orders', id);
  await updateDoc(ref, {
    fulfillmentStatus: 'pendingPartialFulfillment',
    productsDelivered,
    deliveredToConstructionSite,
    partiallyFulfilledBy: {
      id: user.id,
      name: user.name,
      phone: user.phone,
      roles: user.roles,
    }
  });
};

export const tier2FulfillOrder: Tier2FulfillOrder = async (
  id,
  productsDelivered,
  user
) => {
  const ref = doc(firestore, 'orders', id);
  await updateDoc(ref, {
    fulfillmentStatus: 'pendingTier2Fulfillment',
    productsDelivered,
    tier2FulfilledBy: {
      id: user.id,
      name: user.name,
      phone: user.phone,
      roles: user.roles,
    }
  });
};
